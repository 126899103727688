import './hero.scss';

interface IHero {
	url: string;
	className?: string;
}

export const Hero = ({ url, className }: IHero) => {
	if (!url) {
		return <></>;
	}

	const heroImageClass = `kyc-events-hero-image ${className}`;

	return <img className={heroImageClass} src={url} alt="img" />;
};
