import { PDFDocumentProxy } from 'pdfjs-dist';
import { FC, useEffect, useRef } from 'react';

interface IPageRenderer {
	pageNumber: number;
	pdf: PDFDocumentProxy;
}

export const PageRenderer: FC<IPageRenderer> = ({ pageNumber, pdf }) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	useEffect(() => {
		const renderPDF = async () => {
			if (!canvasRef.current) return;
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');
			const page = await pdf.getPage(pageNumber);
			const scale = 1.5;
			const viewport = page.getViewport({ scale });

			if (!context) return;

			canvas.height = viewport.height;
			canvas.width = viewport.width;

			const renderContext = {
				canvasContext: context,
				viewport: viewport,
			};

			await page.render(renderContext).promise;
		};
		renderPDF();
	}, [pageNumber, pdf]);
	return <canvas style={{ width: '100%' }} ref={canvasRef} />;
};

export default PageRenderer;
