export const PREVENTAPPLE_LOGIN_FOR_URLS = [
	// "secure.stage.satschel.com", // Awadhesh: for tetsing
	// "secure.pp.satschel.com",
	'localhost:3000',
	'localhost:3001',
	'localhost:3002',
];

export const PRIVACY_POLICY_LINK = 'https://simplici.io/privacy-notice';

export const isSafariBroswerDetect =
	/constructor/i.test(window.HTMLElement as any) ||
	(function (p) {
		return p.toString() === '[object SafariRemoteNotification]';
	})(
		!(window as any)['safari'] ||
			(typeof (window as any).safari !== 'undefined' &&
				(window as any).safari?.pushNotification)
	);
