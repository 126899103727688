import env from '@beam-australia/react-env';

export const REACT_APP_API_HOST = (window as any).API_HOST
	? (window as any).API_HOST
	: env('API_HOST');
export const REACT_APP_OCR_HOST = 'https://api-ocr.satschel.com/v1';

export const REACT_APP_CDN_URL = env('CDN_URL');
export const REACT_APP_GLARE_API = env('GLARE_API');
export const REACT_APP_GOOGLE_MAP_SECRET_TOKEN = env('GOOGLE_MAP_SECRET_TOKEN');
export const REACT_APP_NON_OF_ABOVE_HIDE = env('NON_OF_ABOVE_HIDE');
