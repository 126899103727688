// Importing necessary dependencies, components, and styles
import {
	useRef,
	useEffect,
	Fragment,
	useState,
	FC,
	useCallback,
	useMemo,
} from 'react';
import { Image, Loader } from '@storybook';

import { isMobileDevice } from 'utils';

import './camera.scss';

// Interface defining the props for the CameraComponent
interface ICameraComponent {
	webcamRef: any;
	children?: JSX.Element;
	setCameraTimeLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const idealSize = {
	width: { ideal: 1920 },
	height: { ideal: 1080 },
};
// Functional component CameraComponent
export const CameraComponent: FC<ICameraComponent> = ({
	webcamRef,
	setCameraTimeLoading,
}) => {
	// Refs for video and canvas elements

	// State for loading indicator
	const [loading, setLoading] = useState(true);

	const [cameraWidth, setcameraWidth] = useState(0);
	const [cameraHeight, setcameraHeight] = useState(0);

	const canvasRef = useRef<HTMLCanvasElement>(null);
	// const captureRef = useRef<HTMLDivElement>(null);

	const drawRectangle = useCallback(() => {
		const video: any = document.getElementById('capture-video');
		const canvas = canvasRef.current;
		const context = canvas?.getContext('2d');
		const canvasWidth = canvas?.width ?? 0;
		const canvasHeight = canvas?.height ?? 0;

		if (context && canvas) {
			// Draw the video frame
			context.drawImage(video, 0, 0, canvasWidth, canvasHeight);

			const borderWidth = 34;

			context.fillStyle = 'rgba(0, 0, 0, 0.50)'; // Set the border color
			// Top border
			context.fillRect(
				borderWidth,
				0,
				canvas.width - borderWidth * 2,
				borderWidth
			);
			// Bottom border
			context.fillRect(
				borderWidth,
				canvas.height - 104,
				canvas.width - borderWidth * 2,
				104
			);
			// Left border
			context.fillRect(0, 0, borderWidth, canvas.height);
			// Right border
			context.fillRect(
				canvas.width - borderWidth,
				0,
				borderWidth,
				canvas.height
			);

			requestAnimationFrame(drawRectangle);
		}
	}, []);

	const startCamera = useCallback(async () => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			let backCamera: any;
			devices?.forEach(device => {
				if (
					device.kind === 'videoinput' &&
					device.label.toLowerCase().includes('back')
				) {
					backCamera = device;
				}
			});
			const vedioStream: any = {};
			if (backCamera) {
				vedioStream.video = {
					deviceId: backCamera?.deviceId,
					...idealSize,
				};
			} else {
				vedioStream.video = {
					...idealSize,
				};
			}
			if (isMobileDevice()) {
				vedioStream.video = {
					facingMode: 'environment',
					...idealSize,
				};
			}

			const newStream = await navigator.mediaDevices.getUserMedia(vedioStream);

			if (webcamRef.current) {
				webcamRef.current.srcObject = newStream;
				webcamRef.current.play();
				drawRectangle();
				// captureRef.current.style.display = 'block';
				if (isMobileDevice()) {
					webcamRef.current.setAttribute('autoplay', '');
					webcamRef.current.setAttribute('muted', '');
					webcamRef.current.setAttribute('playsinline', '');
				}

				webcamRef.current.onloadedmetadata = function () {
					setcameraWidth(webcamRef.current?.videoWidth ?? 0);
					setcameraHeight(webcamRef.current?.videoHeight ?? 0);
				};
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error accessing camera:', error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Initialization effect: setup camera, draw video on canvas, and handle cleanup
	useEffect(() => {
		setCameraTimeLoading(true);
		setTimeout(() => {
			setLoading(false);
			setCameraTimeLoading(false);
		}, 3000);

		// Initialize the camera when the component mounts
		startCamera();

		const video: any = document.getElementById('capture-video');
		return () => {
			if (video) {
				// A video's MediaStream object is available through its srcObject attribute
				const mediaStream = video.srcObject;
				// Through the MediaStream, you can get the MediaStreamTracks with getTracks():
				const tracks = mediaStream.getTracks();
				// Or stop all like so:
				tracks.forEach((track: any) => track.stop());
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Method to handle additional drawing on the canvas (optional)
	const handleDraw = () => {
		if (!webcamRef.current) {
			return;
		}
		const canvas = webcamRef.current;
		const ctx = canvas.getContext('2d');

		// Your additional drawing logic here using ctx
		// Example: Draw a blue circle
		ctx.fillStyle = 'blue';
		ctx.beginPath();
		ctx.arc(50, 50, 30, 0, 2 * Math.PI);
		ctx.fill();
	};

	// Need to do testing to check in various devices
	const { width, height } = useMemo(() => {
		let width = window.innerWidth;
		const height = window.innerHeight;

		if (width > 768) {
			width = 768;
		}
		return { width, height };
	}, []);

	// JSX structure for the CameraComponent
	return (
		<Fragment>
			{/* Video element for displaying the camera feed */}

			<video
				ref={webcamRef}
				id="capture-video"
				width={cameraWidth}
				height={cameraHeight}
				style={{ display: 'none' }}
				autoPlay
			/>

			{/* Canvas element for drawing video frames */}
			<canvas
				width={width}
				height={height}
				ref={canvasRef}
				id="canvas-verification"
				onClick={handleDraw}
				style={{ display: loading ? 'none' : 'unset' }}
			/>
			{/* Loading indicator */}
			{loading && (
				<div className="image-id-card">
					<div className="checkfraud-camera--loader-wrapper">
						<Image
							fileName="Settings.svg"
							className="checkfraud-camera__loading-svg"
						/>
						<p className="checkfraud-camera__loading-heading">
							Hang tight! Your camera is being calibrated for the best quality
							images.
						</p>

						<Loader type="loader" className="loader-blue" dimension={30} />
					</div>
				</div>
			)}
		</Fragment>
	);
};
