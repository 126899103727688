/* eslint-disable @typescript-eslint/no-array-constructor */
export const isValidImage = (fileName: string) => {
	const allowed_extensions = new Array<string>(
		'jpg',
		'png',
		'jpeg',
		'jfif',
		'pjpeg',
		'pjp',
		'svg',
		'webp'
	);
	const file_extension = fileName.split('.')?.pop()?.toLowerCase();
	for (let i = 0; i <= allowed_extensions.length; i++) {
		if (allowed_extensions[i] === file_extension) {
			return true;
		}
	}
	return false;
};
