import { AccessTokenState } from 'states';
import { EVENTURL } from '../constants';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KYCEventsState } from './states';
import { envHost } from 'utils';

export const useKycEvent = () => {
	const { code } = useRecoilValue(AccessTokenState);
	const setKycEventsState = useSetRecoilState(KYCEventsState);

	const initKycEvent = async () => {
		if (envHost === 'prod') return;

		try {
			const resp = await fetch(`${EVENTURL}/events/${code}`);
			const result = await resp.json();
			if (result.message === 'ok') {
				const { data } = result;
				setKycEventsState(data);
			}
		} catch (err) {
			setKycEventsState(null);
			// eslint-disable-next-line no-console
			console.log('Error fetching', err);
		}
	};
	return {
		initKycEvent,
	};
};
