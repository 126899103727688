import { getOrigin, getOriginProtocol, getParentHost } from 'utils';

export const OWNER_STEPS = [
	`Open the Simplici app.`,
	`Navigate to "Settings."`,
	`Go to "General Settings."`,
	`Find "Whitelisted Domains."`,
	`Click on "Add Domain."`,
	`Enter the website domain (e.g. "${getOrigin()}").`,
	`Click "Save."`,
];

export const DOMAIN_EXAMPLES = [`${getOrigin()}`, `${getOrigin()}/home`];
export const WILDCARD_EXAMPLES = [
	`${getOriginProtocol()}//*.${getParentHost()}`,
];
