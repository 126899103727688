import { atom } from 'recoil';
import { IConfirmDetailsFormData, ICountries, IidvPayload } from './types';

type KYCToken = {
	token: string;
	id: string;
};

type IDMeritStep = 'ssnNumber' | 'addressDetails' | 'checkScan' | '';

// Define Recoil atoms with typings
export const KYCTokenState = atom<KYCToken>({
	key: 'kyc-token-state',
	default: {
		token: '',
		id: '',
	},
});

export const KYCAddressInfo = atom<any>({
	key: 'kyc-address-details-state',
	default: {
		streetAddress: '',
		building: '',
		zipCode: '',
		country: '',
		city: '',
		state: '',
	},
});

export const IDMeritStepState = atom<IDMeritStep>({
	key: 'id-merit-step-state',
	default: '',
});

export const SelectedCountryState = atom<any>({
	key: 'selected-country-state',
	default: {},
});

export const AllCountryState = atom<ICountries[]>({
	key: 'all-country-state',
	default: [],
});

export const SelectedDefaultCountryState = atom<ICountries | any>({
	key: 'selected-default-country-state',
	default: {},
});

export const IsBackupKycState = atom<boolean>({
	key: 'is-backup-kyc-state-key',
	default: false,
});

export const AddressNotFetchedState = atom<boolean>({
	key: 'address-not-fetched-state',
	default: false,
});

export const KycLoaderState = atom<boolean>({
	key: 'is-kyc-complex-loader-state',
	default: false,
});

export const ConfirmDetailsFormDataState = atom<IConfirmDetailsFormData>({
	key: 'confirm-details-form-data-state-key',
	default: {
		firstName: '',
		middleName: '',
		lastName: '',
		dateOfBirth: '',
		dateOfExpiry: '',
	},
});

export const SsnNumberState = atom<string>({
	key: 'ssn-number-state-key',
	default: '',
});

export const IsLexisFailedState = atom<boolean>({
	key: 'is-lexis-failed-state-key',
	default: false,
});
export const IsKycLoading = atom<boolean>({
	key: 'is-kyc-loading',
	default: false,
});

export const IsChequeCompleted = atom<boolean>({
	key: 'is-chequ-completed',
	default: false,
});

export const IdvPayloadState = atom<IidvPayload>({
	key: 'idv-payload-state-key',
	default: {
		firstName: '',
		lastName: '',
		streetAddress: '',
		city: '',
		state: '',
		zip5: '',
		country: '',
		idType: '',
		provider: 'LEXISNEXIS',
		sessionId: '',
		driverLicenseNumber: '',
		driverLicenseState: '',
		passportNumber: '',
		passportExpirationDate: '',
		dob: '',
		ssnNumber: '',
		dateOfExpiry: '',
		dateOfIssue: '',
	},
});
