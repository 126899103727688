import { useState, useEffect } from 'react';

declare global {
	interface Window {
		resizeLag?: NodeJS.Timeout;
	}
}

const useOrientation = () => {
	const isLandscape = () =>
		window.matchMedia('(orientation: portrait)').matches;
	const isMobile = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	};

	const [orientation, setOrientation] = useState('portrait');

	const onWindowResize = () => {
		if (window.resizeLag) clearTimeout(window.resizeLag);

		window.resizeLag = setTimeout(() => {
			delete window.resizeLag;
			setOrientation(isMobile() && !isLandscape() ? 'landscape' : 'portrait');
		}, 200);
	};

	useEffect(() => {
		onWindowResize();
		window.addEventListener('resize', onWindowResize);

		return () => {
			if (window.resizeLag) clearTimeout(window.resizeLag);
			window.removeEventListener('resize', onWindowResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return orientation;
};

export default useOrientation;
