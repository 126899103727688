import type { IAccount, IPlaidAccounts, IPhoneNumber } from '.';
import { atom } from 'recoil';

export const SessionStepProperties = atom<any>({
	key: 'session-Details',
	default: {},
});

export const getCurrentLabel = atom({
	key: 'current-label',
	default: '',
});

export const selectedAccountState = atom<IAccount | undefined>({
	key: 'selected-account',
	default: {
		accountId: '',
		balance: {
			available: 0,
			current: 0,
			currencyCode: 0,
		},
		mask: '',
		name: '',
		officialName: '',
		subtype: '',
		type: '',
	},
});

export const selectedBankState = atom<IPlaidAccounts>({
	key: 'selected-bank-state',
	default: {
		account: [
			{
				accountId: '',
				mask: '',
				name: '',
				officialName: '',
				subtype: '',
				type: '',
				balance: {
					available: 0,
					currencyCode: 0,
					current: 0,
				},
			},
		],
		institutionName: '',
		totalAvailableBalance: 0,
		totalCurrentBalance: 0,
		_id: '',
	},
});

export const LoginPhoneNumberState = atom<IPhoneNumber>({
	key: 'login-phone-state',
	default: {
		phone: '',
		countryCode: '+1',
	},
});

export const InviteUserData = atom<any>({
	key: 'invite-user-data',
	default: {},
});
