export const CUSTOM_FORM_INPUTS = [
	{
		id: 1,
		type: 'input',
		key: 'firstName',
		name: 'firstName',
		placeholder: 'First Name',
		title: 'First Name',
	},
	{
		id: 2,
		type: 'input',
		key: 'lastName',
		name: 'lastName',
		placeholder: 'Last Name',
		title: 'Last Name',
	},
	{
		id: 3,
		type: 'input',
		key: 'email',
		name: 'email',
		placeholder: 'Email',
		title: 'Email',
	},
];

export const SIGNATORY_USER: { [key: string]: string } = {
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	First_Name: 'firstName',
	Last_Name: 'lastName',
	Email: 'email',
};
