import { Description, Hero, Title } from './components';

import './kyc-events.scss';
import { Button } from '@storybook';
import { useRecoilValue } from 'recoil';
import { KYCEventsState } from './store/states';
import { FC } from 'react';

interface IKYCEvents {
	onAcceptInvite: () => void;
}

export const KYCEvents: FC<IKYCEvents> = ({ onAcceptInvite }) => {
	const kycEventsState = useRecoilValue(KYCEventsState);

	const dateAndTimee = (
		<div className="d-flex align-center gp-8">
			<i className="ri-calendar-line"></i>
			<span className="date-info__full-datetime">
				{kycEventsState?.date ?? ''}
			</span>
		</div>
	);

	const location = (
		<div className="d-flex align-center gp-8">
			<div className="d-flex  align-start gp-8">
				<i className="ri-map-pin-fill"></i>
				<div>{kycEventsState?.address ?? '--'}</div>
			</div>
		</div>
	);

	return (
		<div className="kyc-events__wrapper">
			<div className="kyc-events__wrapper__inner_content">
				<Hero url={kycEventsState?.image ?? ''} />
				<div className="d-flex direction-col  align-start gp-4">
					<Title title={kycEventsState?.name ?? '--'} />
					<Description description={kycEventsState?.description ?? ''} />
				</div>
				{/* <FollowSection /> */}
				<div className="d-flex direction-col align-start gp-4">
					<Description description={dateAndTimee} />
				</div>

				<div className="d-flex direction-col align-start gp-4">
					{/* <Title title="Location" /> */}
					<Description description={location} />
				</div>
			</div>

			<div className="kyc-events__wrapper__footer">
				<Button
					type="button__filled button__filled--primary button__large"
					label="Accept Invitation"
					handleClick={onAcceptInvite}
				/>
			</div>
		</div>
	);
};
