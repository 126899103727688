// export const isMobileDevice = () => {
//   var mobile = [
//     "iphone",
//     "ipad",
//     "android",
//     "blackberry",
//     "nokia",
//     "opera mini",
//     "windows mobile",
//     "windows phone",
//     "iemobile",
//   ];
//   for (var i in mobile)
//     if (
//       navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0 &&
//       window.innerWidth < 500
//     )
//       return true;
//   return false;
// };
export const isMobileDevice = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
};

export const getiOSVersion = (): number | null => {
	const ua: string = navigator.userAgent ?? '';
	const iosRegex = /OS (\d+)[_.](\d+)[_.]?(\d+)?/;
	if (/iP(hone|od|ad)/.test(ua)) {
		const match = ua.match(iosRegex);
		if (match !== null) {
			const major = parseInt(match[1] ?? '0', 10);
			const minor = parseInt(match[2] ?? '0', 10);
			const patch = parseInt(match[3] ?? '0', 10);
			const versionNumber = major + minor / 10 + patch / 100;
			return versionNumber;
		}
	}
	return null;
};
