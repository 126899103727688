import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = (ref: any, cb: any) => {
	const handleClick = useCallback(
		(e: any) => {
			if (ref?.current && !ref?.current?.contains(e.target)) {
				cb();
			}
		},
		[cb, ref]
	);

	useEffect(() => {
		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [handleClick]);
};

// Update the hook to accept an array of refs
export const useOutsideClickAdvanced = (
	refs: RefObject<HTMLDivElement>[],
	cb: () => void
) => {
	const handleOutsideClick = useCallback(
		(e: MouseEvent) => {
			// Check if the click is outside of all provided refs
			if (
				refs.every(
					ref => ref?.current && !ref?.current?.contains(e.target as Node)
				)
			) {
				cb();
			}
		},
		[cb, refs]
	);

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick, true);

		return () => {
			document.removeEventListener('click', handleOutsideClick, true);
		};
	}, [handleOutsideClick]);
};
