export const getSteps = (steps: string) => {
	if (steps) {
		const allSteps = steps
			.split(',')
			.map(step => step.trim())
			.filter(step => step !== '');
		return allSteps?.length ? allSteps : null;
	} else {
		return null;
	}
};
