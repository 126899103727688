/* eslint-disable react/no-unescaped-entities */
import { useCallback, useMemo } from 'react';
import './camera-setting.scss';
import { Button, Image } from '@storybook';
import {
	CameraHardwareMessages,
	CameraInUseMessages,
	CameraPermisionsErrors,
	CameraSettingsOffMessages,
	CameraTimeOutMessages,
} from 'views/facial-enroll/constant';

interface ICameraSettingsProps {
	cameraTypeError?: string;
}

export const CameraSettings = ({ cameraTypeError }: ICameraSettingsProps) => {
	// Function for reload the page
	const reloadPage = useCallback(() => {
		location.reload();
	}, []);

	const renderErrorSection = useMemo(() => {
		switch (cameraTypeError) {
			case CameraPermisionsErrors.Camera_settings_OFF:
				return (
					<div className="settings__wrapper">
						<Image fileName="cameraSettings.svg" />
						<p className="settings__wrapper-heading">
							{CameraSettingsOffMessages.Heading}
						</p>
						<p className="settings__wrapper-subheading">
							{CameraSettingsOffMessages.subHeading}
						</p>
						<div className="settings__instuction-notes-box">
							<p>Follow these steps:</p>
							<ul>
								<li>{CameraSettingsOffMessages.instructionOne}</li>
								<li>{CameraSettingsOffMessages.instructionTwo}</li>
							</ul>
						</div>
						<Button
							label={'Refresh'}
							handleClick={reloadPage}
							type="button__filled button__filled--primary button__large "
						/>
					</div>
				);
			case CameraPermisionsErrors.Device_in_use:
				return (
					<div className="settings__wrapper">
						<Image fileName="cameraSettings.svg" />
						<p className="settings__wrapper-heading">
							{CameraInUseMessages.Heading}
						</p>
						<p className="settings__wrapper-subheading">
							{CameraInUseMessages.subHeading}
						</p>
						<Button
							label={'Retry'}
							handleClick={reloadPage}
							type="button__filled button__filled--primary button__large "
						/>
					</div>
				);
			case CameraPermisionsErrors.Not_Readable_Error:
				return (
					<div className="settings__wrapper">
						<Image fileName="hardWareSettings.svg" />
						<p className="settings__wrapper-heading">
							{CameraHardwareMessages.Heading}
						</p>
						<p className="settings__wrapper-subheading">
							{CameraHardwareMessages.subHeading}
						</p>
						<Button
							label={'Retry'}
							handleClick={reloadPage}
							type="button__filled button__filled--primary button__large "
						/>
					</div>
				);
			case CameraPermisionsErrors.Camera_time_out:
				return (
					<div className="settings__wrapper">
						<Image fileName="hardWareSettings.svg" />
						<p className="settings__wrapper-heading">
							{CameraTimeOutMessages.Heading}
						</p>
						<p className="settings__wrapper-subheading">
							{CameraTimeOutMessages.subHeading}
						</p>
						<Button
							label="Retry"
							handleClick={reloadPage}
							type="button__filled button__filled--primary button__large "
						/>
					</div>
				);
			default:
				return (
					<div className="settings__wrapper">
						<Image fileName="cameraSettings.svg" />
						<p className="settings__wrapper-heading">
							{CameraSettingsOffMessages.Heading}
						</p>
						<p className="settings__wrapper-subheading">
							{CameraSettingsOffMessages.subHeading}
						</p>
						<div className="settings__instuction-notes-box">
							<p>Follow these steps:</p>
							<ul>
								<li>{CameraSettingsOffMessages.instructionOne}</li>
								<li>{CameraSettingsOffMessages.instructionTwo}</li>
							</ul>
						</div>
						<Button
							label={'Refresh'}
							handleClick={reloadPage}
							type="button__filled button__filled--primary button__large "
						/>
					</div>
				);
		}
		return <></>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cameraTypeError]);

	return <div className="settings">{renderErrorSection}</div>;
};
