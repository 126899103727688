export const AlphanumericRegex = /^[a-zA-Z0-9_.-]*$/;

export const validateEmail = (emailString: string) => {
	const emailRegex =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	if (emailRegex.test(emailString) || emailString.length === 0) {
		return false;
	} else {
		return true;
	}
};

export const validateName = (value: string = '') => {
	return /^[a-zA-ZÀ-ÿ'.-]+(?: [a-zA-ZÀ-ÿ'.-]+)*$/g.test(value);
};
