import { Fragment, useEffect, useState } from 'react';
import { Loader } from '@storybook';

import { TERMS_CONDTION_ONETRUST_TOKEN } from 'constants/common';
import { useNextStep } from 'hooks';

import './term-and-condition.scss';

export const TermAndCondition = () => {
	const [isLoading, setIsLoading] = useState(true);

	const { sessionDetails } = useNextStep();

	const { tokenId, url } =
		TERMS_CONDTION_ONETRUST_TOKEN[
			sessionDetails.organization.business as string
		] ??
		(TERMS_CONDTION_ONETRUST_TOKEN['default'] as {
			tokenId: string;
			url: string;
		});
	useEffect(() => {
		const OneTrust = (window as any).OneTrust;
		OneTrust?.NoticeApi?.Initialized?.then(function () {
			OneTrust.NoticeApi.LoadNotices([url]);
		});
		setTimeout(() => setIsLoading(false), 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<Fragment>
			{isLoading && (
				<div className="loader-wrapper">
					<Loader className="loader-blue" dimension={40} />
				</div>
			)}
			<div
				style={{ display: isLoading ? 'none' : 'block' }}
				id={tokenId}
				className="otnotice"
			></div>
		</Fragment>
	);
};
