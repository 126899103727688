import { FC } from 'react';

interface IFileUpload {
	name: string;
	className?: string;
	uploadKey: string | number;
	accept: string;
	capture: boolean | 'user' | 'environment' | undefined;
	children: JSX.Element | string;
	handleOnChange: any;
}
export const FileUpload: FC<IFileUpload> = ({
	name,
	className,
	uploadKey,
	accept,
	capture,
	children,
	handleOnChange,
}) => {
	return (
		<label
			htmlFor={name}
			className={className}
			style={{ marginBottom: '12px' }}
		>
			<input
				type="file"
				onChange={e => handleOnChange(e, uploadKey)}
				id={name}
				accept={accept}
				capture={capture}
				hidden
			/>
			{children}
		</label>
	);
};
