// Importing necessary dependencies, components, and styles
import { Fragment, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button, FileUpload } from '@storybook';
import Modal from 'react-modal';

import emptyScreen from '../../empty-check.svg';
import {
	CapturedScanImageState,
	CheckFileSelectorTypeState,
	IScreen,
} from 'views/check-defense/stores';
import { CameraComponent } from '../camera';
import { rotateImage } from 'utils';
import { useNotification } from 'hooks';

import './scan.scss';

// Interface defining the props for the Scan component
interface IScan {
	type: 'empty' | 'upload' | 'uploaded';
	handleSuccess?: (e?: IScreen) => void;
	handleStep?: (e: IScreen) => void;
}

// Functional component Scan
export const Scan = ({ type, handleSuccess, handleStep }: IScan) => {
	const [loading, setLoading] = useState(true);
	// webcamRef for the camera component
	const webcamRef = useRef<any>(null);

	const [fileSelectorType, setFileSelectorType] = useRecoilState(
		CheckFileSelectorTypeState
	);

	const { errorNotification } = useNotification();

	// State for the captured image, managed by Recoil
	const [capturedImage, setCapturedImage] = useRecoilState(
		CapturedScanImageState
	);

	// Method to handle capturing an image from the camera
	const captureHandler = () => {
		const video: any = document.getElementById('capture-video');

		const canvas = document.createElement('canvas'); //canvasRef.current;
		canvas.height = video.videoHeight;
		canvas.width = video.videoWidth;

		const context = canvas.getContext('2d');
		context?.drawImage(video, 0, 0, canvas.width, canvas.height);
		const fullQuality = canvas.toDataURL('image/jpeg', 1);

		rotateImage(fullQuality, (res: string) =>
			rotateImage(res, (resp: string) =>
				rotateImage(resp, (final: string) => setCapturedImage(final))
			)
		);

		if (fullQuality) {
			handleStep?.('uploaded-scan');
		} else {
			errorNotification('Something went wrong');
		}
	};

	// Method to handle the click event on the "Success" button
	const handleOnClick = () => {
		setFileSelectorType('camera');
		handleSuccess?.();
		return;
	};

	// Method to handle going back to the previous step
	const handleOnBack = (types: IScreen) => {
		handleStep?.(types);
	};

	// Method to rotate the captured image
	const handleRotate = () => {
		rotateImage(capturedImage, (res: string) => {
			setCapturedImage(res);
		});
	};

	const blobToBase64 = async (url: string) => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			// do a request to the blob uri
			const response = await fetch(url);

			// response has a method called .blob() to get the blob file
			const blob = await response.blob();

			// instantiate a file reader
			const fileReader = new FileReader();

			// read the file
			fileReader.readAsDataURL(blob);

			fileReader.onloadend = function () {
				resolve(fileReader.result); // Here is the base64 string
			};
			fileReader.onerror = function () {
				reject('Something went wrong');
			};
		});
	};

	const handleOnUpload = async (e: any) => {
		// Method to handle the click event on the "Success" button
		const file = e.target.files[0];
		const url = window.URL.createObjectURL(file);
		const image = await blobToBase64(url);
		if (image) {
			setCapturedImage(image as string);
			setFileSelectorType('uploaded');
			handleSuccess?.('uploaded-scan');
			return;
		} else {
			return;
		}
	};

	// Memoized rendering of the main component based on the type
	const renderMainComponent = useMemo(() => {
		switch (type) {
			case 'empty':
				return (
					<Fragment>
						<div className="empty-image-wrapper">
							<img src={emptyScreen} />
						</div>

						<div className="empty-btn-wrapper">
							<Button
								label={
									<div className="d-flex gap-8">
										<i className="ri-camera-line"></i> <span>Camera</span>
									</div>
								}
								handleClick={() => handleOnClick()}
								type="button__filled camera-btn button__large button__block"
							/>

							<FileUpload
								className="button__filled button__filled--primary button__large button__block upload-check-btn"
								name={'upload'}
								uploadKey={''}
								accept={'image/*'}
								capture={undefined}
								handleOnChange={handleOnUpload}
							>
								<div
									style={{
										color: 'var(--color-bg-100-light)',
										backgroundColor: 'var(--color-primary-light)',
									}}
								>
									<div className="d-flex gap-8">
										<i className="ri-file-upload-line"></i> <span>Upload</span>
									</div>
								</div>
							</FileUpload>
						</div>
					</Fragment>
				);
			case 'upload':
				return (
					<Modal
						className="image_capture__modal"
						isOpen={true}
						onRequestClose={() => {}}
						contentLabel="Canvas Modal"
					>
						<CameraComponent
							webcamRef={webcamRef}
							setCameraTimeLoading={setLoading}
						/>
						{/* Styling elements for creating a transparent box with overlays */}
						<div className="transparent-box">
							<div className="box box-1"></div>
							<div className="box box-2"></div>
							<div className="box box-3"></div>
							<div className="box box-4"></div>

							<div
								className="close-icon"
								onClick={() => handleOnBack('empty-scan')}
							>
								<i className="ri-close-line"></i>
							</div>

							{/* Text guiding the user to position the check within the rectangular box */}
							<div className="note-text">
								Position your check within the rectangular box.
								<Button
									label="Capture"
									handleClick={captureHandler}
									type="button__filled button__filled--primary button__large button__block"
									disabled={loading}
								/>
							</div>
						</div>
					</Modal>
				);
			case 'uploaded':
				return (
					<div className="cw">
						{capturedImage && (
							<div className="cw__image-wrapper">
								<img src={capturedImage} alt="Captured" />
								<div className="captured-image-wraaper">
									<div
										className="cw__image-wrapper__action"
										onClick={() =>
											handleOnBack(
												fileSelectorType === 'uploaded'
													? 'empty-scan'
													: 'upload-scan'
											)
										}
									>
										<i className="ri-camera-line"></i>
										<span>Retake</span>
									</div>
									<div
										className="cw__image-wrapper__action"
										onClick={handleRotate}
									>
										<i className="ri-clockwise-2-line"></i>
										<span>Rotate</span>
									</div>
								</div>
							</div>
						)}
						<Button
							label="Next"
							handleClick={() => handleStep?.('form')}
							type="button__filled button__filled--primary button__large button__block"
						/>
					</div>
				);
			default:
				return <div>Component Not Found</div>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, loading, webcamRef, capturedImage]);

	// JSX structure for the Scan component
	return (
		<div className="scan-wrapper">
			<div className="scan-wrapper__inner">{renderMainComponent}</div>
		</div>
	);
};
