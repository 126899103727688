import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { InvestorsDetails } from './state';
import {
	IInvestor,
	IInvestorDetails,
	INVESTORS_TYPE_STRING,
	NOTIFICATION_MESSAGE,
} from '../constant';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { IDynamicForm, IQuestionPage, IQuestionPageElement } from '../types';

const {
	INVALID_ORDER,
	FIX_ERRORS,
	MIN_SUM_LIMIT,
	MAX_SUM_LIMIT,
	EMPTY_INVESTORS,
} = NOTIFICATION_MESSAGE;

export const useInvestorDetails = () => {
	const [totalInvestors, setTotalInvestors] =
		useRecoilState<IInvestor[]>(InvestorsDetails);
	const sessionDetails = useRecoilValue(SessionDetailsState);

	const shareholderThresholdValues = useCallback(() => {
		let returnData = {
			complianceSessionThreshold: 25,
			minTotalShareOwned: 50,
		};
		sessionDetails?.nodes?.pipelineActions?.forEach((item: IDynamicForm) => {
			item?.metadata?.questions?.pages?.forEach((page: IQuestionPage) => {
				page?.elements?.forEach((element: IQuestionPageElement) => {
					if (element.type === INVESTORS_TYPE_STRING) {
						returnData = {
							complianceSessionThreshold: Number(
								element.complianceSessionThreshold
							),
							minTotalShareOwned: Number(element.minTotalShareOwned),
						};
					}
				});
			});
		});
		return returnData;
	}, [sessionDetails]);

	const disableExtraRows = useCallback(() => {
		const threasholdKycValues = shareholderThresholdValues();
		if (totalInvestors.length) {
			setTotalInvestors((prev: IInvestor[]) => {
				let sum = 0;
				let sumIndex = -1;
				prev.forEach((item: IInvestor, index: number) => {
					sum += Number(item.shareOwned.value ?? 0);
					if (
						sum >= threasholdKycValues.minTotalShareOwned &&
						sumIndex === -1
					) {
						sumIndex = index;
					}
				});
				return prev.map((investor, index) => {
					if (index > sumIndex) {
						return { ...investor, disableRow: sumIndex > -1 };
					} else {
						return { ...investor, disableRow: false };
					}
				});
				return prev;
			});
		}
	}, [setTotalInvestors, totalInvestors, shareholderThresholdValues]);

	const ValidateSubmit = useCallback(
		(investorsDetails: IInvestorDetails[]) => {
			const threasholdKycValues = shareholderThresholdValues();
			if (investorsDetails.length) {
				const emailSet = new Set();
				const phoneSet = new Set();
				const userWithError = investorsDetails.find(
					(investor: IInvestorDetails) => {
						return Object.keys(investor).find(key => {
							const { error, value } =
								investor[key as keyof IInvestorDetails] ?? {};
							return error || value === '';
						});
					}
				);
				if (userWithError) {
					return FIX_ERRORS;
				}
				for (let index = 0; index < investorsDetails.length; index++) {
					const investor = investorsDetails[index] as IInvestorDetails;
					if (index > 0) {
						const currentSharesOwned = Number(investor.shareOwned.value);
						const previousSharesOwned = Number(
							(investorsDetails[index - 1] as IInvestorDetails).shareOwned.value
						);
						if (currentSharesOwned > previousSharesOwned) {
							return INVALID_ORDER;
						}
					}

					if (Number(investor.shareOwned.value) <= 0) {
						return `Share owned by ${index + 1} Shareholder/Owner can not be 0%`;
					}

					// Check for duplicate email id
					const email = (investor.email.value as string).trim();
					if (emailSet.has(email)) {
						return `Duplicate email id "${email}" found for Shareholder/Owner ${index + 1}`;
					} else {
						emailSet.add(email);
					}

					// Check for duplicate phone number
					const phone = (investor.phoneNumber.value as string).trim();
					if (phoneSet.has(phone)) {
						return `Duplicate phone number "${phone}" found for Shareholder/Owner ${index + 1}`;
					} else {
						phoneSet.add(phone);
					}
				}
				const sharesSum = investorsDetails.reduce(
					(acc: number, curr: IInvestorDetails) => {
						acc += Number(curr.shareOwned.value || 0);
						return acc;
					},
					0
				);
				if (sharesSum < threasholdKycValues.minTotalShareOwned) {
					return `${MIN_SUM_LIMIT} ${threasholdKycValues.minTotalShareOwned}%`;
				}
				if (sharesSum > 100) {
					return MAX_SUM_LIMIT;
				}
				return '';
			} else {
				return EMPTY_INVESTORS;
			}
		},
		[shareholderThresholdValues]
	);

	return { disableExtraRows, ValidateSubmit, shareholderThresholdValues };
};
