import { atom } from 'recoil';

import { ICHECK_DETAILS_FORM } from './types';

export const CheckDefenseFormMetaData = atom<ICHECK_DETAILS_FORM[]>({
	key: 'check-defense-form-meta-data',
	default: [
		{
			name: 'routeNumber',
			placeholder: 'Routing Number',
			label: 'Routing Number',
			type: 'text',
			width: 'calc(50% - 8px)',
			regex: /^[a-zA-Z0-9_' ,.]+$/i,
		},
		{
			name: 'accountNumber',
			placeholder: 'Account Number',
			label: 'Account Number',
			type: 'text',
			width: 'calc(50% - 8px)',
			regex: /^[a-zA-Z0-9]+$/i,
		},

		{
			name: 'checkNumber',
			placeholder: 'Check Number',
			label: 'Check Number',
			type: 'text',
			width: 'calc(50% - 8px)',
			regex: /^[a-zA-Z0-9_' ,.]+$/i,
		},
		{
			name: 'amount',
			placeholder: 'Amount',
			label: 'Amount',
			type: 'number',
			width: 'calc(50% - 8px)',
			prefixIcon: 'dollar',
			pattern: '[0-9]*',
		},
		// {
		// 	name: 'bankBranch',
		// 	placeholder: 'Select Branch',
		// 	label: 'Bank Branch',
		// 	type: 'select',
		// 	width: '100%',
		// },
	],
});

export const CheckDetailsFormState = atom<any>({
	key: 'check-details-form-state',
	default: {},
});

export const CapturedScanImageState = atom<string>({
	key: 'captured-scan-image-state',
	default: '',
});

export const CheckFileSelectorTypeState = atom({
	key: 'check-file-selector-type-state',
	default: 'camera',
});
