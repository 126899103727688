import { FC, Fragment, useEffect, useMemo, useState } from 'react';

import { useNextStep } from 'hooks';
import { SkeltonLoader } from '@storybook/skelton-loader';
import { LABEL } from './constant';

import './header.scss';
import { Image } from '@storybook/image';

interface IHeader {
	logo?: string;
	loading?: boolean;
	currentKey?: string;
	handleSkip: VoidFunction;
}

export const Header: FC<IHeader> = ({
	logo,
	loading,
	currentKey,
	handleSkip,
}) => {
	const [loader, setLoading] = useState(true);
	const [isInIframe, setIsInIframe] = useState(false);

	const { sessionPayloadDetail } = useNextStep();

	useEffect(() => {
		if (window.location !== window.parent.location) {
			setIsInIframe(true);
		}
	}, []);

	const labal = useMemo(() => {
		let labelKey = currentKey;
		if (labelKey === 'fundInvestmentVerification') {
			labelKey = (sessionPayloadDetail as any).currentAction.metadata
				?.payInPayOut;
		}
		return LABEL[labelKey as string];
	}, [currentKey, sessionPayloadDetail]);

	const LOGO =
		'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png';

	return (
		<div className={isInIframe ? 'header-container-frame' : 'header-container'}>
			<div className={isInIframe ? 'logo-frame' : 'logo'}>
				<Fragment>
					{(loader || loading) && (
						<SkeltonLoader height={isInIframe ? '30px' : '60px'} width="96px" />
					)}
					{!loading && (
						<Image
							url={logo || LOGO}
							className={
								loader
									? 'd-none'
									: isInIframe
										? 'logo-frame animate-in-2'
										: 'logo animate-in-2'
							}
							onLoad={() => setLoading(false)}
							style={{ maxWidth: '200px' }}
						/>
					)}
				</Fragment>
			</div>
			{currentKey && (
				<div className="skip-steps-wrapper" onClick={handleSkip}>
					<div className="skip-steps-wrapper__label">Skip {labal}</div>
					<i className="ri-skip-forward-line"></i>
				</div>
			)}
		</div>
	);
};
