import { FC } from 'react';

import { ComponentType } from './stores/types';
import { ComponentWrapper } from './components';
import { GetAllComponents } from './all-components';

interface IRoot {
	componentKey: ComponentType;
	componentProps?: any;
}

export const ComponentRoot: FC<IRoot> = ({ componentKey, componentProps }) => {
	const props = {
		...(componentProps && { componentProps }),
		componentKey,
	};
	return (
		<ComponentWrapper>
			<GetAllComponents {...props} />
		</ComponentWrapper>
	);
};
