import { atom } from 'recoil';
import { IAccessToken, IWebComponentState, LocationInfo } from './type';

export const AccessTokenState = atom<IAccessToken>({
	key: 'access-token-state-key',
	default: {
		token: '',
		code: '',
		type: '',
		isWebComponent: false,
	},
});

export const WebComponentMetaDataState = atom<IWebComponentState>({
	key: 'webcomponent-state-key',
	default: {
		isWebComponent: false,
		env: 'prod',
		apiEndPoint: '',
		steps: null,
		biometric: true,
		termsCondition: true,
	},
});

export const GoogleLocation = atom<null | LocationInfo>({
	key: 'google-location-state',
	default: null,
});
