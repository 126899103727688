import { atom } from 'recoil';

export const CameraScreenActive = atom<boolean>({
	key: 'cameraScreenActive',
	default: false,
});

export const SessionIdComplex = atom<string>({
	key: 'sessionIdComplex',
	default: '',
});

export const CaptureImage = atom<string>({
	key: 'CaptureImage',
	default: '',
});

export const SubmitBtnLoad = atom<boolean>({
	key: 'SubmitBtnLoad',
	default: false,
});

export const IsValidSelfie = atom<boolean>({
	key: 'IsValidSelfie',
	default: true,
});
