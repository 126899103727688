import ReactDOM from 'react-dom';
import {
	Calendar,
	DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import React, {
	ChangeEvent,
	CSSProperties,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import InputMask from 'react-input-mask';

import { useOutsideClickAdvanced } from 'hooks';
import './custom-date-picker.scss';

const currentDate = new Date();
const currentDay = currentDate.getDate();
const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so January is 0
const currentYear = currentDate.getFullYear();
interface IDateCalender {
	optionalClassName?: string;
	value?: DayValue | any;
	onChange?: any;
	errorMessage?: string;
	isError?: boolean;
	label: string;
	isRequired?: boolean;
	id?: string;
	formate?: string;
	name?: string;
	disabled?: boolean;
}

export const CustomDatePicker: React.FC<IDateCalender> = ({
	value,
	onChange,
	isError = false,
	errorMessage,
	label,
	isRequired = false,
	id,
	formate = 'MM/DD/YYYY',
	name,
	disabled,
}) => {
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const [popupAbove, setPopupAbove] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const calendarRef = useRef<HTMLDivElement>(null);

	const formatDate = ({
		year,
		month,
		day,
	}: {
		day: number;
		month: number;
		year: number;
	}) => {
		return `${month.toString().padStart(2, '0')}/${day
			.toString()
			.padStart(2, '0')}/${year}`;
	};

	useEffect(() => {
		const { day = '', month = '', year = '' } = value ?? {};
		if (day && month && year) {
			setInputValue(formatDate(value));
		} else {
			setInputValue('');
		}
	}, [value]);

	const onCalenderChange = (day: DayValue) => {
		if (day) {
			setInputValue(formatDate(day));
			const formattedDate = {
				target: {
					value: day,
					name,
				},
			};
			onChange(formattedDate);
		}
		setShowCalendar(false);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setShowCalendar(false);
		let { value } = e.target;
		// Remove any non-numeric characters
		value = value.replace(/\D/g, '');

		// Format the input with slashes
		if (value.length > 2 && value.length <= 4) {
			value = `${value.slice(0, 2)}/${value.slice(2)}`;
		} else if (value.length > 4) {
			value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
		}
		const [month, day, year] = value.split('/');
		if ((month && +month > 12) || (day && +day > 31)) {
			return;
		}
		// Update the input value
		setInputValue(value);
		const newDate = {
			day: parseInt(day as string, 10),
			month: parseInt(month as string, 10),
			year: parseInt(year as string, 10),
		};

		const formattedDate = {
			target: {
				value: newDate,
				name,
			},
		};

		onChange(formattedDate);
	};

	// Dynamically calculate the space and decide position for calendar popup
	const calculatePopupPosition = () => {
		if (wrapperRef.current) {
			const inputRect = wrapperRef.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - inputRect.bottom;
			const spaceAbove = inputRect.top;

			// Determine if there's more space above or below
			if (spaceBelow < 365 && spaceAbove > spaceBelow) {
				setPopupAbove(true);
			} else {
				setPopupAbove(false);
			}
		}
	};

	useEffect(() => {
		if (showCalendar) {
			calculatePopupPosition();
		}
	}, [showCalendar]);

	useOutsideClickAdvanced([wrapperRef, calendarRef], () => {
		setShowCalendar(false);
	});

	const calenderStyle: CSSProperties = useMemo(() => {
		return {
			position: 'absolute',
			top: wrapperRef.current
				? popupAbove
					? wrapperRef.current.getBoundingClientRect().top +
						window.scrollY -
						(calendarRef?.current?.scrollHeight || 365)
					: wrapperRef.current.getBoundingClientRect().bottom + window.scrollY
				: 0,
			left: wrapperRef.current
				? wrapperRef.current.getBoundingClientRect().left + window.scrollX
				: 0,
			zIndex: showCalendar ? 1000 : -1,
			opacity: showCalendar ? 1 : 0,
			pointerEvents: showCalendar ? undefined : 'none',
		};
	}, [popupAbove, showCalendar]);

	return (
		<div ref={wrapperRef} className="custom-date-picker">
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<InputMask
				onClick={() => setShowCalendar(preState => !preState)}
				placeholder={formate}
				value={inputValue}
				onChange={handleInputChange}
				className={`react-date-picker ${isError ? 'active-error-message' : ''}`}
				disabled={disabled}
			/>

			{ReactDOM.createPortal(
				<div ref={calendarRef} style={calenderStyle}>
					<Calendar
						value={value}
						onChange={onCalenderChange}
						shouldHighlightWeekends
						calendarClassName="responsive-calendar"
						maximumDate={{
							year: currentYear,
							month: currentMonth,
							day: currentDay,
						}}
					/>
				</div>,
				document.body
			)}
			{isError && <span className="error-message">{errorMessage}</span>}
		</div>
	);
};
