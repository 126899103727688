import { atom } from 'recoil';

export interface IAccreditedByVerifierState {
	[key: string]: {
		firstName: string;
		lastName: string;
		email: string;
		[key: string]: string;
	};
}

export const AccreditedByVerifierState = atom<IAccreditedByVerifierState>({
	key: 'accredited-by-verifier-state-key',
	default: {},
});

export const AccreditedByVerifierInputErrorState =
	atom<IAccreditedByVerifierState>({
		key: 'accredited-by-verifier-input-error-state-key',
		default: {},
	});

export const SelectedItemComponentForAccreditationState = atom<{
	[key: string]: string | number | JSX.Element;
}>({
	key: 'selected-item-component-for-accreditation-state-key',
	default: {},
});
