import { isMobileDevice } from 'utils';
import { calculateCenterX, distance2D } from './geometryUtils';
export function drawPlaceholder(
	style: any,
	canvas: HTMLCanvasElement,
	gestureRecognizerResults: any
): void {
	const canvasCtx = canvas?.getContext('2d');

	if (!canvasCtx || !canvas) {
		return;
	}
	const { lineColor, lineWidth } = style;
	const isRightHand =
		gestureRecognizerResults.current?.handednesses?.[0]?.[0]?.displayName ===
		'Left';
	const mobileDeviceCenterX = calculateCenterX(canvas.width, isRightHand);
	const centerX = isMobileDevice() ? mobileDeviceCenterX : canvas.width / 2;

	const centerY = isMobileDevice()
		? canvas.height / 2 + 200
		: canvas.height / 2 + 80;
	const radius = isMobileDevice()
		? distance2D(centerX, 0, centerX, centerY) / 8
		: distance2D(centerX, 0, centerX, centerY) / 6;
	const borderWidth = lineWidth;

	// Draw circle
	canvasCtx.beginPath();
	canvasCtx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
	canvasCtx.lineWidth = borderWidth;
	canvasCtx.strokeStyle = lineColor;
	canvasCtx.stroke();
}

function calculateEntropy(data: Uint8ClampedArray) {
	const histogram = new Array(256).fill(0);
	const totalPixels = data.length / 4;

	for (let i = 0; i < data.length; i += 4) {
		const r = data[i];
		const g = data[i + 1];
		const b = data[i + 2];
		// Convert RGB to grayscale using luminance formula
		if (r !== undefined && g !== undefined && b !== undefined) {
			const gray = 0.299 * r + 0.587 * g + 0.114 * b;
			histogram[Math.floor(gray)]++;
		}
	}

	let entropy = 0;
	for (let i = 0; i < 256; i++) {
		if (histogram[i] > 0) {
			const p = histogram[i] / totalPixels;
			entropy -= p * Math.log2(p);
		}
	}
	return entropy;
}

export function checkIsImageBlank(data: Uint8ClampedArray) {
	const entropy = calculateEntropy(data);
	const entropyThreshold = 5; // Adjust threshold as needed
	return entropy < entropyThreshold;
}
