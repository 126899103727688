import { Image, Loader } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { selectedBankState } from 'states';
import { ILogoTypes, getLogos } from 'views/fund-investment/constants';
import { FundBankDetailsState } from '../../stores';
import { PayoutPayment } from '../payment';
import { PayoutSummary } from '../payment-summary';

import './bank-details.scss';

interface IBankDetails {
	handleBack: VoidFunction;
}

export const PayoutBankDetails: FC<IBankDetails> = ({ handleBack }) => {
	const [dropDownIndex, setDropDownIndex] = useState(0);
	//globle states
	const fundBankDetails = useRecoilValue(FundBankDetailsState);
	const setSelectBank = useSetRecoilState(selectedBankState);

	//local states
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedAccountId, setSelectedAccountId] = useState('');
	const [isLoading, setLoading] = useState(false);

	const { data } = useMemo(() => fundBankDetails ?? {}, [fundBankDetails]);

	const onSelect = useCallback(
		(index: number, id: string, institutionName: string) => {
			setSelectedIndex(index);
			setSelectedAccountId(id);
			setSelectBank(pre => ({ ...pre, institutionName }));
			if (dropDownIndex === index) {
				setDropDownIndex(-1);
			} else setDropDownIndex(index);
		},
		[dropDownIndex, setSelectBank]
	);

	useEffect(() => {
		if (
			data?.accounts &&
			data?.accounts[0]?._id &&
			data?.accounts[0]?.account &&
			data?.accounts[0]?.institutionName
		) {
			setSelectedAccountId(data.accounts[0]?._id as string);
			setSelectBank(pre => ({
				...pre,
				institutionName: data?.accounts[0]?.institutionName,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const renderBankAccountDetails = useMemo(() => {
		return data?.accounts?.map(
			(
				{ institutionName, totalAvailableBalance, account, _id }: any,
				index: number
			) => {
				return (
					<div
						key={`${selectedIndex}__${index.toString()}`}
						className={`bank-account-detail ${
							selectedIndex === index ? 'bank-account-detail__selected' : ''
						}`}
					>
						<div className="bank-details__card_inner_bank">
							<div
								style={{
									display: 'flex',
									gap: 16,
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '8px 16px',
								}}
								onClick={() =>
									onSelect(index, _id as string, institutionName as string)
								}
							>
								<div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
									<div className="bank-details__logo_wrapper">
										<Image
											fileName={
												getLogos[institutionName as ILogoTypes] ??
												getLogos['default']
											}
										/>
									</div>
									<div>
										<div className="bank-details__institution_name">
											{institutionName}
										</div>
										<div className="bank-details__amount_wrapper">
											<div className="bank-details__avail_balance">
												${Number(totalAvailableBalance ?? 0).toFixed(2)}
											</div>
										</div>
									</div>
								</div>
								<i
									className="ri-arrow-down-s-line"
									style={{
										fontSize: 24,
										transform:
											dropDownIndex === index ? 'scale(-1)' : 'scale(1)',
									}}
								></i>
							</div>
							{dropDownIndex === index && (
								<div style={{ borderTop: '1px solid #D7DFF5' }}>
									<PayoutPayment accounts={account} />
								</div>
							)}
						</div>
					</div>
				);
			}
		);
	}, [data?.accounts, dropDownIndex, onSelect, selectedIndex]);

	const mainComponent = useMemo(() => {
		return isLoading ? (
			<div className="summary-loader">
				<Loader />
			</div>
		) : (
			<div className="bank-details__account-details">
				<div className="bank-details__account-details__header">
					<div className="bank-details__account-details__header__title">
						Select Account Type
					</div>
				</div>
				<div className="bank-details__account-details__lists">
					{renderBankAccountDetails}
				</div>
				<PayoutSummary
					tokenId={selectedAccountId}
					handleBack={handleBack}
					setLoading={setLoading}
				/>
			</div>
		);
	}, [handleBack, isLoading, renderBankAccountDetails, selectedAccountId]);

	return <div className="bank-details">{mainComponent}</div>;
};
