export const OfflineSvg = `<svg
width="194"
height="192"
viewBox="0 0 194 192"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
    d="M76.0846 20.8151C34.5611 32.3109 10.2188 75.2915 21.7146 116.815C33.2104 158.339 76.1911 182.681 117.715 171.185C159.238 159.689 183.58 116.709 172.085 75.1851C160.589 33.6616 117.608 9.31926 76.0846 20.8151Z"
    fill="#F1F3F9"
    stroke="#D6DBE8"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M118.269 43.499L119.75 42.7516C119.828 42.7299 119.963 42.6082 119.92 42.4514L119.799 40.7973C119.813 40.5405 119.926 40.3404 120.161 40.2753C120.396 40.2102 120.675 40.3019 120.74 40.537L121.487 42.0175C121.509 42.0959 121.631 42.2309 121.787 42.1875L123.442 42.0671C123.698 42.0804 123.898 42.1937 123.964 42.4288C124.029 42.6639 123.937 42.9424 123.702 43.0075L122.221 43.7549C122.143 43.7766 122.008 43.8983 122.051 44.0551L122.172 45.7092C122.158 45.966 122.045 46.1661 121.81 46.2312C121.575 46.2963 121.296 46.2046 121.231 45.9695L120.484 44.489C120.462 44.4106 120.341 44.2756 120.184 44.319L118.53 44.4394C118.273 44.4261 118.073 44.3128 118.008 44.0777C117.964 43.921 118.056 43.6425 118.269 43.499Z"
    fill="#D6DBE8"
/>
<path
    d="M47.1845 52.0128L48.596 51.3003C48.6707 51.2796 48.7994 51.1636 48.758 51.0142L48.6432 49.4372C48.6559 49.1924 48.7639 49.0016 48.9881 48.9396C49.2122 48.8775 49.4777 48.9649 49.5397 49.189L50.2522 50.6005C50.2729 50.6752 50.389 50.8039 50.5384 50.7626L52.1153 50.6477C52.3602 50.6604 52.5509 50.7684 52.613 50.9926C52.675 51.2167 52.5877 51.4822 52.3635 51.5443L50.9521 52.2568C50.8774 52.2774 50.7486 52.3935 50.79 52.5429L50.9048 54.1199C50.8922 54.3647 50.7841 54.5555 50.56 54.6175C50.3359 54.6796 50.0704 54.5922 50.0083 54.3681L49.2958 52.9566C49.2751 52.8819 49.1591 52.7532 49.0096 52.7945L47.4327 52.9094C47.1879 52.8967 46.9971 52.7886 46.9351 52.5645C46.873 52.3404 46.9604 52.0749 47.1845 52.0128Z"
    fill="#D6DBE8"
/>
<path
    d="M97.7481 67.4686L98.8881 66.8931C98.9484 66.8764 99.0524 66.7826 99.019 66.662L98.9262 65.3883C98.9365 65.1906 99.0237 65.0365 99.2048 64.9864C99.3858 64.9363 99.6002 65.0068 99.6503 65.1879L100.226 66.3279C100.243 66.3882 100.336 66.4922 100.457 66.4588L101.731 66.366C101.928 66.3762 102.082 66.4635 102.133 66.6445C102.183 66.8256 102.112 67.04 101.931 67.0901L100.791 67.6656C100.731 67.6823 100.627 67.776 100.66 67.8967L100.753 69.1704C100.743 69.3681 100.655 69.5222 100.474 69.5723C100.293 69.6224 100.079 69.5519 100.029 69.3708L99.4533 68.2308C99.4366 68.1705 99.3429 68.0665 99.2222 68.0999L97.9485 68.1927C97.7508 68.1824 97.5967 68.0952 97.5466 67.9141C97.4529 67.8102 97.5838 67.579 97.7481 67.4686Z"
    fill="#D6DBE8"
/>
<path
    d="M52.8352 147.756L54.8819 146.723C54.9903 146.693 55.1769 146.524 55.1169 146.308L54.9505 144.021C54.9688 143.666 55.1255 143.389 55.4505 143.299C55.7755 143.21 56.1605 143.336 56.2504 143.661L57.2836 145.708C57.3136 145.816 57.4819 146.003 57.6986 145.943L59.9852 145.776C60.3402 145.795 60.6168 145.951 60.7068 146.276C60.7968 146.601 60.6701 146.986 60.3451 147.076L58.2984 148.11C58.1901 148.14 58.0034 148.308 58.0634 148.525L58.2299 150.811C58.2115 151.166 58.0549 151.443 57.7299 151.533C57.4049 151.623 57.0199 151.496 56.9299 151.171L55.8967 149.124C55.8667 149.016 55.6984 148.829 55.4818 148.889L53.1951 149.056C52.8402 149.037 52.5635 148.881 52.4735 148.556C52.3836 148.231 52.5402 147.954 52.8352 147.756Z"
    fill="#D6DBE8"
/>
<path
    d="M156.675 93.598L157.747 91.9896C157.814 91.9226 157.881 91.7216 157.747 91.5875L156.675 89.9791C156.541 89.711 156.541 89.443 156.742 89.2419C156.943 89.0409 157.278 88.9739 157.479 89.1749L159.088 90.2472C159.155 90.3142 159.356 90.3812 159.49 90.2472L161.098 89.1749C161.366 89.0409 161.634 89.0409 161.835 89.2419C162.037 89.443 162.104 89.7781 161.903 89.9791L160.83 91.5875C160.763 91.6545 160.696 91.8556 160.83 91.9896L161.903 93.598C162.037 93.8661 162.037 94.1342 161.835 94.3352C161.634 94.5363 161.299 94.6033 161.098 94.4022L159.49 93.33C159.423 93.263 159.222 93.1959 159.088 93.33L157.479 94.4022C157.211 94.5363 156.943 94.5363 156.742 94.3352C156.541 94.1342 156.474 93.7991 156.675 93.598Z"
    fill="#D6DBE8"
/>
<path
    d="M80.3577 163.417L82.2801 162.447C82.3818 162.418 82.5571 162.26 82.5008 162.057L82.3444 159.909C82.3617 159.576 82.5088 159.316 82.8141 159.231C83.1193 159.147 83.4809 159.266 83.5654 159.571L84.5358 161.493C84.564 161.595 84.7221 161.771 84.9256 161.714L87.0733 161.558C87.4068 161.575 87.6666 161.722 87.7511 162.027C87.8356 162.333 87.7166 162.694 87.4114 162.779L85.489 163.749C85.3873 163.777 85.2119 163.935 85.2683 164.139L85.4247 166.287C85.4074 166.62 85.2603 166.88 84.955 166.965C84.6497 167.049 84.2882 166.93 84.2036 166.625L83.2332 164.702C83.2051 164.601 83.047 164.425 82.8435 164.482L80.6957 164.638C80.3623 164.621 80.1025 164.474 80.018 164.168C79.9334 163.863 80.0524 163.502 80.3577 163.417Z"
    fill="#D6DBE8"
/>
<path
    d="M43.4038 114.24L44.741 111.869C44.8281 111.768 44.9011 111.478 44.6988 111.304L43.0242 109.158C42.8078 108.795 42.7797 108.419 43.041 108.115C43.3022 107.812 43.7658 107.683 44.0693 107.944L46.4406 109.281C46.5418 109.368 46.8312 109.441 47.0054 109.239L49.1518 107.564C49.5142 107.348 49.8907 107.32 50.1941 107.581C50.4976 107.842 50.6269 108.306 50.3656 108.609L49.0284 110.981C48.9413 111.082 48.8682 111.371 49.0705 111.546L50.7452 113.692C50.9616 114.054 50.9897 114.431 50.7284 114.734C50.4671 115.038 50.0036 115.167 49.7001 114.906L47.3287 113.569C47.2276 113.481 46.9382 113.408 46.764 113.611L44.6176 115.285C44.2552 115.502 43.8787 115.53 43.5752 115.269C43.2718 115.007 43.1425 114.544 43.4038 114.24Z"
    fill="#D6DBE8"
/>
<path
    d="M135.583 67.3234L136.844 66.7145C136.964 66.6811 136.991 66.5437 136.975 66.4833L136.848 65.089C136.859 64.8913 136.99 64.6601 137.171 64.61C137.352 64.5599 137.566 64.6305 137.693 64.8551L138.302 66.1158C138.336 66.2365 138.473 66.2634 138.533 66.2467L139.928 66.1206C140.125 66.1308 140.356 66.2617 140.407 66.4427C140.457 66.6237 140.386 66.8382 140.161 66.9653L138.901 67.5742C138.78 67.6076 138.753 67.745 138.77 67.8054L138.896 69.1997C138.886 69.3974 138.755 69.6286 138.574 69.6787C138.393 69.7288 138.178 69.6582 138.051 69.4336L137.442 68.1729C137.409 68.0522 137.272 68.0253 137.211 68.042L135.817 68.1681C135.619 68.1579 135.388 68.027 135.338 67.846C135.288 67.665 135.419 67.4338 135.583 67.3234Z"
    fill="#D6DBE8"
/>
<path
    d="M124.381 145.627L127.222 144.255C127.494 144.18 127.554 143.87 127.517 143.734L127.232 140.592C127.255 140.147 127.55 139.626 127.958 139.513C128.366 139.4 128.849 139.559 129.136 140.065L130.508 142.906C130.583 143.178 130.893 143.239 131.029 143.201L134.171 142.917C134.617 142.94 135.138 143.235 135.25 143.643C135.363 144.051 135.204 144.534 134.698 144.821L131.857 146.193C131.585 146.268 131.525 146.578 131.562 146.714L131.846 149.856C131.823 150.301 131.528 150.822 131.12 150.935C130.713 151.048 130.229 150.889 129.943 150.383L128.571 147.542C128.495 147.27 128.186 147.209 128.05 147.247L124.908 147.531C124.462 147.508 123.941 147.213 123.828 146.805C123.715 146.397 123.874 145.914 124.381 145.627Z"
    fill="#D6DBE8"
/>
<path
    d="M137.694 116.547L139.736 115.561C139.931 115.507 139.975 115.284 139.948 115.186L139.743 112.928C139.76 112.608 139.972 112.234 140.265 112.153C140.558 112.071 140.905 112.186 141.111 112.549L142.098 114.591C142.152 114.787 142.374 114.83 142.472 114.803L144.73 114.599C145.05 114.615 145.425 114.827 145.506 115.121C145.587 115.414 145.473 115.761 145.109 115.967L143.067 116.953C142.872 117.007 142.828 117.23 142.855 117.328L143.059 119.586C143.043 119.906 142.831 120.28 142.538 120.361C142.244 120.443 141.897 120.328 141.691 119.964L140.705 117.923C140.651 117.727 140.429 117.684 140.331 117.711L138.073 117.915C137.752 117.898 137.378 117.686 137.297 117.393C137.216 117.1 137.428 116.726 137.694 116.547Z"
    fill="#D6DBE8"
/>
<path
    d="M24.1248 86.6424L27.1526 86.3685C27.4146 86.2959 27.6182 86.5217 27.6545 86.6527L28.9766 89.3902C29.2165 89.747 29.7184 90.0313 30.1115 89.9225C30.5046 89.8137 30.8251 89.4428 30.811 88.8824L30.5371 85.8547C30.4645 85.5926 30.6903 85.389 30.8213 85.3528L33.5588 84.0306C33.9157 83.7908 34.1999 83.2889 34.0911 82.8958C33.9822 82.5027 33.6114 82.1821 33.051 82.1962L30.0233 82.4702C29.7612 82.5427 29.5577 82.3169 29.5214 82.1859L28.1992 79.4484C27.9594 79.0916 27.4574 78.8073 27.0644 78.9161C26.6713 79.025 26.3508 79.3959 26.3648 79.9562L26.6388 82.9839C26.7113 83.246 26.4855 83.4496 26.3545 83.4859L23.617 84.808C23.2602 85.0479 22.9759 85.5498 23.0848 85.9429C23.2299 86.467 23.7318 86.7512 24.1248 86.6424Z"
    fill="#D6DBE8"
/>
<path
    d="M7.93858 146.899L12.2195 146.512C12.59 146.409 12.8778 146.729 12.9291 146.914L14.7986 150.784C15.1377 151.289 15.8474 151.691 16.4031 151.537C16.9589 151.383 17.4121 150.859 17.3922 150.066L17.0049 145.785C16.9023 145.415 17.2215 145.127 17.4068 145.076L21.2774 143.206C21.7819 142.867 22.1838 142.158 22.0299 141.602C21.8761 141.046 21.3517 140.593 20.5594 140.613L16.2784 141C15.9079 141.103 15.6201 140.783 15.5688 140.598L13.6994 136.728C13.3602 136.223 12.6506 135.821 12.0948 135.975C11.539 136.129 11.0858 136.653 11.1057 137.446L11.493 141.727C11.5956 142.097 11.2764 142.385 11.0911 142.436L7.22052 144.306C6.71602 144.645 6.31413 145.354 6.468 145.91C6.67316 146.651 7.38279 147.053 7.93858 146.899Z"
    fill="#F1F3F9"
/>
<path
    d="M15.0471 38.2277L18.9942 37.8705C19.3358 37.7759 19.6012 38.0703 19.6485 38.2411L21.3722 41.8099C21.6849 42.2751 22.3392 42.6456 22.8516 42.5038C23.3641 42.3619 23.7819 41.8784 23.7636 41.1478L23.4065 37.2007C23.3119 36.8591 23.6062 36.5937 23.777 36.5464L27.3459 34.8227C27.811 34.5101 28.1816 33.8557 28.0397 33.3433C27.8978 32.8309 27.4143 32.413 26.6838 32.4313L22.7367 32.7884C22.395 32.883 22.1296 32.5887 22.0823 32.4179L20.3587 28.8491C20.046 28.3839 19.3917 28.0133 18.8792 28.1552C18.3668 28.2971 17.9489 28.7806 17.9673 29.5111L18.3244 33.4583C18.419 33.7999 18.1246 34.0653 17.9538 34.1126L14.385 35.8362C13.9198 36.1489 13.5493 36.8032 13.6912 37.3157C13.8803 37.999 14.5346 38.3695 15.0471 38.2277Z"
    fill="#F1F3F9"
/>
<path
    d="M171.414 112.158L176.794 114.075C177.298 114.158 177.468 114.703 177.426 114.955L177.525 120.666C177.654 121.464 178.286 122.344 179.042 122.468C179.798 122.592 180.637 122.212 181.054 121.245L182.972 115.865C183.055 115.36 183.6 115.191 183.852 115.232L189.563 115.133C190.36 115.005 191.241 114.373 191.365 113.617C191.489 112.86 191.108 112.022 190.142 111.604L184.761 109.687C184.257 109.604 184.088 109.059 184.129 108.807L184.03 103.096C183.902 102.298 183.27 101.418 182.513 101.294C181.757 101.17 180.918 101.55 180.501 102.517L178.583 107.897C178.501 108.401 177.955 108.571 177.703 108.53L171.992 108.629C171.195 108.757 170.315 109.389 170.191 110.145C170.025 111.154 170.658 112.034 171.414 112.158Z"
    fill="#F1F3F9"
/>
<path
    d="M58.5744 57.849C58.0746 57.9874 57.7815 58.5049 57.9199 59.0047C58.0583 59.5046 58.5757 59.7977 59.0756 59.6593C59.5755 59.5209 59.8685 59.0035 59.7301 58.5036C59.5917 58.0037 59.0743 57.7106 58.5744 57.849Z"
    fill="#D6DBE8"
/>
<path
    d="M90.8991 81.4482C90.3992 81.5866 90.1062 82.104 90.2446 82.6039C90.383 83.1037 90.9004 83.3968 91.4003 83.2584C91.9002 83.12 92.1932 82.6026 92.0548 82.1027C91.9164 81.6028 91.399 81.3098 90.8991 81.4482Z"
    fill="#D6DBE8"
/>
<path
    d="M111.897 43.378C111.632 43.824 111.779 44.4002 112.225 44.665C112.671 44.9298 113.248 44.7829 113.512 44.3369C113.777 43.8909 113.63 43.3147 113.184 43.0499C112.738 42.7851 112.162 42.932 111.897 43.378Z"
    fill="#D6DBE8"
/>
<path
    d="M81.5948 25.3785C81.3301 25.8245 81.477 26.4007 81.923 26.6655C82.369 26.9303 82.9452 26.7834 83.21 26.3373C83.4747 25.8913 83.3278 25.3151 82.8818 25.0504C82.4358 24.7856 81.8596 24.9325 81.5948 25.3785Z"
    fill="#D6DBE8"
/>
<path
    d="M27.5369 66.7247C27.2721 67.1707 27.4191 67.7469 27.8651 68.0117C28.3111 68.2765 28.8873 68.1295 29.152 67.6835C29.4168 67.2375 29.2699 66.6613 28.8239 66.3966C28.3779 66.1318 27.8017 66.2787 27.5369 66.7247Z"
    fill="#D6DBE8"
/>
<path
    d="M31.4726 103.656C31.2078 104.102 31.3548 104.678 31.8008 104.943C32.2468 105.208 32.823 105.061 33.0877 104.615C33.3525 104.169 33.2056 103.592 32.7596 103.328C32.3136 103.063 31.7374 103.21 31.4726 103.656Z"
    fill="#D6DBE8"
/>
<path
    d="M41.7172 140.568C41.4524 141.014 41.5994 141.59 42.0454 141.855C42.4914 142.12 43.0676 141.973 43.3323 141.527C43.5971 141.081 43.4502 140.505 43.0042 140.24C42.5582 139.975 41.982 140.122 41.7172 140.568Z"
    fill="#D6DBE8"
/>
<path
    d="M66.4916 159.43C66.2268 159.876 66.3737 160.452 66.8197 160.717C67.2657 160.982 67.8419 160.835 68.1067 160.389C68.3715 159.943 68.2246 159.367 67.7786 159.102C67.3326 158.837 66.7563 158.984 66.4916 159.43Z"
    fill="#D6DBE8"
/>
<path
    d="M111.813 159.733C111.548 160.179 111.695 160.755 112.141 161.02C112.587 161.285 113.163 161.138 113.428 160.692C113.693 160.246 113.546 159.67 113.1 159.405C112.654 159.14 112.077 159.287 111.813 159.733Z"
    fill="#D6DBE8"
/>
<path
    d="M143.346 131.598C143.081 132.044 143.228 132.62 143.674 132.885C144.12 133.15 144.696 133.003 144.961 132.557C145.226 132.111 145.079 131.534 144.633 131.27C144.187 131.005 143.611 131.152 143.346 131.598Z"
    fill="#D6DBE8"
/>
<path
    d="M160.152 101.083C159.887 101.529 160.034 102.105 160.48 102.37C160.926 102.634 161.503 102.487 161.767 102.041C162.032 101.595 161.885 101.019 161.439 100.754C160.993 100.49 160.417 100.637 160.152 101.083Z"
    fill="#D6DBE8"
/>
<path
    d="M141.175 54.2276C140.91 54.6736 141.057 55.2498 141.503 55.5146C141.949 55.7794 142.525 55.6325 142.79 55.1865C143.055 54.7405 142.908 54.1643 142.462 53.8995C142.016 53.6347 141.44 53.7816 141.175 54.2276Z"
    fill="#D6DBE8"
/>
<path
    d="M191.146 81.6304L172.474 56.8321L134.458 85.7617L153.129 110.56L191.146 81.6304Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M163.011 103.459L143.834 78.1255"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M172.388 96.3608L153.212 71.0269"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M181.766 89.2627L162.59 63.9287"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M178.37 65.2881L140.858 93.6821"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M184.773 73.7466L147.261 102.141"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M137.315 106.035L145.512 99.8008L142.596 95.93L134.398 102.164L137.315 106.035Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M146.578 138.264C143.602 140.517 139.371 139.927 137.113 136.944L133.371 132C133.185 131.755 133.235 131.396 133.479 131.211L143.387 123.712C143.632 123.526 143.99 123.576 144.176 123.822L147.918 128.766C150.135 131.779 149.514 136.042 146.578 138.264Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2.0875"
    stroke-miterlimit="10"
/>
<path
    d="M80.2251 165.741L61.5537 140.943L99.5701 112.013L118.241 136.812L80.2251 165.741Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M108.864 144.446L89.6875 119.112"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M99.4855 151.544L80.3091 126.21"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M90.1084 158.643L70.932 133.309"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M67.9562 148.866L105.468 120.472"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M74.3589 157.324L111.87 128.93"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M115.367 116.577L107.169 122.811L110.085 126.682L118.283 120.447L115.367 116.577Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M123.148 87.4842L104.025 101.959L130.315 136.691L130.346 136.732C130.346 136.732 130.346 136.732 130.377 136.773C131.47 138.131 136.586 135.994 141.846 132.012C147.106 128.031 150.511 123.718 149.541 122.267C149.541 122.267 149.541 122.267 149.51 122.226L149.479 122.185L123.189 87.4534L123.148 87.4842Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M132.582 99.947L139.881 109.59L139.912 109.631L139.943 109.672C140.954 111.092 137.508 115.436 132.248 119.417C126.988 123.399 121.913 125.505 120.78 124.178L120.749 124.137L120.718 124.096L113.418 114.453C114.511 115.811 119.627 113.674 124.887 109.692C130.146 105.711 133.562 101.326 132.582 99.947Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
/>
<path
    d="M123.843 71.4246C128.803 78.0084 124.922 89.37 115.16 96.7944C105.397 104.219 93.4187 104.918 88.4585 98.3342C88.4284 98.2943 88.3983 98.2544 88.3683 98.2145C88.3382 98.1746 88.2781 98.0948 88.248 98.0549C86.6614 95.4329 93.0449 87.5656 102.643 80.2665C112.241 72.9674 121.54 68.9705 123.591 71.1767C123.621 71.2166 123.681 71.2964 123.711 71.3363C123.752 71.3049 123.782 71.3448 123.843 71.4246Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
/>
<path
    d="M123.539 71.2013L123.569 71.241C124.127 72.0661 123.823 73.5457 122.784 75.3341C120.695 78.9816 115.733 84.1248 109.291 89.018C102.849 93.9111 96.528 97.3388 92.5036 98.3351C90.5063 98.8531 89.0048 98.7448 88.3648 97.982L88.3349 97.9423C88.305 97.9026 88.275 97.8629 88.2451 97.8232C86.6647 95.2146 93.0231 87.3874 102.583 80.1256C112.143 72.8638 121.406 68.8873 123.449 71.0822C123.479 71.1219 123.509 71.1616 123.539 71.2013Z"
    fill="#F1F3F9"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
/>
<path
    d="M122.771 75.3534L122.801 75.3931C120.714 79.0347 115.758 84.1695 109.323 89.0547C102.889 93.9399 96.5752 97.362 92.5554 98.3567L92.5255 98.317C92.4956 98.2774 92.4657 98.2378 92.4657 98.2378C91.1153 96.0212 96.5804 89.2536 104.777 83.0304C113.015 76.7761 120.951 73.3688 122.711 75.2742C122.711 75.2742 122.741 75.3138 122.771 75.3534Z"
    fill="#D6DBE8"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
/>
<path
    d="M107.931 83.3047C108.072 83.5798 107.388 84.4189 106.383 85.1826C105.336 85.9781 104.346 86.4119 104.132 86.1281L97.2891 75.3905L99.2568 73.8949L107.87 83.2237L107.931 83.3047Z"
    fill="#F1F3F9"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
/>
<path
    d="M99.0945 75.5447C100.377 74.569 100.657 72.7708 99.7217 71.5281C98.7861 70.2854 96.9884 70.0689 95.7063 71.0445C94.4243 72.0201 94.1435 73.8184 95.0792 75.0611C96.0148 76.3038 97.8125 76.5203 99.0945 75.5447Z"
    fill="#F1F3F9"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linejoin="round"
/>
<path
    d="M43.1041 43.6741C42.7843 45.8064 41.3984 49.9642 38.9463 50.6039C38.8397 50.6039 38.7331 50.6039 38.6265 50.7105C36.281 51.0303 33.6158 49.4311 31.91 48.2584C31.0571 47.6188 30.2042 46.8725 29.4579 46.1262C29.1381 45.8064 28.7117 45.3799 28.3918 45.0601C25.9398 42.3948 24.0208 39.0899 22.8481 35.5717C22.6348 34.8254 22.4216 34.0792 22.5282 33.3329C22.5282 33.1197 22.6348 33.0131 22.6348 32.9064C22.8481 32.3734 23.2745 31.8403 23.9142 31.7337C24.4472 31.6271 24.8737 31.8403 25.4067 31.9469C25.9398 32.1602 26.3662 32.3734 26.8993 32.6932C25.7266 31.3073 25.4067 28.9618 25.4067 27.2561C25.4067 27.1495 25.4067 26.9362 25.5133 26.9362C25.62 26.8296 25.9398 26.9362 26.0464 27.0428C27.7522 28.3222 27.7522 30.6676 28.4984 32.5866C28.4984 31.2007 28.6051 29.8147 28.6051 28.4288C28.6051 27.4693 28.7117 26.2966 29.4579 25.6569C29.5646 25.5503 29.6712 25.5503 29.7778 25.5503C29.991 25.5503 30.0976 25.7635 30.2042 25.8701C31.4836 28.109 30.7373 30.8808 30.9505 33.4395C31.0571 34.399 31.1637 35.3585 31.3769 36.318C31.4836 36.7444 31.6968 37.7039 31.91 38.6634C32.1232 39.0899 32.3364 39.5163 32.6563 39.9428C32.7629 40.156 32.9761 40.3692 33.1893 40.4758C33.5092 40.7957 33.9356 41.1155 34.362 41.4353C35.2149 41.8618 36.0678 42.1816 37.0273 42.3948C38.7331 42.7146 40.4389 42.7147 42.1446 42.8213C42.4645 42.8213 42.7843 42.9279 42.9975 43.1411C43.1041 43.3543 43.1041 43.4609 43.1041 43.6741Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M65.4921 78.9619C63.2533 78.9619 60.9079 78.2157 59.2021 77.1496C55.8971 75.0173 53.8715 71.819 51.9525 68.9405L55.6839 66.4885C57.3897 69.0471 59.0955 71.819 61.5475 73.3116C63.4665 74.5909 66.5582 75.0173 68.264 73.5248C70.3962 71.6058 69.8632 67.981 69.0103 65.3157C67.9442 62.0108 65.9186 59.1323 63.4665 57.3199L66.1318 53.8018C69.4367 56.2538 71.9954 59.8786 73.2747 64.0364C75.0871 69.6868 74.4475 74.1644 71.2491 76.9363C69.65 78.3223 67.5177 78.9619 65.4921 78.9619Z"
    fill="#AAB1C5"
/>
<path
    d="M70.0768 43.5676C70.1834 45.1668 69.6504 46.766 69.0107 48.3651C66.5587 54.7618 64 61.1584 61.0149 67.3419C60.3752 68.5146 59.8422 69.7939 59.2025 70.9666C58.3496 72.6724 57.2835 74.5914 55.3645 75.0178C53.7653 75.3377 52.2728 74.5914 50.8868 73.8451C50.4604 73.6319 50.034 73.3121 49.8207 72.8856C49.6075 72.4592 49.7141 71.9261 49.8207 71.4997C51.1001 65.103 53.5521 59.1328 56.0042 53.056C57.1769 50.0709 58.4562 46.9792 59.4157 43.8875C60.162 41.7552 60.695 38.9834 63.4669 38.7701C66.0256 38.6635 69.8636 40.6891 70.0768 43.5676Z"
    fill="#AAB1C5"
/>
<path
    d="M40.3321 62.6506C40.3321 62.8638 40.3321 62.9704 40.3321 63.1837C40.2255 64.5696 39.9056 65.8489 39.1594 67.0217C38.3065 68.301 37.0271 69.3671 35.5346 69.6869C35.2148 69.7935 34.8949 69.7935 34.5751 69.7935C33.1891 69.9002 31.8032 69.4737 30.5239 68.9407C28.9247 68.301 27.3255 67.5547 25.5132 67.4481C24.5537 67.3415 23.5942 67.4481 22.6347 67.6613C22.1016 67.7679 21.6752 67.9812 21.1421 68.0878C20.6091 68.301 20.076 68.5142 19.5429 68.7274C17.7306 69.5803 15.9182 70.753 14.1058 71.4993C12.6132 72.139 10.8008 72.4588 9.52152 71.4993C8.88185 71.0729 8.56202 70.3266 8.24219 69.6869C6.4298 66.2754 4.83064 62.8638 3.23147 59.3457C3.12486 59.2391 3.01825 59.0258 3.01825 58.8126C3.01825 58.2796 3.65792 57.8531 4.08436 57.6399C4.19097 57.5333 4.29758 57.5333 4.40419 57.4267C5.57691 56.8936 6.64302 56.4672 7.92235 56.254C9.30829 56.1473 10.8008 56.3606 11.7603 57.3201C12.4 57.9597 12.8265 58.706 13.2529 59.5589C13.6793 60.5184 14.2124 61.4779 14.6388 62.4374C15.9182 61.3713 17.1975 60.3052 18.5834 59.3457C19.0099 59.0258 19.4363 58.8126 19.8628 58.5994C20.6091 58.173 21.4619 57.7465 22.3148 57.5333C23.7008 57.0002 25.1933 56.787 26.6859 56.6804C27.4322 56.5738 28.1784 56.4672 28.9247 56.5738C29.7776 56.5738 30.6305 56.787 31.3768 56.8936C33.4024 57.3201 35.5346 57.7465 37.5602 58.1729L35.3214 57.9597C37.1337 58.3862 39.266 59.0258 40.0122 60.7316C40.3321 61.2647 40.3321 62.0109 40.3321 62.6506Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M32.443 57.5335C31.6967 60.412 32.3364 63.5037 34.1488 65.9558C34.2554 66.0624 34.362 66.2756 34.5752 66.169C34.6818 66.169 34.6818 66.0624 34.7884 65.9558C35.1082 65.2095 35.2149 64.3566 35.3215 63.6103C35.5347 61.7979 36.1744 58.9194 35.5347 57.107C34.7884 55.4013 32.7628 56.1476 32.443 57.5335Z"
    fill="#AAB1C5"
/>
<path
    d="M45.6624 37.0641C44.8096 38.2369 43.7434 39.1964 42.7839 40.2625C37.1336 46.3393 34.4683 54.7616 32.8691 62.9706C32.4427 65.1028 32.2295 67.4483 33.5088 69.0474C34.1485 69.7937 35.0014 70.3267 35.8542 70.7532C37.2402 71.4995 38.5195 72.2457 39.9055 72.992C41.5046 73.8449 43.2104 74.8044 45.0228 74.911C47.155 75.0176 49.2872 73.9515 50.8864 72.459C52.4855 70.9664 53.5517 69.0474 54.6178 67.1284C57.283 62.2243 59.7351 57.2136 61.8673 52.0963C62.507 50.6037 63.1466 49.0046 63.3599 47.4054C63.6797 45.06 63.1466 42.7145 62.7202 40.4757C62.6136 39.9426 62.507 39.303 62.0805 38.8765C61.5475 38.3435 60.6946 38.3435 60.0549 38.4501C55.364 38.6633 50.5665 39.0897 46.0889 38.0236C45.1294 37.8104 44.0633 37.4906 43.5302 36.6377"
    fill="white"
/>
<path
    d="M45.6624 37.0641C44.8096 38.2369 43.7434 39.1964 42.7839 40.2625C37.1336 46.3393 34.4683 54.7616 32.8691 62.9706C32.4427 65.1028 32.2295 67.4483 33.5088 69.0474C34.1485 69.7937 35.0014 70.3267 35.8542 70.7532C37.2402 71.4995 38.5195 72.2457 39.9055 72.992C41.5046 73.8449 43.2104 74.8044 45.0228 74.911C47.155 75.0176 49.2872 73.9515 50.8864 72.459C52.4855 70.9664 53.5517 69.0474 54.6178 67.1284C57.283 62.2243 59.7351 57.2136 61.8673 52.0963C62.507 50.6037 63.1466 49.0046 63.3599 47.4054C63.6797 45.06 63.1466 42.7145 62.7202 40.4757C62.6136 39.9426 62.507 39.303 62.0805 38.8765C61.5475 38.3435 60.6946 38.3435 60.0549 38.4501C55.364 38.6633 50.5665 39.0897 46.0889 38.0236C45.1294 37.8104 44.0633 37.4906 43.5302 36.6377"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M22.6346 67.768C22.1015 67.8746 21.6751 68.0878 21.142 68.1944C21.0354 66.7019 20.8222 64.9961 20.3957 63.3969C19.9693 62.011 19.4362 60.7317 18.5833 59.4523C19.0098 59.1325 19.4362 58.9193 19.8627 58.7061C20.7156 60.092 21.3552 61.4779 21.7817 62.9705C22.2081 64.5697 22.5279 66.2754 22.6346 67.768Z"
    fill="#AAB1C5"
/>
<path
    d="M37.1335 65.6358C36.707 66.7019 36.9202 65.3159 36.8136 66.5953C36.8136 67.768 37.1335 68.9407 37.7731 70.0068C39.0525 71.8192 41.2913 72.8853 43.4235 73.6316C45.3425 74.2713 47.3681 74.5911 49.2871 73.6316C51.6325 72.4589 53.4449 69.687 53.2317 67.0217C53.0185 63.5036 48.8607 61.6912 45.7689 61.3713C41.931 60.9449 40.012 62.3308 37.0269 64.5697V65.6358H37.1335Z"
    fill="#AAB1C5"
/>
<path
    d="M66.8778 31.8402C66.8778 31.9468 66.7712 32.16 66.7712 32.2667C66.2382 34.6121 65.5985 36.8509 65.3853 39.303C65.2787 40.3691 65.4919 42.2881 64.4258 43.0344C63.5729 43.5674 62.0803 43.2476 61.1208 43.2476C58.1357 43.0344 55.2572 42.5013 52.1655 42.5013C51.9523 42.5013 51.7391 42.5013 51.4192 42.5013C50.3531 42.3947 49.3936 41.9682 48.5407 41.4352C47.368 40.9021 46.3019 40.2625 45.1292 39.836C44.1697 39.5162 43.1036 39.303 42.5705 38.4501C41.9309 37.5972 41.9309 36.5311 41.8243 35.5716C41.5044 32.3733 41.611 28.9617 42.4639 25.87C42.997 23.951 44.0631 22.032 45.6622 20.6461C49.1804 17.4477 54.8308 16.8081 59.2018 18.9403C62.4002 20.5395 65.172 23.3113 66.4514 26.7229C66.9844 28.2154 67.1977 30.0278 66.8778 31.8402Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M66.878 31.84C66.878 31.9466 66.7713 32.1598 66.7713 32.2664C66.2383 34.6119 65.5986 36.8507 65.3854 39.3027C65.2788 40.3689 65.492 42.2878 64.4259 43.0341C63.573 43.5672 62.0805 43.2473 61.121 43.2473C58.1359 43.0341 55.2574 42.5011 52.1656 42.5011C51.9524 42.5011 51.7392 42.5011 51.4194 42.5011C52.6987 41.968 54.0846 41.968 55.4706 41.7548C59.8416 41.0085 63.6796 37.9168 65.3854 33.8656C65.9185 32.5863 66.2383 31.2003 66.3449 29.8144C66.4515 28.7483 66.4515 27.6822 66.2383 26.7227C66.9846 28.2152 67.1978 30.0276 66.878 31.84Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M57.2828 40.6891C56.1101 41.7552 54.7242 42.5015 53.2316 42.5015C50.673 42.5015 48.0077 41.9685 45.6622 40.7957C44.916 40.3693 44.2763 39.9429 43.6366 39.5164C43.3168 39.1966 42.8904 38.8768 42.6771 38.5569C41.9309 37.704 41.5044 36.7445 41.2912 35.5718C40.6515 33.1198 41.078 30.1347 41.9309 27.7892C42.8904 25.3372 44.3829 24.3777 46.9416 24.2711C49.5002 24.0578 52.4853 24.9107 54.9374 26.0834C55.044 26.19 55.1506 26.19 55.2572 26.2967C56.3233 26.8297 57.2828 27.4694 58.0291 28.2157C61.6539 31.5206 60.6944 37.4908 57.2828 40.6891Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M51.6326 68.8342C51.6326 68.9408 51.6326 68.9408 51.6326 69.0474C51.6326 69.5805 51.526 70.0069 51.3128 70.4334C50.5665 72.459 48.4343 73.7383 46.3021 74.378C44.1699 75.0176 41.9311 75.1243 39.9054 75.7639C38.1997 76.297 36.4939 77.0433 35.2146 78.216C34.8947 78.5358 34.5749 78.749 34.3617 79.0689C34.3617 79.1755 34.2551 79.1755 34.2551 79.2821C31.9096 82.1606 31.27 86.3184 28.4981 88.8771C28.2849 89.0903 28.0716 89.1969 27.8584 89.3035C27.6452 89.3035 27.432 89.1969 27.2187 89.1969C23.2741 87.4911 19.6494 85.3589 16.0246 83.1201C15.4915 82.8002 14.8519 82.3738 14.532 81.8408C14.3188 81.4143 14.3188 80.9879 14.532 80.5614C15.0651 78.9623 17.4105 77.0433 18.4767 76.6168C19.6494 76.1904 21.0353 76.297 22.208 76.7234C23.3808 77.1499 24.5535 77.8961 25.513 78.6424C25.7262 77.1499 26.1526 75.5507 26.7923 74.1648C27.0055 73.7383 27.2187 73.3119 27.432 72.8854C27.6452 72.459 27.965 72.1392 28.1782 71.7127C28.8179 70.7532 29.6708 69.9003 30.5237 69.154C31.6964 68.0879 32.9757 67.1284 34.2551 66.2756C36.1741 64.9962 38.1997 63.8235 40.3319 63.2904C40.6517 63.1838 40.9716 63.1838 41.2914 63.0772C43.4236 62.6508 45.769 62.9706 47.7947 63.8235C48.9674 64.2499 50.0335 64.9962 50.7798 65.9557C51.3128 66.702 51.6326 67.7681 51.6326 68.8342Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M35.108 78.2158C34.7882 78.5356 34.4683 78.7489 34.2551 79.0687C34.2551 79.1753 34.1485 79.1753 34.1485 79.2819C32.8692 77.6827 31.3766 76.4034 29.6709 75.4439C28.9246 75.0175 28.0717 74.6976 27.2188 74.3778C27.1122 74.2712 26.899 74.2712 26.7924 74.1646C27.0056 73.7381 27.2188 73.3117 27.432 72.8853C27.5386 72.8853 27.6452 72.9919 27.7519 72.9919C28.6047 73.3117 29.5642 73.6315 30.4171 74.1646C32.1229 75.1241 33.8287 76.51 35.108 78.2158Z"
    fill="#AAB1C5"
/>
<path
    d="M83.7224 50.3905C83.7224 50.4971 83.7224 50.7104 83.6158 50.817C83.2959 51.9897 82.1232 52.6294 80.9505 52.8426C80.6306 52.9492 80.2042 52.9492 79.8844 52.9492C79.0315 53.0558 78.2852 53.0558 77.4323 53.269C76.5794 53.4822 75.7265 54.0153 74.8737 54.4417C74.0208 54.8682 73.0613 55.188 72.2084 55.4012C71.6753 55.5079 71.1423 55.6145 70.6092 55.7211C68.2638 56.0409 65.9183 55.8277 63.6795 55.0814C62.2936 54.655 61.2275 53.4822 60.5878 51.9897C59.6283 49.6443 59.8415 46.6591 62.0803 45.2732C64.9588 43.4608 66.7712 46.0195 69.1167 47.0856C69.5431 47.2988 69.9696 47.4054 70.396 47.512C70.9291 47.6186 71.4621 47.6186 71.9952 47.6186C73.1679 47.512 74.3406 47.1922 75.4067 46.6591C77.3257 45.6996 79.0315 44.2071 80.7373 42.8212C81.0571 42.6079 81.3769 42.2881 81.8034 42.3947C82.2298 42.5013 82.443 43.0344 82.443 43.5674V43.674C82.3364 44.1005 82.1232 44.5269 82.0166 44.9534C81.8034 46.8724 83.9356 48.5781 83.7224 50.3905Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M83.6166 50.8171C83.2968 51.9898 82.124 52.6295 80.9513 52.8427C80.6315 52.9493 80.205 52.9493 79.8852 52.9493C79.8852 52.8427 79.7786 52.7361 79.7786 52.6295C79.5654 52.203 79.672 51.67 80.0984 51.3501C79.7786 51.2435 79.4588 51.1369 79.3522 50.8171C79.1389 50.284 79.3522 49.6444 79.8852 49.4311L80.205 49.3245C80.0984 49.3245 79.9918 49.3245 79.9918 49.3245C79.5654 49.3245 79.2456 49.1113 79.0323 48.6849C78.8191 48.1518 79.0323 47.6188 79.4588 47.2989C79.2455 47.1923 78.9257 46.9791 78.8191 46.7659C78.6059 46.2328 78.8191 45.5931 79.3522 45.3799L82.7637 43.7808C82.6571 44.2072 82.4439 44.6336 82.3373 45.0601C81.8042 46.9791 84.2563 48.7915 83.6166 50.8171Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    d="M85.3214 49.751C85.2148 49.4312 84.895 49.2179 84.5751 49.2179L84.895 49.1113C85.428 48.8981 85.6412 48.2584 85.428 47.7254C85.2148 47.2989 84.6817 47.0857 84.2553 47.1923L84.4685 47.0857C85.0016 46.8725 85.2148 46.2328 85.0016 45.6998C84.895 45.3799 84.6817 45.2733 84.3619 45.1667C84.7884 44.8469 85.0016 44.3138 84.7883 43.7808C84.5751 43.2477 83.9355 43.0345 83.4024 43.2477L79.7776 44.9535C79.2446 45.1667 79.0314 45.8064 79.2446 46.3394C79.3512 46.6593 79.5644 46.7659 79.8842 46.8725C79.4578 47.1923 79.2446 47.7254 79.4578 48.2584C79.671 48.6849 80.2041 48.8981 80.6305 48.7915L80.3107 48.8981C79.7776 49.1113 79.5644 49.751 79.7776 50.284C79.8842 50.6039 80.2041 50.8171 80.5239 50.8171C80.0975 51.1369 79.9909 51.67 80.2041 52.0964C80.4173 52.6295 81.057 52.8427 81.59 52.6295L84.7883 51.1369C85.3214 50.9237 85.5346 50.3907 85.3214 49.751Z"
    fill="white"
    stroke="#AAB1C5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
/>
<path
    opacity="0.4"
    d="M53.3386 30.3477C52.9122 30.9874 52.0593 31.2006 51.4197 30.8808C51.2064 30.7742 50.8866 30.6676 50.6734 30.5609C47.6883 29.3882 44.4899 30.1345 43.637 32.3733C43.4238 32.9064 42.6776 32.7998 42.6776 32.2667C42.5709 31.5204 42.6776 30.6676 42.9974 29.9213C44.0635 27.0428 47.5817 25.7635 50.8866 27.0428C51.7395 27.3626 52.4858 27.8957 53.1254 28.4287C53.6585 28.9618 53.6585 29.7081 53.3386 30.3477Z"
    fill="#AAB1C5"
/>
<path
    opacity="0.4"
    d="M43.1041 46.6588C45.2364 47.725 49.6074 49.1109 52.2727 49.3241C52.8057 49.4307 53.019 49.8572 52.9124 50.3902C51.1 54.6547 49.6074 58.9191 49.0744 60.5183C48.9677 60.9447 48.5413 61.1579 48.2215 61.0513C46.3025 60.5183 40.9719 59.0257 38.8397 58.1728C38.4133 58.0662 38.3067 57.6398 38.4133 57.2133C40.2257 52.7357 41.505 48.7911 42.038 47.0853C42.2513 46.6588 42.7843 46.4456 43.1041 46.6588Z"
    fill="#AAB1C5"
/>
<path
    d="M72.3157 55.2943C71.7827 55.4009 71.2496 55.5075 70.7166 55.6142C69.8637 54.3348 69.3306 52.629 69.4372 50.9233C69.5438 49.9638 69.8637 48.5778 70.5033 47.5117C71.0364 47.6183 71.5694 47.6183 72.1025 47.6183C71.4628 48.5778 70.9298 49.9638 70.8232 51.0299C70.7166 52.629 71.3562 54.3348 72.3157 55.2943Z"
    fill="#AAB1C5"
/>
<path
    d="M34.4683 41.4354C32.5493 42.7147 30.8435 44.3139 29.3509 46.1263C29.0311 45.8065 28.6047 45.38 28.2848 45.0602C29.6708 43.3544 31.3765 41.8619 33.1889 40.5825L34.4683 41.4354Z"
    fill="#AAB1C5"
/>
</svg>`;
