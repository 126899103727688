import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { AccessTokenState } from 'states';

export const useExistingSession = () => {
	// globle states
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	// hooks
	const {
		handleNext,
		sessionDetails,
		sessionPayloadDetail,
		setSessionDetails,
	} = useNextStep();
	const { errorNotification } = useNotification();
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	const { currentAction, stepsId: step } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const getId = useCallback(
		(step: string) => {
			const { existingSession, existingSessionNodeIds } = sessionDetails ?? {};
			return (
				existingSession?.[step as 'kyc'] ??
				existingSessionNodeIds?.[step as 'kyc'] ??
				''
			);
		},
		[sessionDetails]
	);
	const submitExistingSession = useCallback(async () => {
		const payload = {
			...(onboardingType === 'complex'
				? {
						nodeId: currentAction?._id,
						existingSessionNode: {
							id: getId(step),
							step,
						},
					}
				: {
						stepId: step,
						existingSession: {
							id: getId(step),
							step,
						},
					}),
		};
		const resp = await postTokenSession({
			payload,
			code: sessionCode,
		});
		const { success, statusCode } = resp ?? {};

		if (success || statusCode === 200) {
			if (onboardingType === 'complex') {
				const response = { ...resp };
				delete response.statusCode;
				setSessionDetails(prev => ({
					...prev,
					nodes: response,
					steps: [],
				}));
			} else handleNext();
		} else {
			errorNotification('Something went wrong');
		}
		return resp;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionCode, onboardingType, currentAction?._id, getId, step]);

	return { submitExistingSession, getExistingId: getId };
};
