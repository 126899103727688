import { Loader } from '@storybook';
import { useState } from 'react';
import { BodyWrapper } from 'components/body-wrapper';
import { Questionnire } from './components/questionnire-form';
import './form-builder.scss';

export const FormAnswering = () => {
	// local state
	const [isLoading, setLoading] = useState(false);

	return isLoading ? (
		<div className="terms_header_container">
			<BodyWrapper
				optionalClassName="terms-body-wrapper"
				bodyElement={
					<div className="terms_header_container--loader">
						<Loader />
					</div>
				}
			/>
		</div>
	) : (
		<Questionnire setLoading={setLoading} isLoading={isLoading} />
	);
};
