import { ReactSwitch } from '@storybook';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { KycAmlVerificationToggleActionState } from 'views/kyb/stores';
import './kyc-aml-verification-toggle.scss';

type OnChangeHandlerType = () => void;

export const KycAmlVerificationDetail = () => {
	// global states
	const [isKycAmlToggle, setIsKycAmlToggle] = useRecoilState(
		KycAmlVerificationToggleActionState
	);

	const handleDoKycAmlToogleChange = useCallback(() => {
		setIsKycAmlToggle(toggles =>
			toggles.map(toggle => ({
				...toggle,
				isFreshKycLink: !toggle.isFreshKycLink,
			}))
		);
	}, [setIsKycAmlToggle]);

	const renderExistingKycAmlToggle = useMemo(() => {
		return (
			<div className="kyc-aml-members__toggle-wrapper">
				<div className="kyc-aml-members__toggle-wrapper__title-container">
					<span className="kyc-aml-members__toggle-wrapper__title-container__title">
						Do KYC & AML even if it already exist
					</span>
					<span className="kyc-aml-members__toggle-wrapper__title-container__description">
						Enable this option to perform KYC & AML verification even if it
						already exist.
					</span>
				</div>
				<ReactSwitch
					checked={isKycAmlToggle?.[0]?.isFreshKycLink ?? false}
					handleChange={handleDoKycAmlToogleChange}
					id={'representative-existing-kyc-aml-toggle'}
				/>
			</div>
		);
	}, [handleDoKycAmlToogleChange, isKycAmlToggle]);

	const renderDoKycAmlToggle = useCallback(
		(onChangeHandler: OnChangeHandlerType, checked: any) => {
			return (
				<div className="kyc-aml-members__toggle-wrapper">
					<div className="kyc-aml-members__toggle-wrapper__title-container">
						<span className="kyc-aml-members__toggle-wrapper__title-container__title">
							KYC & AML verification
						</span>
						<span className="kyc-aml-members__toggle-wrapper__title-container__description">
							Enable this option to perform KYC & AML verification for this
							representative.
						</span>
					</div>
					<ReactSwitch
						checked={checked?.doKyc || false}
						handleChange={() => onChangeHandler()}
						id={'representative-do-kyc-aml-toggle'}
					/>
				</div>
			);
		},
		[]
	);

	return { renderExistingKycAmlToggle, renderDoKycAmlToggle };
};
