import { API_TYPE } from 'constants/api';
import { useNotification } from 'hooks';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { AccessTokenState, LoginPhoneNumberState } from 'states';
import { loginToasterMessages } from './constants';
import {
	AppleUserDetailsState,
	BasicInformationState,
	useBasicInformation,
} from 'views/basic-information/store';
import { useTokenSession } from 'hooks/token/token-session';

export const useOtpVerify = () => {
	const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
	const basicInfoPayload = useRecoilValue(BasicInformationState);
	const appleUserDetails = useRecoilValue(AppleUserDetailsState);
	const { code: qrID } = useRecoilValue(AccessTokenState);

	const { errorNotification } = useNotification();
	const { inviteUser } = useBasicInformation();
	const { phone, countryCode } = loginPhoneNumber ?? {};
	const { postTokenSession } = useTokenSession();

	const verify = useCallback(
		async (otp: string) => {
			const payload = {
				phone,
				countryCode,
				otp,
				deviceDetailId: appleUserDetails?._id ?? '',
			};
			// VERIFY_CODE post api
			const response = await postTokenSession({
				payload,
				type: API_TYPE.VERIFICATION,
				code: qrID,
			});

			const { success, message } = response ?? {};
			if (success) {
				if (basicInfoPayload) await inviteUser(basicInfoPayload);
			} else {
				errorNotification(message || loginToasterMessages.WENT_WRONG);
			}
			return success;
		},
		[
			phone,
			countryCode,
			appleUserDetails?._id,
			postTokenSession,
			qrID,
			basicInfoPayload,
			inviteUser,
			errorNotification,
		]
	);

	const getOtp = useCallback(async () => {
		const payload = {
			phone,
			countryCode,
		};
		// PHONE_CODE POST API
		const response = await postTokenSession({
			payload,
			type: API_TYPE.VERIFICATION,
			code: qrID,
		});

		const { message, statusCode } = response ?? {};
		if (statusCode !== 200) {
			errorNotification(message || loginToasterMessages.WENT_WRONG);
		}
		return response;
	}, [phone, countryCode, postTokenSession, qrID, errorNotification]);

	return { verify, getOtp };
};
