import { Button, Input, Loader, ReactDropdown } from '@storybook';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useNetwork, useNotification } from 'hooks';
import { AccessTokenState } from 'states';
import {
	CapturedScanImageState,
	CheckDefenseFormMetaData,
	CheckDetailsFormState,
	CheckFileSelectorTypeState,
} from 'views/check-defense/stores';
import { useKycRequests } from 'views/kyc/stores';

import { APIS } from 'constants/api';
import './check-details-form.scss';

// Functional component CheckDetailsForm
export const CheckDetailsForm = ({ handleStep }: any) => {
	const [isLoading, setIsLoading] = useState(false);
	// State management using Recoil
	const [checkDetailsForm, setCheckDetailsForm] = useRecoilState(
		CheckDetailsFormState
	);

	const resetFileSelectorType = useResetRecoilState(CheckFileSelectorTypeState);

	// State for the captured image, managed by Recoil
	const ressetCapturedImage = useResetRecoilState(CapturedScanImageState);

	const { submitCheckScan } = useKycRequests();
	const { errorNotification } = useNotification();

	// Loading state and form metadata

	const formMetaData = useRecoilValue(CheckDefenseFormMetaData);

	const { post, loading } = useNetwork();

	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	const capturedImage = useRecoilValue(CapturedScanImageState);

	const handleOnSubmitImage = () => {
		const [, base64] = capturedImage.split(',');
		const payload = {
			code: sessionCode,
			type: 'ocr-scan',
			payload: {
				operation: 'CHEQUE-SCAN',
				base64Image: base64,
			},
		};
		post(APIS.TOKEN, payload).then(resp => {
			if (resp.statusCode === 200) {
				const {
					accountNumber = '',
					routingNumber: routeNumber = '',
					checkNumber = '',
					path,
				} = resp;

				const info = {
					accountNumber,
					routeNumber,
					checkNumber,
					path,
				};
				setCheckDetailsForm({ ...checkDetailsForm, ...info });
			} else {
				errorNotification('Please upload a valid check');
				ressetCapturedImage();
				resetFileSelectorType();
				handleStep?.('empty-scan');
			}
		});
	};

	const submitFormData = useCallback(async () => {
		const {
			accountNumber,
			amount,
			routeNumber: routingNumber,
			checkNumber,
			path,
		} = checkDetailsForm;
		const payload = {
			checkDetails: {
				accountNumber,
				amount,
				routingNumber,
				checkNumber,
				path,
			},
		};
		setIsLoading(true);
		await submitCheckScan(payload);
		setIsLoading(false);
	}, [checkDetailsForm, submitCheckScan]);
	// Simulating loading with a timeout
	useEffect(() => {
		handleOnSubmitImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// State for managing the visibility of additional bank details
	const [isAddBank, setIsAddBank] = useState(false);

	// Method to handle click events on settings button
	const handleClickSetting = (name: string) => {
		if (name === 'addbank') {
			setIsAddBank(true);
		}
	};

	// Method to handle click events on divider sub-label
	const handleDeviderSublabelClick = (name: string) => {
		if (name === 'addNewBank') {
			setIsAddBank(false);
		}
	};

	// Callback method for handling input changes
	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
			const { name, value } = event.target ?? {};
			if (regex) {
				if (!regex.test(value) && value !== '') {
					return;
				}
			}
			if (name === 'amount' && value.length > 30) {
				return;
			}
			setCheckDetailsForm({ ...checkDetailsForm, [name]: value });
		},
		[checkDetailsForm, setCheckDetailsForm]
	);

	// Cleanup effect to reset form data on component unmount
	useEffect(() => {
		return () => {
			setCheckDetailsForm({});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Method to generate options for dropdown based on a given name
	const getOptions = useCallback((name: string) => {
		if (name) {
			return [
				{
					label: '',
					value: '',
				},
			];
		}
		return [
			{
				label: '',
				value: '',
			},
		];
	}, []);

	// Callback method for handling dropdown selection
	const handleSelect = useCallback((event: any, name: string) => {
		if (name && event) {
			return;
		}
	}, []);

	const isDisabled = useCallback((name: string) => {
		if (name === 'amount') {
			return false;
		}
		return true;
	}, []);

	// Method to generate the appropriate form element based on metadata
	const getSettingForm = (el: any) => {
		const {
			type,
			name,
			placeholder,
			label,
			className,
			subLabel,
			regex,
			prefixIcon,
			pattern,
		} = el ?? {};
		switch (type) {
			case 'text':
			case 'number':
				return (
					<Input
						label={label}
						inputType={type}
						inputName={name}
						placeholder={placeholder}
						value={checkDetailsForm[name] ? checkDetailsForm[name] : ''}
						handleChange={event => handleOnChange(event, regex)}
						prefixIcon={prefixIcon}
						disabled={isDisabled(name)}
						pattern={pattern}
					/>
				);
			case 'or':
				return (
					<div className="center-option-wrapper">
						<div className="center-option-wrapper__inner">{label}</div>
					</div>
				);
			case 'select':
				return (
					<ReactDropdown
						options={getOptions(name)}
						handleChangeSelect={event => handleSelect(event, name)}
						optionsDropHeight={130}
						maxMenuHeight={130}
						isSearchable
						label={label}
						value={{
							label: '',
							value: '',
						}}
						defaultValue={{
							label: '',
							value: '',
						}}
					/>
				);
			case 'devider':
				return (
					<div className="label-divider-wrapper">
						<div className="label-divider">{label}</div>
						{subLabel && (
							<div
								className="divider_cancel"
								onClick={() => handleDeviderSublabelClick(name)}
							>
								{subLabel}
							</div>
						)}
					</div>
				);
			case 'button':
				return (
					<Button
						label={label}
						type={`button button__filled ${className} `}
						handleClick={() => handleClickSetting(name)}
					/>
				);
			default:
				return <></>;
		}
	};

	// Loading state indicator
	if (loading) {
		return (
			<div className="fetching-details-wrapper">
				<div className="fetching-details-wrapper__inner">
					<Loader type="loader" />
					<div className="fetching-details-wrapper__inner-title">
						Please wait
					</div>
					<div className="fetching-details-wrapper__inner-text">
						We are fetching details from check provided by you.
					</div>
				</div>
			</div>
		);
	}

	// Render the main form content
	return isLoading ? (
		<div className="kyc-loader-wrapper">
			<Loader dimension={40} className="loader-blue" />
		</div>
	) : (
		<div className="report-modal__content">
			<div className="report-modal__content__form-body">
				{formMetaData.map((el, i: number) => {
					if (!el) {
						return <></>;
					}
					if (!isAddBank && el.refrer === 'bank') {
						return;
					}
					if (isAddBank && el.name === 'addbank') {
						return;
					}
					return (
						<div key={`${el.name}_${i.toString()}`} style={{ width: el.width }}>
							{getSettingForm(el)}
						</div>
					);
				})}
			</div>
			<div className="report-modal__content__btn-wrapper">
				<Button
					label="Next"
					handleClick={submitFormData}
					type="button__filled button__filled--primary button__large button__block"
					disabled={!checkDetailsForm?.amount}
				/>
				<Button
					label="Back"
					handleClick={() => handleStep?.('uploaded-scan')}
					type="button__filled button__filled--secondary button__large button__block"
				/>
			</div>
		</div>
	);
};
