import {
	createRef,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Confirmation, Loader } from '@storybook';

import { useInterval } from 'hooks';
import { SignAgreementUrlState, useSignAgreementRequests } from '../stores';

import '../sign-agreement.scss';
import { detectIncognito } from 'utils';
import { incognitoMessage } from './constant';

export const SignAgreementDocusign = () => {
	const ref = createRef<HTMLIFrameElement>();

	const [documentloading, setDocumentLoading] = useState(true);
	const [confirmation, setConfirmation] = useState(false);

	const currentUrl = useRecoilValue(SignAgreementUrlState);
	const { Title, Description } = incognitoMessage;

	const { checkStatus } = useSignAgreementRequests();

	useInterval(() => {
		checkStatus();
	}, 2000);

	useEffect(() => {
		setTimeout(() => {
			setDocumentLoading(false);
		}, 2500);
	}, []);

	const detectIncognitoFunc = useCallback(async () => {
		const result = await detectIncognito();
		if (result.isPrivate) {
			setConfirmation(true);
			return;
		}
	}, []);

	useEffect(() => {
		detectIncognitoFunc();
	}, [detectIncognitoFunc]);

	const renderIframe = useMemo(() => {
		return (
			<Fragment>
				{documentloading && (
					<div className="sign-aggrement__container__loader">
						<Loader />
					</div>
				)}
				<div className="iframe-wrapper docusign-iframe-warpper">
					<iframe
						title="uniqueTitle"
						ref={ref}
						className={`iframe-wrapper__iframe-tag ${
							documentloading ? 'iframe-wrapper__none' : 'iframe-wrapper__block'
						}`}
						src={currentUrl?.url}
					/>
				</div>
			</Fragment>
		);
	}, [currentUrl, documentloading, ref]);

	return (
		<>
			{confirmation ? (
				<Confirmation
					visible={confirmation}
					title={Title}
					description={''}
					boldDescription={Description}
					handleModal={() => ''}
					secondryLabel=""
					label=""
				/>
			) : (
				<div className="sign-aggrement__container">{renderIframe}</div>
			)}
		</>
	);
};
