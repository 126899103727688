import { FC, ReactNode, useCallback } from 'react';
import { ReactDropdown } from '@storybook';
import { useRecoilState } from 'recoil';

import { FinraUploadState } from '../stores';

interface IOption {
	label: string;
	value: string;
}
interface ISelectComponent {
	label?: string;
	name: string;
	selectOptions: IOption[];
	defaultValue: IOption;
	isMulti: boolean;
	placeholder: string | ReactNode;
}

export const SelectComponent: FC<ISelectComponent> = ({
	label,
	name,
	selectOptions,
	defaultValue,
	placeholder,
	isMulti = false,
}) => {
	const [dropDownValue, setDropDownValue] = useRecoilState(FinraUploadState);

	const handleChange = useCallback(
		(event: any) => {
			setDropDownValue((prev: any) => {
				const prevState = { ...prev };
				prevState[name] = event;
				return { ...prevState };
			});
		},
		[name, setDropDownValue]
	);

	return (
		<div
			className="select-component"
			style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
		>
			{label && (
				<div
					className="select-label"
					style={{
						color: ' #747e99',
						fontSize: '12px',
						fontWeight: 500,
						lineHeight: '16px',
					}}
				>
					{label}
				</div>
			)}
			<ReactDropdown
				options={selectOptions}
				isMulti={isMulti}
				defaultValue={defaultValue}
				value={dropDownValue[name]}
				placeholder={placeholder}
				handleChangeSelect={handleChange}
			/>
		</div>
	);
};
