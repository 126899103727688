import { Image } from '@storybook';
import { PrerequisiteWebglMessage } from './constants';

import './prerequisite-webgl.scss';

export const PrerequisiteWebgl = () => {
	return (
		<div className="webgl-wrapper">
			<div className="webgl-wrapper__inner">
				<Image
					fileName="webgl-error.svg"
					className="webgl-wrapper__inner__error-image"
				/>
				<div>
					<div className="webgl-wrapper__inner__heading">
						{PrerequisiteWebglMessage.HEAD}
					</div>
					<div className="webgl-wrapper__inner__description">
						{PrerequisiteWebglMessage.DESC}
					</div>
				</div>
			</div>
		</div>
	);
};
