export const ComponentMetaData = {
	form: {
		label: 'Check Information',
		sublabel: 'Review Check Information',
		description: 'Please review check information to proceed further.',
	},
	'empty-scan': {
		label: 'Check Scan',
		sublabel: 'Please provide a clear photo of the front of your check',
		description: 'Please make sure the details are clear and unobstructed.',
	},
	'upload-scan': {
		label: 'Camera',
		sublabel: 'Take photo of the front of your check',
		description:
			'Use a well lit area and make sure the details are clear and unobstructed.',
	},
	'uploaded-scan': {
		label: 'Check Scan',
		sublabel: "Well done! here's the captured photo",
		description:
			'Please take a moment to review it. If it looks good, you can submit it. Otherwise, feel free to retake the picture.',
	},
};
