import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useNextStep } from 'hooks';
import { IAccount, selectedAccountState } from 'states';

import { useRecoilState } from 'recoil';
import './payment.scss';

interface IPaymentScreen {
	accounts: IAccount[];
}
export const Payment: FC<IPaymentScreen> = ({ accounts }) => {
	//local states
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedAccounts, setSelectedAccounts] =
		useRecoilState(selectedAccountState);

	//hooks
	const { sessionPayloadDetail } = useNextStep();

	const { investingAmount } = sessionPayloadDetail;

	const onSelect = useCallback(
		(index: number, account: IAccount) => {
			setSelectedIndex(index);
			setSelectedAccounts(account);
		},
		[setSelectedAccounts]
	);

	useEffect(() => {
		if (accounts && accounts.length > 0) setSelectedAccounts(accounts[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts]);

	const renderLists = useMemo(() => {
		if (accounts?.length > 0) {
			return accounts?.map((item: IAccount, index: number) => {
				return (
					<div
						key={`${selectedIndex}__${index.toString()}`}
						className={`account-list ${
							selectedIndex === index
								? selectedAccounts &&
									selectedAccounts.balance.available < Number(investingAmount)
									? 'account-list__selected__balance-unavailable'
									: 'account-list__selected'
								: ''
						}`}
						onClick={() => onSelect(index, item)}
					>
						<div className="fund-investment__card_inner">
							<div
								style={{
									display: 'flex',
									gap: 16,
									alignItems: 'center',
								}}
							>
								<input
									type="radio"
									name="radio-input"
									id={`${index}_${item.subtype}`}
									checked={selectedIndex === index}
								/>
								<div>
									<div
										className="fund-investment__sub_title fw-500"
										style={{ color: '#464C5C' }}
									>
										{item.subtype}
									</div>
									<div className="fund-investment__card_number">
										&#8226;&#8226;&#8226;&#8226; {item.mask}
									</div>
								</div>
							</div>
							<div>
								<div className="fun-investment__amount_wrapper">
									<div className="fund-investment__avail_balance">
										${Number(item.balance?.available ?? 0).toFixed(2)}
									</div>
								</div>
								<div className="fund-investment__sub_title fw-500">
									Total Balance
								</div>
							</div>
						</div>
					</div>
				);
			});
		} else {
			return (
				<div className="fund-investment__empty_bank_account">
					Please add a bank account
				</div>
			);
		}
	}, [accounts, investingAmount, onSelect, selectedAccounts, selectedIndex]);

	return <div className="fund-investment__card_wrapper">{renderLists}</div>;
};
