import { IExistingSessions } from './types';

import { atom } from 'recoil';

export const ExistingSessionsState = atom<IExistingSessions>({
	key: 'existing-session-state-key',
	default: {
		kyc: false,
		kyb: false,
	},
});
