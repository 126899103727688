import { FC } from 'react';
import { Image, SkeltonLoader } from '@storybook';

import './glare-detected-screen.scss';

interface GlareDetectedScreenProps {
	image: string;
	handleRetake: () => void;
	errorMessage?: string;
	glareErrors: string[];
	labelElement: any;
}

export const GlareDetectedScreen: FC<GlareDetectedScreenProps> = ({
	image,
	handleRetake,
	glareErrors,
	labelElement,
}) => {
	return (
		<div className="GlareDetectedScreen__container">
			{labelElement}
			<div className="GlareDetectedScreen__container__secondcontainer">
				<div className="GlareDetectedScreen__container__secondcontainer__detected">
					<div className="GlareDetectedScreen__container__secondcontainer__detected__heading">
						Glare detected
					</div>
					<div className="GlareDetectedScreen__container__secondcontainer__detected__info">
						It looks like there is some glare in the photo of your document.
						Please retake the picture, ensuring that there is no glare or
						reflection.
					</div>
				</div>
				{/* <div className="GlareDetectedScreen__container__secondcontainer__tips">
					<div className="GlareDetectedScreen__container__secondcontainer__tips__heading">
						Tips for a better photo:
					</div>
					<div className="GlareDetectedScreen__container__secondcontainer__tips__steps">
						<ul className="GlareDetectedScreen__container__secondcontainer__tips__steps__bullet">
							<li>Place the document in a well-lit area.</li>
							<li>Avoid direct light sources that may cause glare.</li>
							<li>Ensure the document is flat and not tilted.</li>
						</ul>
					</div>
				</div> */}
				{glareErrors.length > 0 && (
					<div className="GlareDetectedScreen__container__secondcontainer__tips">
						<div className="GlareDetectedScreen__container__secondcontainer__tips__heading">
							<i className="ri-error-warning-fill ri-xl GlareDetectedScreen__container__secondcontainer__tips__heading__error-icon"></i>
							Errors:
						</div>
						<div className="GlareDetectedScreen__container__secondcontainer__tips__steps">
							<ul className="GlareDetectedScreen__container__secondcontainer__tips__steps__bullet">
								{glareErrors.map(error => (
									<li key={error}>{error}</li>
								))}
							</ul>
						</div>
					</div>
				)}
				<div className="GlareDetectedScreen__container__secondcontainer__image">
					<div className="GlareDetectedScreen__container__secondcontainer__image__imgdiv">
						{image ? (
							<Image
								className="GlareDetectedScreen__container__secondcontainer__image__imgdiv__imagerotate"
								url={image}
							/>
						) : (
							<SkeltonLoader height="100%" />
						)}
					</div>
				</div>
				<div
					className="GlareDetectedScreen__container__secondcontainer__button"
					onClick={handleRetake}
				>
					Retake photo
				</div>
			</div>
		</div>
	);
};
