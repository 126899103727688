export const StepVerifiactionMessage = {
	// HEADING: 'Welcome to facial enrollment',
	TIPS: 'Tips for successful enrollment.',
	HEADING: 'Facial Enrollment',
	SUBHEADING:
		'To ensure secure access using facial recognition, we need to enroll your face.',
	STEPONE: 'Find a well-lit area for accurate image capture.',
	STEPTWO: 'Turn your head slowly to the right, and then to the left.',
	STEPTHREE:
		'Position your face within the oval boundary and keep your face straight for a frontal face image capture.',
};

export const StepMobileVerifiactionMessage = {
	TIPS: 'Successful Enrolment Tips',
	HEADING: 'Facial Enrollment',
	SUBHEADING:
		'To ensure secure access using facial recognition, we need to enroll your face.',
	STEPONE: 'Find a well-lit area',
	STEPTWO: 'Turn your head slowly right, then left.',
	STEPTHREE: 'Keep your face straight within the oval for a face capture.',
};

export const LeftRight_URL =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/LeftRight.mp4';
