import { IMAGE_GCP_URL } from './constant';

interface IImage {
	id?: string;
	fileName?: string;
	url?: string;
	className?: string;
	height?: string | number;
	width?: string;
	props?: any;
	style?: any;
	onLoad?: any;
	onError?: () => void;
}

export const Image = ({
	fileName,
	url: imageUrl,
	className,
	height,
	width,
	props,
	style,
	onLoad,
	id,
	onError,
}: IImage) => {
	const url = imageUrl ?? `${IMAGE_GCP_URL}/${fileName}`;

	return (
		<img
			id={id}
			src={url}
			alt={fileName}
			className={className ?? ''}
			height={height}
			width={width}
			onLoad={onLoad}
			style={style}
			{...props}
			{...(onError ? { onError } : {})}
		/>
	);
};
