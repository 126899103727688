export const ROUTES = {
	CONDITIONS: '/terms-conditions',
	KYC_FORM: '/kyc-requests',
	DEFAULT: '/:code',
	PLEASE_WAIT: '/processing',
	INVALID_ORIGIN: '/error/invalid_origin',
};

export const STEPS = {
	Facial_Recognition: 'facialRecognition',
	Authentication: 'authentication',
};
