import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Image, Input, Loader } from '@storybook';

import { useRecoilValue } from 'recoil';
import { useNextStep } from 'hooks';
import { convertToCurrencySystem, isNumber } from 'utils';
import { FundBankDetailsState, IsInvestingAmountEmptyState } from '../stores';
import {
	MAX_DECIMAL_DIGITS,
	MAX_DEFAULT_AMOUNT,
	MIN_DECIMAL_DIGITS,
	MIN_DEFAULT_AMOUNT,
	SESSION_TYPE,
} from '../constants';

interface IConnectBank {
	handleConnect: VoidFunction;
	title: string;
	headerTitle: string;
	headerSubtitle?: string;
	investmentDetails?: string | JSX.Element;
	investingAmount?: string | number;
	setShowConnectBank: (value: boolean) => void;
	loader?: boolean;
	handleBack?: () => void;
}
export const ConnectBank: FC<IConnectBank> = ({
	handleConnect,
	title,
	headerTitle,
	headerSubtitle,
	investmentDetails = '',
	investingAmount,
	setShowConnectBank,
	loader = false,
	handleBack,
}) => {
	// globle state
	const isInvestingAmountEmpty = useRecoilValue(IsInvestingAmountEmptyState);
	const fundBankDetails = useRecoilValue(FundBankDetailsState);

	// hook
	const { setSessionDetails, sessionPayloadDetail, payInUnitPricing } =
		useNextStep();

	const [investmentAmount, setinvestmentAmount] = useState(investingAmount);
	const [isError, setIsError] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const type = (sessionPayloadDetail as any).currentAction.metadata
		?.payInPayOut;

	const { sessionType = '' } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const isSeller = useMemo(
		() => sessionType === SESSION_TYPE.Seller,
		[sessionType]
	);
	const isBuyer = useMemo(
		() => sessionType === SESSION_TYPE.Buyer,
		[sessionType]
	);

	const decimalDigits = useMemo(
		() =>
			!isSeller && !isBuyer && !!payInUnitPricing
				? MAX_DECIMAL_DIGITS
				: MIN_DECIMAL_DIGITS,
		[isBuyer, isSeller, payInUnitPricing]
	);

	const renderHeader = useMemo(
		() => (
			<div className="fi-connect-bank__header">
				<div className="fi-connect-bank__header__title">
					{headerTitle ?? 'Connect your bank account to fund this account'}
				</div>
				<div className="fi-connect-bank__header__sub-title">
					{headerSubtitle}
				</div>
			</div>
		),
		[headerSubtitle, headerTitle]
	);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target ?? {};
			const valueWithoutCurrency = value?.replace(/[^0-9.]/g, '');
			const regexDecimals = /^$|^\d+(\.\d{0,2})?$/;

			if (
				!isNumber(valueWithoutCurrency) ||
				!regexDecimals.test(valueWithoutCurrency)
			)
				return;
			if (
				parseFloat(valueWithoutCurrency as string) <= MIN_DEFAULT_AMOUNT ||
				parseFloat(valueWithoutCurrency as string) > MAX_DEFAULT_AMOUNT
			) {
				setIsError(true);
			} else {
				setIsError(false);
			}

			setSessionDetails(pre => ({
				...pre,
				investingAmount: valueWithoutCurrency,
			}));
			setinvestmentAmount(valueWithoutCurrency);
		},

		[setSessionDetails]
	);

	const isAmountError = useMemo(
		() =>
			!isSeller &&
			(!parseFloat(investmentAmount as string) ||
				parseFloat(investmentAmount as string) <= MIN_DEFAULT_AMOUNT ||
				parseFloat(investmentAmount as string) > MAX_DEFAULT_AMOUNT),
		[investmentAmount, isSeller]
	);

	const renderInputFund = useMemo(
		() => (
			<>
				<div className="fi-connect-bank__input">
					<div className="fi-connect-bank__input__amount_paid_text">
						Enter Amount
					</div>
					<Input
						errorMessage="Investment Amount should be greater than $0.5 and less than $1M"
						isError={isError}
						handleChange={handleChange}
						label=" "
						inputType="text"
						placeholder="$0.00"
						value={investmentAmount ?? 0 > 0 ? `$${investmentAmount}` : ''}
						inputRef={inputRef}
						onKeyDown={(e: any) =>
							['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
						}
					/>
				</div>
			</>
		),
		[handleChange, investmentAmount, inputRef, isError]
	);

	const renderCard = useMemo(
		() => (
			<div className="fi-connect-bank__card">
				<Image
					fileName={'pattern_01.png'}
					className="fi-connect-bank__card__image"
				/>
				<div className="fi-connect-bank__card__doller_icon_wrapper">
					<i className="ri-money-dollar-circle-line"></i>
				</div>
				<div className="fi-connect-bank__card__investment_wrapper">
					{type === 'payIn' && (
						<div className="fi-connect-bank__card__amount_paid">
							{!isInvestingAmountEmpty
								? `$
						${convertToCurrencySystem(Number(investingAmount ?? 0), decimalDigits)}`
								: renderInputFund}
						</div>
					)}
					{!isInvestingAmountEmpty && (
						<div className="fi-connect-bank__card__amount_paid_text">
							{title}
						</div>
					)}
				</div>
			</div>
		),
		[
			investingAmount,
			isInvestingAmountEmpty,
			renderInputFund,
			title,
			type,
			decimalDigits,
		]
	);

	const isDisable = useMemo(() => {
		return (
			type === 'payIn' &&
			!isSeller &&
			isInvestingAmountEmpty &&
			(isAmountError || !investmentAmount)
		);
	}, [investmentAmount, isAmountError, isInvestingAmountEmpty, isSeller, type]);

	const handleClickOnConnect = useCallback(() => {
		if (fundBankDetails?.data?.accounts?.length) {
			setShowConnectBank(false);
		} else handleConnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fundBankDetails?.data?.accounts?.length]);

	const renderLabel = useMemo(
		() => (loader ? <Loader type="loader" dimension={20} /> : 'Connect'),
		[loader]
	);

	const renderBody = useMemo(() => {
		switch (type) {
			case 'payIn':
				if (!isSeller) {
					return (
						<>
							{investmentDetails}
							{renderCard}
						</>
					);
				}
				return <></>;
			case 'payOut':
				return (
					<>
						{investmentDetails}
						{renderCard}
					</>
				);
			default:
				return <></>;
		}
	}, [investmentDetails, isSeller, renderCard, type]);

	return (
		<div className="fi-connect-bank">
			{renderHeader}
			{renderBody}
			<div className="fund--button">
				{handleBack && (
					<Button
						label={'Back'}
						handleClick={handleBack}
						type="button__filled button__filled--secondary button__large button__block "
					/>
				)}
				<Button
					label={renderLabel}
					handleClick={handleClickOnConnect}
					type="button__filled button__filled--primary button__large button__block "
					disabled={isDisable || loader}
				/>
			</div>
		</div>
	);
};
