import { atom } from 'recoil';
import { CheckboxState, DocumentProofDetails } from './type';

export const ProofReadDocumentState = atom<DocumentProofDetails[]>({
	key: 'proof-read-document-state',
	default: [],
});

export const AggrementCheckboxState = atom<CheckboxState>({
	key: 'checkbox-state',
	default: {},
});
