import { FC } from 'react';
import './back-icon.scss';

interface IBackIcon {
	handleGoBack: () => void;
}
export const BackIcon: FC<IBackIcon> = ({ handleGoBack }) => {
	return (
		<div className="BackIcon" onClick={handleGoBack}>
			<i className="ri-arrow-left-line"></i>
		</div>
	);
};
