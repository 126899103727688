import { useNextStep } from 'hooks';
import { SignAgreementDocusign } from './components/docusign';
import { SignAgreementSimpliciSign } from './components/simplici-sign';

import './sign-agreement.scss';

export const SignAgreement = () => {
	const { agreementProvider } = useNextStep();

	return agreementProvider === 'esign' ? (
		<SignAgreementSimpliciSign />
	) : (
		<SignAgreementDocusign />
	);
};
