import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button, Input, Loader } from '@storybook';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BodyWrapper, LabelElement } from 'components';
import { SsnNumberState, useKycRequests } from 'views/kyc/stores';

import './provide-ssn.scss';
import { CountryStateListState } from 'views/kyb/stores';
import { KycSelectedCountry } from '../../store';

export const ProvideSSNNumber = () => {
	const [nationalIdNumber, setNationalIdNumber] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const setSsnNumber = useSetRecoilState(SsnNumberState);
	const selectedCountry = useRecoilValue(KycSelectedCountry);
	const [error, setError] = useState({
		national_id_number: '',
	});

	const isUsa = /USA/gi.test(selectedCountry.iso3);

	const setCountryListResp = useSetRecoilState(CountryStateListState);

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		let maxDigit = 15;
		if (selectedCountry?.iso3 === 'USA') {
			maxDigit = 9;
		} else if (selectedCountry?.iso3 === 'IND') {
			maxDigit = 10;
		}

		setError(prevState => ({
			...prevState,
			national_id_number: '',
		}));

		const number = e.target.value;
		const validSsnRegex = /^[0-9a-zA-Z/-]*( [0-9a-zA-Z/-]+)*$/;
		const expression =
			/^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
		const digits = number?.replace(/[^0-9a-zA-Z]/g, '');
		const ssnValue = number
			.replace(/[^0-9a-zA-Z/\- ]/g, '')
			.replace(/\s+/g, ' ');

		if (digits.length <= maxDigit + 1 && validSsnRegex.test(number.trim())) {
			if (selectedCountry?.iso3 === 'USA') {
				const isOnlyDigitsRegex = /^[a-zA-Z0-9]+$/;
				const valueToSet = isOnlyDigitsRegex.test(number.trim())
					? number.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3')
					: ssnValue;
				setNationalIdNumber(valueToSet);
			} else {
				setNationalIdNumber(ssnValue);
			}
			setSsnNumber(digits);
		}

		if (!validSsnRegex.test(number.trim())) {
			setError(prevState => ({
				...prevState,
				national_id_number: 'Invalid TaxID Number.',
			}));
			return;
		}

		if (digits.length > maxDigit && validSsnRegex.test(number.trim())) {
			setError(prevState => ({
				...prevState,
				national_id_number: `The maximum length for a TaxID Number should be ${maxDigit} digits.`,
			}));
			return;
		}
		if (
			digits.length < maxDigit &&
			validSsnRegex.test(number.trim()) &&
			/USA|IND/.test(selectedCountry?.iso3)
		) {
			const label = isUsa ? 'SSN' : 'TaxID';
			setError(prevState => ({
				...prevState,
				national_id_number: `The minimum length for a ${label} Number should be ${maxDigit} digits.`,
			}));
			return;
		}
		if (!expression.test(number.trim()) && selectedCountry?.iso3 === 'USA') {
			setError(prevState => ({
				...prevState,
				national_id_number: 'Invalid SSN Format',
			}));
			return;
		}
		if (
			digits.length >= maxDigit &&
			digits.length < maxDigit + 1 &&
			validSsnRegex.test(number.trim())
		) {
			setError(prevState => ({
				...prevState,
				national_id_number: '',
			}));
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { onsubmitSSN } = useKycRequests();

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		const resp = await onsubmitSSN();
		if (!resp?.message) {
			setCountryListResp([]);
		}
		setIsLoading(false);
	}, [onsubmitSSN, setCountryListResp]);

	const bodyContent = useMemo(() => {
		return (
			<div className="input-wrapper-inner">
				<Input
					handleChange={handleChange}
					value={nationalIdNumber}
					placeholder={isUsa ? 'Enter SSN Number' : 'Enter TaxID Number'}
					inputType="text"
					label={isUsa ? 'SSN Number' : 'TaxID Number'}
					isRequired
					errorMessage={error.national_id_number}
					isError={!!error.national_id_number}
				/>
				<div className="input-wrapper-inner--snn-btn">
					<Button
						type="button__filled button__filled--primary button__large button__block mt-2"
						label="Submit"
						handleClick={handleSubmit}
						disabled={
							!!error.national_id_number || nationalIdNumber.length === 0
						}
					/>
				</div>
			</div>
		);
	}, [
		handleChange,
		nationalIdNumber,
		isUsa,
		error.national_id_number,
		handleSubmit,
	]);

	const labelElement = useMemo(() => {
		return <LabelElement text="Identity Verification" />;
	}, []);

	const headerElement = useMemo(() => {
		return (
			<div className="provide-ssn__header">
				<div className="provide-ssn__header__title">
					Please provide your TaxID (e.g., SSN, PAN)
				</div>
				<div className="provide-ssn__header__sub-title">
					We need some more information to validate your identity.
				</div>
			</div>
		);
	}, []);

	return isLoading ? (
		<div className="kyc-loader-wrapper">
			<Loader dimension={40} className="loader-blue" />
		</div>
	) : (
		<BodyWrapper
			headerElement={headerElement}
			label={labelElement}
			bodyElement={bodyContent}
		/>
	);
};
