import { useCallback } from 'react';

export const useEnv = () => {
	const injectUrl = useCallback((url: string) => {
		if (!url) {
			return;
		}
		const scriptAd = document.createElement('script');
		scriptAd.type = 'text/javascript';
		scriptAd.src = url;
		document?.getElementsByTagName?.('head')?.[0]?.appendChild?.(scriptAd);
	}, []);

	return { injectUrl };
};
