import { useCallback, useMemo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { APIS, ITokenApiType } from 'constants/api';
import { useSharedVariables } from 'hooks/shared-variable';
import { AccessTokenState, CustomLoaderState, QrStatusCodeState } from 'states';
import { getOrigin } from 'utils';

export const useToken = () => {
	const { apiEndPoint: API_HOST } = useSharedVariables();

	const [, setAccessToken] = useRecoilState(AccessTokenState);
	const [qrStatusCode, setQrStatusCodeState] =
		useRecoilState(QrStatusCodeState);
	const resetAccessToken = useResetRecoilState(AccessTokenState);
	const setCustomLoader = useSetRecoilState(CustomLoaderState);

	const { sessionId, onboardingType } = useSharedVariables();

	const getTokenType = useMemo(
		() => (onboardingType === 'qr' ? onboardingType : 'session'),
		[onboardingType]
	);

	const getLoader = useCallback(
		async (code: string, type: ITokenApiType, accessToken: string) => {
			try {
				const url = `${API_HOST}${APIS.TOKEN}?fetchLoader=true`;
				const customConfig = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						...(type === 'qr' && { Domain: getOrigin() }),
					},
					body: JSON.stringify({
						code,
						type,
					}),
				};
				const response = await fetch(url, { ...customConfig });
				const apiPayload = await response.json();
				const { loader, whitelabel, loaderWhitelabel } =
					apiPayload?.data ?? apiPayload ?? {};
				const customLoader = { loader, whitelabel, loaderWhitelabel };
				setCustomLoader({ ...customLoader });
				const json = JSON.stringify({ ...(customLoader ?? {}) });
				localStorage.setItem('session-loader', json);
			} catch (err: any) {
				const customLoader = {
					loader: undefined,
					whitelabel: undefined,
					loaderWhitelabel: undefined,
				};
				setCustomLoader({ ...customLoader });
				const json = JSON.stringify({ ...(customLoader ?? {}) });
				localStorage.setItem('session-loader', json);
			}
		},
		[API_HOST, setCustomLoader]
	);

	const generateToken = useCallback(
		async (id?: string, type?: string) => {
			resetAccessToken();
			try {
				const response = await fetch(
					`${API_HOST}${APIS.TOKEN}/${id ?? sessionId}?type=${type ?? getTokenType}`
				);

				const apiPayload = await response.json();
				const statusCode = response?.status ?? 400;

				const { data } = apiPayload ?? {};
				if (data?.token) {
					getLoader(
						data?.code ?? data?.qrId,
						data?.code ? 'code' : 'qr',
						data.token
					);
				}
				setAccessToken({
					token: data?.token ?? '',
					code: data?.code ?? data?.qrId ?? '',
				});

				if (qrStatusCode !== 400 && qrStatusCode !== 500) {
					setTimeout(() => {
						setQrStatusCodeState(statusCode);
					}, 2000);
				}

				return data;
			} catch (err: any) {
				setQrStatusCodeState(401);
				resetAccessToken();
				return null;
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getTokenType, sessionId, qrStatusCode, API_HOST]
	);
	return { generateToken };
};
