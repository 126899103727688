import { FC, useEffect, useState } from 'react';
import { getDocument, PDFDocumentProxy } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import PageRenderer from './PageRenderer';

interface IDocumentPreview {
	docUrl: string;
}

export const DocumentPreview: FC<IDocumentPreview> = ({ docUrl }) => {
	const [numPages, setNumPages] = useState(0);
	const [pdf, setPdf] = useState<PDFDocumentProxy>();
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const fetchPdf = async () => {
			const loadingTask = getDocument(docUrl);
			const allPdf = await loadingTask.promise;
			setNumPages(allPdf.numPages);
			setPdf(allPdf);
			setIsLoading(false);
		};
		if (docUrl) fetchPdf();
	}, [docUrl]);

	return (
		<div className="pdf-div">
			{isLoading ? (
				<div className="ProofReadingDocument--container__loading">
					{' '}
					Loading...{' '}
				</div>
			) : (
				Array.from(Array(numPages).keys()).map(pageNum => (
					<div key={pageNum}>
						<PageRenderer pdf={pdf!} pageNumber={pageNum + 1} />
						{pageNum < numPages - 1 && <hr />}
					</div>
				))
			)}
		</div>
	);
};
