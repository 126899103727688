import type { IConfirmationModal } from './type';
import { FC } from 'react';

import { Button, Image, Loader } from '@storybook';

import './confirmation.scss';

export const Confirmation: FC<IConfirmationModal> = ({
	visible,
	title,
	description,
	handleModal,
	boldDescription = '',
	label,
	type = 'danger',
	secondryLabel = 'Back',
	loading = false,
}) => {
	if (!visible) {
		return null;
	}

	return (
		<div className="modal-main" onClick={() => handleModal(false, false)}>
			<div className="modal-content" onClick={e => e.stopPropagation()}>
				<div className="confirmation-header">
					<Image
						fileName="confirmation_page.svg"
						className="confirmation-image"
					/>
					<div className="modal-title">{title}</div>
				</div>
				<div>
					<div className="modal-des">
						<span>
							{description}
							{boldDescription && <strong>{boldDescription}</strong>}
						</span>
					</div>
					<div className="modal-footer">
						{secondryLabel && (
							<Button
								label={secondryLabel}
								handleClick={() => handleModal(false, false)}
								type="button__filled button__filled--secondary button__large button-cancel"
							/>
						)}
						{label && (
							<Button
								label={
									loading ? <Loader type="loader" dimension={20} /> : label
								}
								handleClick={() => handleModal(false, true)}
								type={`button__filled button__filled--${type} button__large button-ok`}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
