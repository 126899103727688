export const isEmptyObject = (obj: any) => {
	if (obj)
		return Object.entries(obj)?.length === 0 && obj?.constructor === Object;
	return false;
};

export const isEmptyArrayObject = (obj: any) => {
	for (const key in obj) {
		if (Object.hasOwn(obj, key)) {
			if (key > '0') {
				return false;
			}
			return true;
		}
	}
	return true;
};
export const replaceEmptyObjectsWithEmptyStrings = (obj: any) => {
	for (const key in obj) {
		if (
			typeof obj?.[key] === 'object' &&
			!Object.keys(obj?.[key] ?? {}).length
		) {
			obj[key] = '';
		}
	}
	return obj;
};
