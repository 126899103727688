import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as Token } from 'envs';

/**
 * Fetches the country name for a given zipcode using the Google Maps Geocoding API.
 *
 * @param {string} zipcode - The zipcode to look up.
 * @returns {Promise<string|null>} - Resolves to the country name, or null if not found or an error occurs.
 */

export const getCountry = async (zipcode: string) => {
	const apiKey = Token;

	// Encode the zipcode to be used in the API request
	const encodedZipcode = encodeURIComponent(zipcode);

	// Construct the URL for the Google Maps Geocoding API request
	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedZipcode}&key=${apiKey}`;

	try {
		// Fetch data from the API
		const response = await fetch(url);

		if (!response.ok) {
			throw new Error(`Failed to fetch. Status: ${response.status}`);
		}

		const data = await response.json();

		if (data.status === 'OK' && data.results.length > 0) {
			// Extract address components from response
			const components = data.results[0].address_components;
			// Find the address component that represents the country
			const countryComponent = components.find((component: any) =>
				component.types.includes('country')
			);
			// If country component found, return the long name of the country else Error
			if (countryComponent) {
				return countryComponent.long_name;
			} else {
				throw new Error('Country not found in the address components');
			}
		} else {
			// Throw error if no results found for the given zipcode
			throw new Error('No results found for the given zipcode');
		}
	} catch (error) {
		// Return null if an error occurs during API request
		throw new Error('No results found for the given zipcode');
		return null;
	}
};
