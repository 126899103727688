export const REGEX_ACCEPT_ONLY_NUMBER = /^[0-9\b]+$/;

export const loginToasterMessages = {
	SENT_OTP: 'OTP send successfully to mobile number ',
	PHONE_NUMBER_VALIDATION: 'Mobile Number length must be greater than 8',
	INVALID_OTP: 'Invalid OTP.',
	ACCEPTED_NUMBER_ONLY: 'Only number allowed in OTP input',
	PHONE_NOT_AVAILABLE: 'Phone number or Country is not available.',
	WENT_WRONG: 'Something went wrong.',
	VERIFIED: 'OTP verified successfully',
	InvalidInput: 'Please fill all the required fields',
};
