import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { UrlPathState } from 'states';
import { SignAgreementUrlState, useSignAgreementRequests } from '../stores';

import { SimpliciSignWebComponent } from 'views/accreditation/pages/accreditation-506b/accre-web-component';
import '../sign-agreement.scss';

export const SignAgreementSimpliciSign = () => {
	/**
	 * custom hooks have been imported in here
	 * */
	const navigate = useNavigate();
	const { submitRequest } = useSignAgreementRequests();

	/**
	 * Global recoil states have been imported in here
	 * */
	const { url } = useRecoilValue(SignAgreementUrlState);
	const resetUrl = useResetRecoilState(SignAgreementUrlState);
	const kycMobilePath = useRecoilValue(UrlPathState);

	const [prepareUrl, setPrepareUrl] = useState<string | undefined>(url);
	const handleFinishInvoked = useRef(false);

	useEffect(() => {
		if (url) {
			setPrepareUrl(url);
		}
	}, [url]);

	(window as any).handleSuccess = () => {
		// eslint-disable-next-line no-console
		console.log('triggered simplici esign');
		if (!handleFinishInvoked.current) {
			handleFinishInvoked.current = true;
			navigate(kycMobilePath);
			resetUrl();
			submitRequest();
			handleFinishInvoked.current = false;
		}
	};

	return (
		<div className="sign-aggrement__container">
			<SimpliciSignWebComponent
				currentUrl={prepareUrl ?? ''}
				submit_esign="handleSuccess"
				hideLogo={true}
				hideUser={true}
			/>
		</div>
	);
};
