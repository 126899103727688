import { useState, useEffect } from 'react';

export const useWebWorker = (file: string) => {
	const [worker, setWorker] = useState<any>(null);
	useEffect(() => {
		const newWorker = new Worker(file);
		setWorker(newWorker);

		return () => {
			newWorker.terminate();
		};
	}, [file]);

	const runWorker = (input: any, cb: any) => {
		worker.postMessage(input);
		worker.onmessage = (event: any) => {
			cb(event?.data);
		};
	};

	return { runWorker };
};
