import { Button, Image, Modal } from '@storybook';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { PAYMENT_FAILED } from 'constants/common';
import { useNextStep, useNotification } from 'hooks';
import { IAccount, selectedAccountState, selectedBankState } from 'states';
import { convertToCurrencySystem } from 'utils';
import {
	ACCOUNTS,
	ILogoTypes,
	MAX_DECIMAL_DIGITS,
	MIN_DECIMAL_DIGITS,
	SESSION_TYPE,
	getLogos,
} from '../../constants';
import {
	IsInvestingAmountEmptyState,
	PayInUnitPricingState,
	useFundInvestmentRequests,
} from '../../stores';

import { FundNameState } from 'hooks/use-next-step/stores';
import './payment-summary.scss';

interface IPaymentSummary {
	accounts: IAccount[];
	tokenId: string;
	handleBack: VoidFunction;
	setLoading: any;
}
export const PaymentSummary: FC<IPaymentSummary> = ({
	accounts,
	tokenId,
	setLoading,
}) => {
	const [isPaymentSuccess, setIsPaymentSucess] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const isInvestingAmountEmpty = useRecoilValue(IsInvestingAmountEmptyState);

	const { sessionPayloadDetail, handleNext, payInUnitPricing } = useNextStep();
	const { submitPayment } = useFundInvestmentRequests();
	const fundNameState = useRecoilValue(FundNameState);

	const { investingAmount, fundName, sessionType = '' } = sessionPayloadDetail;
	const isSeller = useMemo(
		() => sessionType === SESSION_TYPE.Seller,
		[sessionType]
	);
	const isBuyer = useMemo(
		() => sessionType === SESSION_TYPE.Buyer,
		[sessionType]
	);
	const { summary } = useMemo(
		() => ACCOUNTS[sessionType as SESSION_TYPE.Seller] ?? {},
		[sessionType]
	);
	const { btnLabel, bankTitle } = useMemo(
		() => ACCOUNTS[sessionType as SESSION_TYPE.Seller] ?? {},
		[sessionType]
	);

	const selectedAccounts = useRecoilValue(selectedAccountState);

	const selectedBank = useRecoilValue(selectedBankState);

	// Recoil state for managing unit pricing
	const payInUnitPrice = useRecoilValue(PayInUnitPricingState);

	const initialUnitPricingValue = useMemo(
		() => !isSeller && !isBuyer && !!payInUnitPricing,
		[isBuyer, isSeller, payInUnitPricing]
	);

	const { errorNotification, warningNotification } = useNotification();

	const onFailed = useCallback(
		(res: any) => {
			setIsPaymentSucess(false);
			if (/hold/.test(res?.message)) {
				warningNotification(res?.message ?? PAYMENT_FAILED);
			} else {
				errorNotification(res?.message ?? PAYMENT_FAILED);
			}
		},
		[warningNotification, errorNotification]
	);

	const { institutionName } = useMemo(() => selectedBank ?? {}, [selectedBank]);

	// Memoize `unit` and `perUnitPrice` to prevent unnecessary re-renders
	const { unit } = useMemo(() => payInUnitPrice, [payInUnitPrice]);

	const handlePayment = useCallback(async () => {
		const payload: any = {
			tokenId,
			sessionId: sessionPayloadDetail.sessionId,
			accountId: selectedAccounts?.accountId,
			accountType: selectedAccounts?.subtype.toUpperCase(),
		};
		if (isInvestingAmountEmpty) {
			payload.amount = parseFloat(investingAmount);
		}
		// If payInUnitPricing is truthy, set payload with amount and initialUnitPricingValue
		if (initialUnitPricingValue) {
			payload.amount = parseFloat(investingAmount);
			payload.payInPricing = {
				unit: Number(unit ?? 0),
				pricePerUnit: parseFloat(payInUnitPricing),
				totalInvestmentAmount: parseFloat(investingAmount),
			};
		}
		setLoading(true);
		setIsPaymentSucess(true);
		await submitPayment(payload, handleNext, onFailed);
		setLoading(false);
	}, [
		tokenId,
		sessionPayloadDetail.sessionId,
		selectedAccounts?.accountId,
		selectedAccounts?.subtype,
		isInvestingAmountEmpty,
		setLoading,
		submitPayment,
		handleNext,
		onFailed,
		investingAmount,
		unit,
		payInUnitPricing,
		initialUnitPricingValue,
	]);

	const onCancel = () => {
		setOpenModal(false);
	};

	const decimalDigits = useMemo(
		() =>
			!isSeller && !isBuyer && !!payInUnitPricing
				? MAX_DECIMAL_DIGITS
				: MIN_DECIMAL_DIGITS,
		[isBuyer, isSeller, payInUnitPricing]
	);

	const renderModal = useMemo(() => {
		return (
			<Modal
				showCloseBtn={false}
				closeModal={() => setOpenModal(false)}
				className="Fund-confirmation-modal"
				isOpen={openModal}
				modalName="Fund-confirmation-modal"
			>
				<div
					className="Fund-confirmation-modal__main"
					onClick={e => e.stopPropagation()}
				>
					<div className="Fund-confirmation-modal__top">
						<div className="Fund-confirmation-modal__img">
							<Image fileName="confirmation_page.svg" />
						</div>
						<div className="Fund-confirmation-modal__title">
							Are you sure you want to proceed?
						</div>
					</div>

					<div className="Fund-confirmation-modal__content">
						<p>
							By submitting this request, an amount of{' '}
							<b>
								$
								{convertToCurrencySystem(
									Number(investingAmount ?? 0),
									decimalDigits
								)}
							</b>{' '}
							will be deducted from your{' '}
							<b>{selectedBank?.institutionName ?? 'NA'}</b> account to complete
							your investment. Please carefully review the details below to
							ensure they are correct:
						</p>
						<span>
							{' '}
							Amount:{' '}
							<b>
								$
								{convertToCurrencySystem(
									Number(investingAmount ?? 0),
									decimalDigits
								)}
							</b>
						</span>
						<span>
							{' '}
							Bank Name: <b>{selectedBank?.institutionName ?? '--'} </b>
						</span>
						<span>
							Account type: <b>{selectedAccounts?.subtype ?? '--'}</b>
						</span>
						<span>
							Account number: &#8226;&#8226;&#8226;
							<b>{selectedAccounts?.mask ?? '--'}</b>
						</span>
					</div>
				</div>
				<div className="Fund-confirmation-modal__footer">
					<Button
						label="Cancel"
						handleClick={onCancel}
						type="button__filled button__filled--secondary button__large button__block modal__button"
					/>
					<Button
						label="Make Investment"
						handleClick={handlePayment}
						type="button__filled button__filled--primary button__large button__block modal__button"
					/>
				</div>
			</Modal>
		);
	}, [
		openModal,
		investingAmount,
		selectedBank?.institutionName,
		selectedAccounts?.subtype,
		selectedAccounts?.mask,
		handlePayment,
		decimalDigits,
	]);

	const handleFundAccount = useCallback(
		() => (isSeller ? handlePayment() : setOpenModal(true)),
		[handlePayment, isSeller]
	);

	// Conditionally render unit pricing details if both payInUnitPricing and unit are truthy
	const renderUnitPricingDetails = useMemo(() => {
		return (
			initialUnitPricingValue &&
			!!unit && (
				<>
					<div className="summary__desc">
						<span>Number of Shares</span>: <b>{Number(unit)}</b>
					</div>
					<div className="summary__desc">
						<span>Share Price (USD)</span>:
						<b>${convertToCurrencySystem(payInUnitPricing, decimalDigits)}</b>
					</div>
				</>
			)
		);
	}, [initialUnitPricingValue, payInUnitPricing, unit, decimalDigits]);

	return (
		<Fragment>
			<div className="summary__wraper">
				<div className="summary__head">
					Summary
					<span>
						{summary || 'Please take a moment and review your account'}
					</span>
				</div>
				{!isSeller && (
					<div className="summary__desc__wraper">
						<div className="summary__desc">
							<span> Fund Account </span>:
							<b>{fundName || fundNameState || '--'}</b>
						</div>
						{renderUnitPricingDetails}
						<div className="summary__desc desc_4">
							<span>Total Amount </span>:
							<b>
								$
								{convertToCurrencySystem(
									Number(investingAmount ?? 0),
									decimalDigits
								)}
							</b>
						</div>
					</div>
				)}
				<div className="summary__option_wraper">
					{bankTitle || 'Transferring From'}
					<div className="summary__option_box">
						<div className="summary__logo_wraper">
							<Image
								fileName={
									getLogos[institutionName as ILogoTypes] ?? getLogos['default']
								}
							/>
						</div>
						<div className="summary__value_wraper">
							<div className="summary__option_desc">
								{selectedBank?.institutionName ?? '--'}
								<span>{selectedAccounts?.subtype ?? '--'}</span>
							</div>
							<div className="summary__option_value">
								&#8226;&#8226;&#8226;{selectedAccounts?.mask ?? '--'}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="fund-investment__button_wrapper mt-16">
				{!isPaymentSuccess && (
					<>
						<Button
							label={btnLabel || 'Fund Account'}
							handleClick={handleFundAccount}
							type="button__filled button__filled--primary button__large button__block cursor-pointer"
							disabled={
								accounts?.length === 0 ||
								(selectedAccounts &&
									selectedAccounts?.balance?.available <
										Number(investingAmount))
							}
						/>
					</>
				)}
			</div>
			{renderModal}
		</Fragment>
	);
};
