import { atom } from 'recoil';
import { v4 } from 'uuid';

export interface IEventsState {
	name: string;
	image: string;
	date: string;
	description: string;
	businessId?: string;
	qrId: string[];
	_id?: string;
	address: string;
	users: string[];
}

export const KYCEventsState = atom<IEventsState | null>({
	key: v4(),
	default: null,
});
