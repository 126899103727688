import { ChangeEvent, DragEvent, FC, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Image } from '@storybook';

import { AccredUploadFileState, SelectedUploadOptionKeyState } from '../stores';
import { convertFileToBase64 } from 'utils';
import { useNotification } from 'hooks';

import { MESSAGE, max_FileSize } from './constants';

interface IUploadDocuments {
	handleBack: VoidFunction;
	handleNext?: any;
	isLoading?: boolean;
}
export const UploadDocuments: FC<IUploadDocuments> = ({
	handleBack,
	handleNext,
	isLoading,
}) => {
	const [uploadFiles, setUploadFiles] = useRecoilState(AccredUploadFileState);
	const { errorNotification } = useNotification();
	const { FILE_LIMIT_EXCEEDS_MESSAGE } = MESSAGE;
	const selectedUploadKey = useRecoilValue(SelectedUploadOptionKeyState);

	const handleOnUploadDocument = useCallback(
		async (
			e: ChangeEvent<HTMLInputElement> | any,
			type?: 'drag' | 'browse'
		) => {
			/**
			 * @Shivangi here we are assigning the file after extracting from the event based on the the type (drag or browse)
			 * */
			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as ChangeEvent<HTMLInputElement>).target.files?.[0]) ??
				({} as File);

			// Validation to allow only PDF and DOC files
			const allowedFileTypes = [
				'application/pdf',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			];
			if (!allowedFileTypes.includes(file.type)) {
				return errorNotification('Only PDF and DOC files are allowed.');
			}

			const totalSize = uploadFiles.reduce((acc, curr) => {
				return acc + curr.size;
			}, file.size);
			if (file.size > max_FileSize || totalSize > max_FileSize) {
				e.target.value = '';
				return errorNotification(FILE_LIMIT_EXCEEDS_MESSAGE);
			}

			if (file) {
				const fileName = file.name.split('.');
				//shivangi added file as we have stored file[0] and dragged file in file var
				const base64file: string = (await convertFileToBase64(file)) as string;
				setUploadFiles(prev => {
					const prevData = JSON.parse(JSON.stringify(prev));
					const prevState = [...prevData];
					const obj = {
						base64Image: base64file,
						fileName: fileName[0],
						type: file.type,
						size: file.size,
						selectedKey: selectedUploadKey,
					};
					return [obj, ...prevState];
				});
			}
			if (type !== 'drag') {
				/**
				 * @Amit here we are resetting the event so that user can upload the same file again
				 * */
				(e as ChangeEvent<HTMLInputElement> | any).target.value = '';
			}
			return [];
		},
		[
			FILE_LIMIT_EXCEEDS_MESSAGE,
			errorNotification,
			setUploadFiles,
			selectedUploadKey,
			uploadFiles,
		]
	);

	const handleOnClickDelete = useCallback(
		(index: number) => {
			setUploadFiles((prev: any) => {
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState.splice(index, 1);
				return prevState;
			});
		},
		[setUploadFiles]
	);

	const handleOnBackPress = useCallback(() => {
		setUploadFiles([]);
		handleBack();
	}, [handleBack, setUploadFiles]);

	const renderUploadedFiles = useMemo(
		() =>
			uploadFiles?.map((item: any, index: number) => {
				return (
					item?.selectedKey === selectedUploadKey && (
						<div
							className="uploaded-lists"
							key={`${item.fileName}__${index.toString()}`}
						>
							<div className="uploaded-lists__items">
								<Image fileName="TypePDF.svg" />
								<div className="uploaded-lists__items__listname">
									{item.fileName ?? ''}
								</div>
								<i
									onClick={() => handleOnClickDelete(index)}
									className="ri-delete-bin-6-line"
								></i>
							</div>
						</div>
					)
				);
			}),
		[handleOnClickDelete, selectedUploadKey, uploadFiles]
	);

	/**
	 * @Shivangi here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Shivangi here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleOnUploadDocument(files?.[0] as File, 'drag');
		},
		[handleOnUploadDocument]
	);

	const isSubmitDisable = useMemo(
		() =>
			(
				uploadFiles?.filter(item => item.selectedKey === selectedUploadKey) ??
				[]
			).length === 0,
		[selectedUploadKey, uploadFiles]
	);

	return (
		<div className="upload-document">
			<div className="upload-document__inner">
				<div
					className="upload-document__inner__details"
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					<Image fileName="upload-icon.svg" />
					<label htmlFor="upload-document">
						Drag and drop files or <span>Browse file</span>
					</label>
					<div className="info">Supported file format: Pdf and Docx</div>
					<div className="upload-document__description">
						Maximum upload file size: 10 MB.
					</div>
					<input
						className="upload-document__inner__details__file-upload"
						type="file"
						onChange={e => handleOnUploadDocument(e)}
						id="upload-document"
						accept=".docx,.pdf,.doc"
						hidden
					/>
				</div>
			</div>
			<div className="uploaded-lists-wrapper">{renderUploadedFiles}</div>
			<div className="button-wrapper">
				<Button
					label="Submit"
					type="button__filled button__filled--primary button__large button__block"
					handleClick={handleNext as any}
					disabled={isSubmitDisable || isLoading}
				/>
				<Button
					label="Back"
					type="button__filled button__filled--secondary button__large button__block"
					handleClick={handleOnBackPress}
				/>
			</div>
		</div>
	);
};
