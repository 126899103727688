import { atom } from 'recoil';
import { ICustomLoaderState } from './type';

const getSetting = () => {
	const localStorageData = localStorage.getItem('session-loader');
	const localObject = localStorageData && JSON.parse(localStorageData);
	const { loader, whitelabel, loaderWhitelabel } = localObject ?? {};
	return {
		loader,
		whitelabel,
		loaderWhitelabel,
	};
};

export const CustomLoaderState = atom<ICustomLoaderState>({
	key: 'custom-loader-state-key',
	default: getSetting(),
});
