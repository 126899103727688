import { useEffect } from 'react';
import { Loader } from '@storybook';

import { useAMLRequests } from './stores';

import './aml.scss';

export const Aml = () => {
	const { amlRequest } = useAMLRequests();
	useEffect(() => {
		amlRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderLoaderDetails = () => {
		return (
			<>
				<div className="GifLoader--container__heading">Fetching details</div>
				<div className="GifLoader--container__subHeading">
					Please wait while we verify your information{' '}
				</div>
			</>
		);
	};

	return (
		<div className="aml-loader-wrapper">
			<Loader description={renderLoaderDetails()} />
		</div>
	);
};
