import { Button, Image, Loader, SkeltonLoader } from '@storybook';
import { Fragment, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { ImageKey, UseVerification } from '../../hooks/use-verification';
import { IsKycLoading } from 'views/kyc/stores';
import { useRecoilValue } from 'recoil';
import {
	formPropertiesState,
	kycActiveStepName,
	KycIsImageSubmit,
} from '../../store';
import { IFormProperties } from 'states';
import useOrientation from 'utils/detect-oriantation';
import { isMobileDevice } from 'utils';
import { showCaptureButton } from '../../store/utils';
import { KycDocUploadFooter } from '../kyc-doc-upload-footer';

export const KycDocumentUpload = ({
	imgSrc,
	btnNamefirst,
	btnNameSecond,
	keyName,
	canvasImage,
	blankImage,
	bodyName,
}: any) => {
	const formProperties = useRecoilValue<IFormProperties>(formPropertiesState);
	const activeStepName = useRecoilValue(kycActiveStepName);
	const isImageSubmit = useRecoilValue(KycIsImageSubmit);

	const { states, setStates, methods, refs } = UseVerification(
		bodyName,
		keyName
	);
	const {
		handleOnClickDelete,
		captureImage,
		CameraActivefunc,
		handleSubmitPhoto,
		cameraLoaderClassName,
		onClickUploadButton,
		handleDragOver,
		handleDrop,
	} = methods;
	const { setCamerascreenActive } = setStates;
	const { canvasRef, captureRef, videoRef } = refs;
	const { filledCamerascreen, cameraWidth, cameraHeight, cameraScreenActive } =
		states;

	const isLoading = useRecoilValue(IsKycLoading);
	const orientation = useOrientation();

	// Need to do testing to check in various devices
	const { width, height } = useMemo(() => {
		const width = window.innerWidth;
		const height = window.innerHeight;
		return { width, height };
	}, []);

	const renderChoosePhotoButton = useCallback(
		(name: any) => {
			return (
				<div className="btn-icon-wrapper">
					{!isLoading && <i className="ri-file-upload-line"></i>}
					<div className="upload-text">
						{isLoading ? (
							<Loader type="" dimension={25} className="loader-white" />
						) : (
							name
						)}
					</div>
				</div>
			);
		},
		[isLoading]
	);

	const renderUploadButton = useCallback(
		(name: any, key: any) => {
			return (
				<>
					<input
						type="file"
						onChange={e => (isLoading ? {} : onClickUploadButton(e, key))}
						id={`${name}__${key}`}
						accept=".jpeg,.jpg,.png"
						hidden
						disabled={isLoading}
						style={{
							display:
								name === 'Submit Photo' && isImageSubmit ? 'none' : 'contents',
						}}
					/>
					<div className="btn-icon-wrapper">
						{!isLoading && <i className="ri-file-upload-line"></i>}

						<div className="upload-text">
							{isLoading ? (
								<Loader type="" dimension={16} className="loader-white" />
							) : (
								name
							)}
						</div>
					</div>
				</>
			);
		},
		[isLoading, onClickUploadButton, isImageSubmit]
	);

	const uploadButton = useCallback(
		(name: string, key: ImageKey) => {
			return (
				showCaptureButton(name) && (
					<label
						htmlFor={`${name}__${key}`}
						onClick={() => {
							if (name === 'Use My Camera') {
								CameraActivefunc(key);
							} else if (/submit/gi.test(name)) {
								if (!isLoading) {
									handleSubmitPhoto(formProperties);
								}
							}
						}}
						className={cameraLoaderClassName(name)}
					>
						{name === 'Use My Camera' ? (
							<div className="btn-icon-wrapper">
								<i
									className="ri-camera-line action-icon"
									style={{ color: 'black' }}
								/>
								<div
									className={
										name === 'Use My Camera' ? 'camera-btn-text' : 'upload-text'
									}
								>
									{name}
								</div>
							</div>
						) : (
							<Fragment>
								{name !== 'Submit Photo' && isImageSubmit
									? renderUploadButton(name, key)
									: renderChoosePhotoButton(name)}
							</Fragment>
						)}
					</label>
				)
			);
		},
		[
			cameraLoaderClassName,
			isImageSubmit,
			renderUploadButton,
			renderChoosePhotoButton,
			CameraActivefunc,
			isLoading,
			handleSubmitPhoto,
			formProperties,
		]
	);

	return (
		<>
			<video
				ref={videoRef}
				// className={`capture-vid ${cameraScreenActive ? "capture-vid-active" : ""}`}
				id="capture-video"
				width={cameraWidth}
				height={cameraHeight}
				style={{ display: 'none' }}
				autoPlay
			/>
			<div
				className={`filled-card ${
					isMobileDevice() ? 'filled-card-active' : ''
				}   ${filledCamerascreen ? '' : 'filled-card-cameraScreenInactive'}`}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				{imgSrc ? (
					<Fragment>
						<Image
							url={imgSrc}
							width="100%"
							className="filled-card__card-image"
							height={300}
						/>
						<div className="image-action-wrapper">
							<Button
								label={
									<>
										<i className="ri-delete-bin-line action-icon" />
										{/* <span>Remove</span> */}
									</>
								}
								handleClick={() => handleOnClickDelete(keyName)}
								type="image-action-wrapper__btn"
								disabled={isLoading}
							/>
						</div>
					</Fragment>
				) : (
					<div className="filled-card__center">
						{isLoading ? (
							<div className="skeleton-img-loader">
								<SkeltonLoader width="100%" height="270px" />
							</div>
						) : (
							<>
								{
									<>
										<Modal
											className="image_capture__modal"
											isOpen={cameraScreenActive}
											onRequestClose={() => {
												setCamerascreenActive(false);
											}}
											contentLabel="Canvas Modal"
										>
											<div className="Image_capture--back-btn">
												<Button
													label={<i className="ri-arrow-left-line">Back</i>}
													handleClick={() => {
														setCamerascreenActive(false);
													}}
													type="back__btn"
												/>
											</div>
											<div className="full-screen-modal">
												<canvas
													width={width}
													height={height}
													ref={canvasRef}
													id="canvas-verification"
													className={`${
														cameraScreenActive
															? ''
															: 'verification__capture-canvas-hide'
													} capture-canvas`}
												/>
											</div>
											<div>
												<div
													ref={captureRef}
													className={`modal__capture-btn capture-btn  ${
														isMobileDevice() ? 'capture-btn-active' : ''
													}`}
												>
													<Button
														label="Capture"
														handleClick={() => captureImage(keyName)}
														type="button__filled capture__code button__filled--primary button__large button__block"
													/>
												</div>
												<div>{<KycDocUploadFooter bodyName={bodyName} />}</div>
											</div>
										</Modal>

										<Image
											id="myImage"
											url={canvasImage}
											style={{ display: 'none' }}
										/>
										{/* Modal for ilustration for landscape mode */}
										<Modal
											className="full-screen-modal"
											isOpen={orientation === 'landscape'}
										>
											<div className="rotate-modal--body-wrapper">
												<div className="rotate-logo">
													<i className="ri-rotate-lock-fill"></i>
													<div className="rotate-logo-desp">
														Rotate your phone to portrait mode for the best
														experience.
													</div>
												</div>
											</div>
										</Modal>
									</>
								}
							</>
						)}
						{!isLoading ? (
							<Image
								fileName={blankImage ?? 'driving_license_back_img.svg'}
								className={`id-card ${cameraScreenActive ? 'id-card-hide' : ''}`}
							/>
						) : null}
					</div>
				)}
			</div>
			<div className="filled-card__bottom">
				<div
					ref={captureRef}
					className={`capture-btn ${
						isMobileDevice() ? 'capture-btn-active' : ''
					}`}
				></div>
				{!cameraScreenActive ? uploadButton(btnNamefirst, keyName) : null}
				{showCaptureButton(btnNamefirst) && (
					<div className={cameraScreenActive ? '' : 'border-right'} />
				)}
				{!cameraScreenActive &&
					uploadButton(
						(activeStepName === 'step2' && formProperties?.frontImage) ||
							(activeStepName === 'step3' && formProperties?.backImage)
							? 'Submit Photo'
							: btnNameSecond,
						keyName
					)}
			</div>
		</>
	);
};
