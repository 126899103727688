import { Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	PlaidLinkOnEvent,
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';

import { useNextStep, useNotification, useSharedVariables } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { isShowSkipState } from 'states';
import { ConnectBank, PayoutBankDetails } from '../components';
import { MESSAGE } from '../constants';
import { useFundInvestmentRequests } from '../stores';

export const PayOut = () => {
	const [loader, setLoader] = useState(false);
	const [showConnectBank, setShowConnectBank] = useState(true);
	const [token, setToken] = useState(null);
	const [message, setMessage] = useState('');
	const setIsShowSkip = useSetRecoilState(isShowSkipState);

	const { sessionPayloadDetail } = useNextStep();
	const { onboardingType } = useSharedVariables();

	const {
		_id: pipelineId,
		stepsId,
		userId,
		investingAmount,
		currentAction,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const { errorNotification } = useNotification();

	const { createToken, linkAccount, fetchBankDetails } =
		useFundInvestmentRequests();

	//sending institution name and public token to backend once user will cliked on Success screen
	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const sucessPayload: any = {
				pipelineId: pipelineId ?? '',
				stepId: stepsId,
				userId,
				actions: [
					{
						id: 'fundInvestmentTokenExchange',
						data: {
							token: publicToken,
							institutionName: metadata.institution?.name,
							linkToken: token,
						},
					},
				],
			};
			if (onboardingType === 'complex') {
				sucessPayload.nodeId = currentAction._id;
			}
			setMessage(MESSAGE.LINKING_ACCOUNT);
			setToken(null);
			setLoader(true);
			await linkAccount(sucessPayload);
			setMessage(MESSAGE.FETCHING_BANK_DETAILS);
			await fetchBankDetails();
			setShowConnectBank(false);
			setToken(null);
			setLoader(false);
			setMessage('');
		},

		[
			pipelineId,
			stepsId,
			userId,
			token,
			onboardingType,
			linkAccount,
			fetchBankDetails,
			currentAction._id,
		]
	);

	//not required for now keep it for future use
	const onEvent = useCallback<PlaidLinkOnEvent>(() => {}, []);

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setLoader(false);
		setToken(null);
	}, []);

	//plaid configuration
	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};

	// will be used in future as this is not required for now ready, error, exit , for now open is required only
	const { open } = usePlaidLink(config);

	const handleSuccessOnCreateToken = useCallback((data: any) => {
		setToken(data?.token);
	}, []);

	const handleOnFailedCreateToken = useCallback(() => {
		errorNotification('Someting went wrong');
		setIsShowSkip(true);
	}, [errorNotification, setIsShowSkip]);

	const handleConnect = useCallback(async () => {
		setLoader(true);
		if (onboardingType === 'complex') {
			/**
			 * yaha pe token set karna hai in the local state
			 * */
			setToken(currentAction?.token);
			return;
		}
		createToken(handleSuccessOnCreateToken, handleOnFailedCreateToken);
	}, [
		createToken,
		currentAction?.token,
		handleOnFailedCreateToken,
		handleSuccessOnCreateToken,
		onboardingType,
	]);

	useEffect(() => {
		if (token) open();
	}, [open, token]);

	const renderMainComponent = useMemo(() => {
		if (loader && !token)
			return (
				<div className="fund-loader">
					<Loader />
					<div style={{ textAlign: 'center' }}>{message}</div>
				</div>
			);
		if (showConnectBank)
			return (
				<ConnectBank
					headerTitle="Connect your bank accounts to receive fund"
					title="Amount to receive"
					handleConnect={handleConnect}
					investingAmount={investingAmount}
					setShowConnectBank={setShowConnectBank}
					loader={loader}
				/>
			);
		else
			return <PayoutBankDetails handleBack={() => setShowConnectBank(true)} />;
	}, [loader, token, message, showConnectBank, handleConnect, investingAmount]);

	return <div className="fi-root-wrapper">{renderMainComponent}</div>;
};
