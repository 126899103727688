import { useSharedVariables } from 'hooks/shared-variable';
import { useState } from 'react';
import { getOrigin } from 'utils';

type FetchDataResponse = {
	response: any;
	loading: boolean;
	fetchData: any;
};

const useFetchWithToken = (): FetchDataResponse => {
	const [response, setResponse] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { apiEndPoint: API_HOST } = useSharedVariables();

	const fetchData = async (url: string, token: string): Promise<void> => {
		setLoading(true);
		try {
			const resp = await fetch(API_HOST + url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
					Domain: getOrigin() ?? '',
				},
			});
			const data = await resp.json();
			setResponse(data);
			return data;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	return { response, loading, fetchData };
};

export default useFetchWithToken;
