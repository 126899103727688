import { useCallback } from 'react';
import { Button } from '@storybook/button';

import { OfflineSvg } from './image/offline';
import { useSharedVariables } from 'hooks';
import './offline.scss';

export const Offline = () => {
	const { windowReload } = useSharedVariables();

	const onClickHandler = useCallback(() => {
		windowReload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Offline__container">
			<div
				className="Offline__image"
				dangerouslySetInnerHTML={{
					__html: OfflineSvg,
				}}
			/>

			<p className="Offline__text">You’re offline</p>
			<p className="Offline__desc">
				Your internet connection in unavailable. Please check your data or wifi
				connection.
			</p>
			<Button
				label="Retry"
				handleClick={onClickHandler}
				type=" button__filled button__filled--primary button__large button__block Offline__retrybtn "
			/>
		</div>
	);
};
