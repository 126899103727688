import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Image, SkeltonLoader } from '@storybook';

import { CustomLoaderState } from 'states';
import { KycLoaderState } from 'views/kyc/stores';

export const useCustomLoader = () => {
	// const global state
	const customLoader = useRecoilValue(CustomLoaderState);
	const kycLoaders = useRecoilValue(KycLoaderState);

	const localStorageData = localStorage.getItem('session-loader');
	const localObject = localStorageData && JSON.parse(localStorageData);

	const { loader, whitelabel, loaderWhitelabel } = useMemo(
		() => customLoader ?? localObject ?? {},
		[customLoader, localObject]
	);

	const customLoaderElement = useMemo(() => {
		if (whitelabel === false) {
			return <Image className="gif-loader" fileName="loader.gif" />;
		} else if (loaderWhitelabel === false) {
			if (kycLoaders) {
				return <Image className="gif-loader" fileName="SimpliciLoder2.gif" />;
			} else {
				return (
					<div
						style={{ width: 62, height: 62 }}
						className={`loader loader-blue`}
					/>
				);
			}
		} else if (loaderWhitelabel === true && loader) {
			// Extract the file extension from the URL
			const fileExtension =
				loader.split('?')?.[0]?.split('.')?.pop()?.toLowerCase() ?? '';

			return fileExtension === 'mp4' ? (
				<video
					src={loader}
					className="custom-gif-loader"
					controls={false}
					autoPlay
					muted // Mute the video to ensure autoplay works
					loop
					style={{ objectFit: 'contain' }}
				/>
			) : (
				<Image
					className="custom-gif-loader"
					url={loader}
					style={{ objectFit: 'contain' }}
				/>
			);
		}
		return <SkeltonLoader height={'180px'} width={'180px'} />;
	}, [kycLoaders, loader, loaderWhitelabel, whitelabel]);

	return {
		customLoaderElement,
	};
};
