export const convertToCurrencySystem = (
	amount: string | number,
	decimal: number = 2
): string => {
	const numAmount = Math.abs(Number(amount));

	// Handle invalid inputs
	if (isNaN(numAmount)) {
		throw new Error(
			'Invalid amount: Input must be a number or a numeric string.'
		);
	}

	// Determine the output based on the value of numAmount
	if (numAmount >= 1.0e9) {
		return numAmount / 1.0e9 + 'B';
	} else if (numAmount >= 1.0e6) {
		return numAmount / 1.0e6 + 'M';
	} else if (numAmount >= 1.0e3) {
		return numAmount / 1.0e3 + 'K';
	} else {
		// Apply toFixed only if the number is a decimal
		return numAmount % 1 !== 0
			? numAmount.toFixed(decimal)
			: numAmount.toString();
	}
};

export const isNumber = (number: string) => {
	const number_regex = /^(?:\d+(?:.\d*)?|.\d+)?$/;
	const isValid = number_regex.test(number);
	if (isValid) {
		return true;
	} else {
		return false;
	}
};
