import { useCallback } from 'react';

import { CHALLENGE } from './constants';
import { IRegisteredCredentials, IUserInfo } from './type';
import {
	binToStr,
	getRegistrations,
	saveNewCredentials,
	strToBin,
} from './utils';

export const useWebAuthentication = () => {
	const registerNewCredentials = useCallback(
		async (callback: any, userInfo: IUserInfo) => {
			try {
				const enc = new TextEncoder();
				const publicKey: PublicKeyCredentialCreationOptions = {
					rp: { name: window.location.host ?? 'secure.satschel.com' },
					user: {
						name: userInfo.email ?? '',
						id: enc.encode(userInfo.id ?? ''),
						displayName: userInfo.displayName ?? '',
					},
					pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
					challenge: enc.encode(CHALLENGE),
					authenticatorSelection: { userVerification: 'required' },
				};
				const res = (await navigator.credentials.create({
					publicKey: publicKey,
				})) as PublicKeyCredential;

				const payload = {
					rawId: binToStr(res?.rawId),
					id: res.id,
					name: userInfo.email ?? '',
					userId: userInfo.id,
					displayName: userInfo.displayName ?? '',
				};

				saveNewCredentials(payload);
				callback();
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('create webauthn error', { error });
				callback();
			}
		},

		[]
	);

	const registeredCredentials = () => {
		return getRegistrations().map((reg: IRegisteredCredentials) => ({
			id: strToBin(reg.rawId),
			type: 'public-key',
			transports: ['internal'],
		}));
	};

	const authenticate = useCallback(async (onSuccess: any, onFailed: any) => {
		try {
			const enc = new TextEncoder();

			const publicKey = {
				challenge: enc.encode(CHALLENGE),
				allowCredentials: registeredCredentials(),
			};
			// browser receives the publicKey object and passes it to WebAuthn "get" API
			const res = (await navigator.credentials.get({
				publicKey: publicKey,
			})) as PublicKeyCredential;

			// here we build an object containing the results, to be sent to the server
			// usually "extractedData" is POSTed to your server
			const extractedData = {
				id: res.id,
				rawId: binToStr(res?.rawId),
				clientDataJSON: binToStr(res.response?.clientDataJSON),
			};

			// Usually done on the server, this is where you make your auth checks
			// here for DEMO purposes only
			const dataFromClient = JSON.parse(atob(extractedData.clientDataJSON));
			const retrievedChallenge = atob(dataFromClient.challenge);

			// At MINIMUM, your auth checks should be:
			// 1. Check that the retrieved challenge matches the auth challenge you sent to the client, as we do trivially below
			// 2. Check that "retrievedOrigin" matches your domain - otherwise this might be a phish - not shown here

			if (retrievedChallenge === CHALLENGE) {
				onSuccess();
			} else {
				onFailed();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('get webauthn error', { error });
			onSuccess();
		}
	}, []);

	return { authenticate, registerNewCredentials, getRegistrations };
};
