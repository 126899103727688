import { useMemo, useState } from 'react';

import { CheckDetailsForm, Scan } from './components';
import { BodyWrapper, LabelElement } from 'components';
import { ComponentMetaData } from './constants';
import { IScreen } from './stores';

import './check-defense.scss';

// Define a type for the possible screens in the component

// CheckDefense component
export const CheckDefense = () => {
	// State to manage the current screen

	const [screen, setScreen] = useState<IScreen>('empty-scan');

	// useMemo to calculate the component, label, sublabel, and description based on the current screen
	const { component, label, sublabel, description } = useMemo(() => {
		switch (screen) {
			case 'form':
				return {
					...ComponentMetaData[screen],
					// CheckDetailsForm component for collecting check details
					component: (
						<CheckDetailsForm handleStep={(e: IScreen) => setScreen(e)} />
					),
				};
			case 'empty-scan':
				return {
					...ComponentMetaData[screen],
					// Scan component for capturing an empty check scan
					component: (
						<Scan
							type="empty"
							handleSuccess={(e?: IScreen) => setScreen(e ?? 'upload-scan')}
						/>
					),
				};
			case 'upload-scan':
				return {
					...ComponentMetaData[screen],
					// Scan component for uploading a check scan
					component: (
						<Scan type="upload" handleStep={(e: IScreen) => setScreen(e)} />
					),
				};
			case 'uploaded-scan':
				return {
					...ComponentMetaData[screen],
					// Scan component for displaying an uploaded check scan
					component: (
						<Scan type="uploaded" handleStep={(e: IScreen) => setScreen(e)} />
					),
				};
			default:
				// Default case for handling any unexpected screen value
				return {
					...ComponentMetaData['empty-scan'],
					// CheckDetailsForm component as a fallback
					component: (
						<Scan type="empty" handleSuccess={() => setScreen('upload-scan')} />
					),
				};
		}
	}, [screen]);

	// Render the BodyWrapper component with label, headerElement, and bodyElement
	return (
		<BodyWrapper
			label={<LabelElement text={label} />}
			headerElement={
				<div className="cd-wrapper__header">
					<div className="cd-wrapper__header__title">{sublabel}</div>
					<div className="cd-wrapper__header__sub-title">{description}</div>
				</div>
			}
			bodyElement={<div className="cd-wrapper">{component}</div>}
		/>
	);
};
