import { useCallback } from 'react';
import { Button, Image } from '@storybook';
import './internet-connection-issue.scss';
import { InternetConnectionText } from './hooks';

export const InternetConnectionIssue = () => {
	// Function for reload the page
	const reloadPage = useCallback(() => {
		location.reload();
	}, []);

	return (
		<div className="InternetConnctionIssue">
			<div className="InternetConnctionIssue__wrapper">
				<Image fileName="hardWareSettings.svg" />
				<p className="InternetConnctionIssue__wrapper-heading">
					{InternetConnectionText.Header}
				</p>
				<p className="InternetConnctionIssue__wrapper-subheading">
					{InternetConnectionText.SubHeader}
				</p>
				<Button
					label="Retry"
					handleClick={reloadPage}
					type="button__filled button__filled--primary button__large "
				/>
			</div>
		</div>
	);
};
