import { atom } from 'recoil';

import { IWebAuthDetails } from './type';

export const WebAuthUserDetails = atom<IWebAuthDetails>({
	key: 'webAuthUserDetails',
	default: {
		name: '',
		email: '',
		id: '',
		phone: '',
	},
});
