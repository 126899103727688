export const BreadCrumbLabel = {
	KYC: 'KYC',
	AML: 'AML',
	'Sign Agreement': 'Sign',
	'Fund Pay In or Payout': 'Fund Invest.',
	Accreditation: 'Accreditation',
	'Success Screen': 'Success',
	// Shahbaaz: change label a/c to admin label
	Form: 'Questionnaire', // change the key
	KYB: 'KYB',
	'Proof Reading': 'Document Review',
};

export const BreadCrumbLabelForFund = {
	payIn: 'Fund Account',
	payOut: 'Fund Account',
};
