import { Button, Loader } from '@storybook';
import { useCallback, useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import {
	KybBodyStepsState,
	SearchKybCompanyInputState,
	SearchKybCompanyMembersInputState,
	TheKybSearchIntervalState,
} from 'views/kyb/stores';

export const LoadingScreen = () => {
	const setkybBodyStep = useSetRecoilState(KybBodyStepsState);
	const setTheKybSearchInterval = useSetRecoilState(TheKybSearchIntervalState);
	const resetSearchKybCompanyMemberInput = useResetRecoilState(
		SearchKybCompanyMembersInputState
	);
	const resetSearchKybCompanyInput = useResetRecoilState(
		SearchKybCompanyInputState
	);

	const handleCancelButton = useCallback(() => {
		setTheKybSearchInterval(true);
		setkybBodyStep('buisness_verification');
	}, [setTheKybSearchInterval, setkybBodyStep]);

	useEffect(() => {
		resetSearchKybCompanyInput();
		resetSearchKybCompanyMemberInput();
	}, [resetSearchKybCompanyInput, resetSearchKybCompanyMemberInput]);

	return (
		<div className="kyb-loader">
			<div>
				<Loader type="loader" dimension={42} />
			</div>
			<div className="kyb-list__loader-title">Please wait</div>
			<div className="kyb-list__loader-desp">
				We are fetching business/company details.
			</div>
			<Button
				label={'Cancel'}
				handleClick={() => handleCancelButton()}
				type="button__filled button__filled--secondary button__large "
			></Button>
		</div>
	);
};
