import { LinkExpiredMessage } from './constant';
import './link-expired.scss';
import { Image } from '@storybook';

export const LinkExpired = () => {
	return (
		<div className="LinkExpired--container">
			<div className="LinkExpired--container__image-content">
				<Image
					fileName="Link_break.svg"
					className="LinkExpired--container__image"
				/>
			</div>
			<p className="LinkExpired--container__text-bold">
				{LinkExpiredMessage.Header}
			</p>
			<p className="LinkExpired--container__text-regular">
				{LinkExpiredMessage.SubHeader}
			</p>
		</div>
	);
};
