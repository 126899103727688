export const LABEL: { [key: string]: string } = {
	allId: 'KYC',
	amlVerification: 'AML',
	signAgreementVerification: 'Sign Agreement',
	fundInvestmentVerification: 'Fund Investment ',
	payOut: 'Fund Payout',
	payIn: 'Fund Investment',
	accreditationVerify: 'Accreditation',
	kybVerfication: 'KYB',
};
