export async function retryOperation(
	operation: () => Promise<any>,
	maxRetries: number,
	delayMs: number = 0
): Promise<any> {
	let attempt = 0;
	while (attempt < maxRetries) {
		const result = await operation();
		try {
			if (result?.statusCode === 200) return result;
			// Check if the 'result.message' contains both the words "hold" and "investment"
			if (/^(?=.*\bhold\b)(?=.*\binvestment\b).+$/.test(result?.message)) {
				// If both words are found, return the 'result' object
				return result;
			}
			// Retry only if status code is 0
			if (/^(0|500)$/.test(String(result?.statusCode))) {
				attempt++;
				// eslint-disable-next-line no-console
				console.log('Attempt:', attempt); // Logging attempt for debugging

				// Wait for the specified delay before retrying
				if (delayMs > 0) {
					await new Promise(resolve => setTimeout(resolve, delayMs));
				}
			} else {
				// If status code is not 0, break out of the loop and return the result
				return result;
			}
		} catch {
			return result;
		}
	}
}
