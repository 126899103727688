export function removeEmptyKeys(obj: { [key: string]: any }): {
	[key: string]: any;
} {
	const result: { [key: string]: any } = {};

	for (const key in obj) {
		if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
			const nestedObj = removeEmptyKeys(obj[key]);
			if (Object.keys(nestedObj).length > 0) {
				result[key] = nestedObj;
			}
		} else if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
			result[key] = obj[key];
		}
	}

	return result;
}
