import { atom } from 'recoil';
import { IRenderObject } from 'states';
import { CountryStateList } from 'views/kyb/stores';

interface IVouchedCameraOpenState {
	isOpen: boolean;
	docType: boolean;
	isSuccess: boolean;
}

export interface IVouchedCamera {
	isOpen: boolean;
	setIsGlare: React.Dispatch<React.SetStateAction<boolean>>;
	setCurrentImage: React.Dispatch<React.SetStateAction<string>>;
	setRenderedObj: React.Dispatch<React.SetStateAction<IRenderObject>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setGlareErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

export const formPropertiesState = atom<any>({
	key: 'form-properties-state-key',
	default: {
		frontImage: null,
		backImage: null,
		selfie: null,
		btnDisabled: true,
	},
});

export const selectedDocTypeState = atom<any>({
	key: 'selected-doc-type-state-key',
	default: '',
});

export const vouchedCameraOpenState = atom<IVouchedCameraOpenState>({
	key: 'vouched-camera-open-state-key',
	default: { isOpen: false, docType: false, isSuccess: false },
});

export const kycRenderedObj = atom<IRenderObject>({
	key: 'kyc-rendered-obj-key',
	default: {
		label: '',
		title: '',
		subtitle: '',
		settings: [],
	},
});

export const kycIsLiveness = atom<boolean>({
	key: 'kyc-is-liveness-key',
	default: false,
});

export const kycActiveStepName = atom<string>({
	key: 'kyc-active-stepName-key',
	default: '',
});

export const KycDocIsGlare = atom<boolean>({
	key: 'kyc-doc-is-glare-key',
	default: false,
});
export const KycGlareErrors = atom<string[]>({
	key: 'kyc-glare-errors-key',
	default: [],
});

export const KyCurrentImage = atom<string>({
	key: 'kyc-current-image-key',
	default: '',
});

export const KycIsImageSubmit = atom<boolean>({
	key: 'kyc-is-image-submit-key',
	default: true,
});

export const KycSelectedCountry = atom<CountryStateList>({
	key: 'kyc-selected-country-key',
	default: {
		name: '',
		states: [],
		iso3: '',
		iso2: '',
		phone_code: '',
		currency: '',
		currency_name: '',
		emoji: '',
		currency_symbol: '',
		documents: [],
		kyb: { type: '', country: false, states: false }, // add this
	},
});

export const ShowKycSelectCountry = atom<boolean>({
	key: 'show-kyc-select-country-key',
	default: true,
});

export const SelectedKycDocType = atom<any>({
	key: 'selected-kyc-doc-type',
	default: {},
});
