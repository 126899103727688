import { CSSProperties, useMemo } from 'react';

import './radio-card-selection.scss';

interface ICardValues {
	id: string | number;
	label: string;
	[key: string]: string | number | JSX.Element;
}

interface IRadioCardSelection {
	/**
	 * this Props used to set default card selected Value
	 */
	checkedValue: ICardValues;
	/**
	 * cardValues props accept Array of Object where id and label is mendatorry Eg [{id:1 , label:"first"}]
	 */
	cardValues: ICardValues[];
	/**
	 * this props accept function
	 */
	handleOnClick: any;
	/**
	 * this props Accept style for card box
	 */
	customStyle?: CSSProperties;
	/**
	 * this props will add class in your wrapper to override other css properties;
	 */
	className?: string;
}

/**
 * Wrap a standard radio input within a label
 * Within this label, just after the input type="radio", now add a simple div styled as a card
 * @param IRadioCardSelection React Props
 */

export const RadioCardSelection = ({
	checkedValue,
	cardValues,
	handleOnClick,
	customStyle,
	className,
}: IRadioCardSelection) => {
	const getComponent = useMemo(
		() => (
			<div className={`radio-card ${className ?? ''}`}>
				{cardValues.map((item, index: number) => (
					<div
						className={`radio-card__inner ${className ?? ''}`}
						onClick={() => handleOnClick(item)}
						key={`${index.toString()}_${item.id}`}
					>
						<label htmlFor={`${index}_${item?.id}`} className="radio-card">
							<div className="card-content-wrapper" style={customStyle}>
								<input
									type="radio"
									name="radio-input"
									id={`${index}_${item.id}`}
									defaultChecked={item.id === checkedValue?.id ?? false}
								/>
								<div className="card-content">{item?.label}</div>
							</div>
						</label>
						{item?.customComponent && (
							<div
								className="radio-card__custom-component"
								onClick={e => e.stopPropagation()}
							>
								{item.customComponent}
							</div>
						)}
					</div>
				))}
			</div>
		),
		[cardValues, checkedValue?.id, className, customStyle, handleOnClick]
	);

	if (cardValues.length === 0) return <div></div>;

	return <div>{getComponent}</div>;
};
