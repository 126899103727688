import { atom } from 'recoil';
import { IOptionType } from 'views/kyb/stores';

export type IScreenRoutes =
	| 'liveness-information'
	| 'facial-enroll'
	| 'selfie-screen';

export const activeScreenState = atom<IScreenRoutes>({
	key: 'active-screen-state-key',
	default: 'liveness-information',
});

export const IsFaceMeshLoadedState = atom<boolean>({
	key: 'is-face-modal-setup-state-key',
	default: false,
});

export const isRetakeCameraState = atom<boolean>({
	key: 'is-retake-camera-state-key',
	default: false,
});

export const isWebRtcConnectionEstablish = atom<boolean>({
	key: 'is-webRtc-connection-state-key',
	default: false,
});

export const mediaRecorder = atom<MediaRecorder | null>({
	key: 'media-recorder-state-key',
	default: null,
});

export const recordedChunks = atom<Blob[]>({
	key: 'recorded-chunks-state-key',
	default: [],
});

export const isRecording = atom<boolean>({
	key: 'is-recording-state-key',
	default: false,
});

export const isCameraPermission = atom<boolean | null>({
	key: 'is-camera-permission-key',
	default: null,
});

export const cameraPermissionDisplayMessage = atom<string>({
	key: 'camera-permission-display-message-key',
	default: '',
});

export const selectedDeviceIdState = atom<string>({
	key: 'selected-device-state-key',
	default: '',
});

export const selectedCameraLabel = atom<string>({
	key: 'selected-camera-label-state-key',
	default: 'front',
});

export const CameraOptions = atom<IOptionType[]>({
	key: 'camera-option-state-key',
	default: [],
});

export const videoRefAtom = atom<HTMLVideoElement | null>({
	key: 'video-ref-state-key', // unique ID (with respect to other atoms/selectors)
	default: null, // default value (aka initial value)
});
