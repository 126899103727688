import { atom } from 'recoil';
import { ICurrentUrl } from './type';

export const SignAgreementUrlState = atom<ICurrentUrl>({
	key: 'sign-agreement-url',
	default: {
		index: 0,
		url: undefined,
	},
});

export const AllSignAgreementUrlState = atom<string[]>({
	key: 'all-sign-agreement-url',
	default: [],
});

export const IsSignAgreementCurrentUrlLoaded = atom<boolean>({
	key: 'sign-current-signed-url-loading',
	default: false,
});
