import { atom } from 'recoil';
import { IinviteUserPayload } from './types';

export const AppleUserDetailsState = atom<any>({
	key: 'apple-user-details-state',
	default: {
		_id: null,
		firstName: null,
		lastName: null,
		email: null,
		phone: null,
		countryCode: null,
	},
});

interface iBasicInfoData {
	firstName: string | undefined;
	lastName: string;
	email: string;
}

export const BasicInfoDataState = atom<iBasicInfoData>({
	key: 'basic-info-data-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
	},
});

export const BasicInformationState = atom<IinviteUserPayload | null>({
	key: 'basic-information-state',
	default: null,
});

export const SessionExistState = atom<boolean>({
	key: 'is-session-exist',
	default: false,
});

export const FlowTypeState = atom<string>({
	key: 'flow-type-state',
	default: 'linear',
});

export const PreviousTokenState = atom<string>({
	key: 'previous-token-state-key',
	default: '',
});

export const AppleResponseToken = atom<{
	response: {
		authorization: {
			code: string;
			id_token: string;
		};
	};
}>({
	key: 'appple-response-token',
	default: {
		response: {
			authorization: {
				code: '',
				id_token: '',
			},
		},
	},
});
