import { useCallback, useMemo, useRef } from 'react';

import { useNextStep, useSharedVariables, useTokenSession } from 'hooks';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AccessTokenState, isShowSkipState } from 'states';

export const useAMLRequests = () => {
	const onCount = useRef(0);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const { sessionPayloadDetail, handleNext, setSessionDetails } = useNextStep();
	const { onboardingType } = useSharedVariables();

	const { stepsId, _id, sessionId, userId, currentAction } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const { postTokenSession } = useTokenSession();

	const submitAml = useCallback(async () => {
		const payload: any = {
			pipelineId: _id ?? '',
			userId: userId ?? '',
			actions: [
				{
					id: 'amlVerification',
					data: {},
				},
			],
		};
		if (onboardingType === 'complex') {
			payload.nodeId = currentAction._id;
			// APIS.COMPLEX_SESSION patch api
			const res = await postTokenSession({ payload, code: sessionCode });

			if (res.statusCode === 200) {
				const amlResponse = { ...res };
				delete amlResponse.statusCode;
				setSessionDetails(prev => ({
					...prev,
					nodes: amlResponse,
				}));
			}
			return;
		}
		payload.stepId = 'aml';
		//KYC_SESSION aml api
		const res = await await postTokenSession({ payload, code: sessionCode });
		if (res.statusCode === 200) {
			handleNext();
		} else {
			setIsShowSkip(true);
		}
	}, [
		_id,
		currentAction._id,
		handleNext,
		onboardingType,
		postTokenSession,
		sessionCode,
		setIsShowSkip,
		setSessionDetails,
		userId,
	]);

	const amlRequest = async () => {
		if (
			stepsId === 'aml' &&
			onCount.current === 0 &&
			_id &&
			(sessionId || _id)
		) {
			onCount.current += 1;
			submitAml();
		}
	};
	return { amlRequest };
};
