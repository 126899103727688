import { useMemo } from 'react';
import './better-photo-tips.scss';
import { instructionUploadImg } from 'views/kyc/constants';

export const BetterPhotoTips = ({ content }: any) => {
	const { TIPS, ...TipsMessage } = useMemo(
		() => (content ? content : instructionUploadImg),
		[content]
	);
	return (
		<div className="BetterPhotoTips__tips-container">
			<div className="BetterPhotoTips__tips-container__tips-title">
				<i className="ri-flashlight-fill BetterPhotoTips__tips-container__tips-icon"></i>
				{TIPS}
			</div>
			<ul className="BetterPhotoTips__tips-container__tips-list">
				{Object.keys(TipsMessage).map(key => (
					<li key={key}>{TipsMessage[key as keyof typeof TipsMessage]}</li>
				))}
			</ul>
		</div>
	);
};
