import { Button, Loader } from '@storybook';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SUBSIDIARY_BUSINESS_CONSTANTS } from 'views/kyb/constants';

import {
	ISubsidiariesBusiness,
	SelectedSubsidiaryBusinessDetailState,
	SubsidiaryBusinessListState,
	SubsidiaryBusinessLoadingState,
	TheKybSubmitLoadingState,
} from 'views/kyb/stores';
import { useKYBRequests } from 'views/kyb/stores/hooks';

export const SelectSubsidiary = () => {
	// global states
	const subsidiaryBusinessList = useRecoilValue(SubsidiaryBusinessListState);
	const setSelectSubsidiaryBusiness = useSetRecoilState(
		SelectedSubsidiaryBusinessDetailState
	);
	const isSubsidiariesLoading = useRecoilValue(SubsidiaryBusinessLoadingState);
	const isTheKybSubmitLoading = useRecoilValue(TheKybSubmitLoadingState);

	// local states
	const [filterBusinessList, setFilterBusinessList] = useState<
		ISubsidiariesBusiness[]
	>([]);
	const [searchTerm, setSearchTerm] = useState('');

	// hooks
	const { submitKyb } = useKYBRequests();

	// constants
	const {
		Header_Title,
		Header_Description,
		Fetching_Subsidiaries_Text,
		No_Subsidiaries_Message,
		Not_Find_Linked_Subsidiaries_Text,
	} = SUBSIDIARY_BUSINESS_CONSTANTS;

	// Clears selected subsidiary business on component mount
	useEffect(() => {
		setSelectSubsidiaryBusiness([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// checking if a company has any linked subsidiaries or not
	const hasLinkedSubsidiaries = useMemo(() => {
		return !isSubsidiariesLoading && !subsidiaryBusinessList.length;
	}, [isSubsidiariesLoading, subsidiaryBusinessList.length]);

	// Handles search input change
	const handleSearch = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const term = event.target.value;
			setSearchTerm(term);
			const filtered = subsidiaryBusinessList.filter(
				business =>
					(business.TIN?.includes(term) ?? false) ||
					(business.name?.toLowerCase()?.includes(term.toLowerCase()) ?? false)
			);
			setFilterBusinessList(filtered);
		},
		[subsidiaryBusinessList]
	);

	// Handles checkbox selection of subsidiary businesses
	const handleCheckBox = useCallback(
		(company: ISubsidiariesBusiness) => {
			setSelectSubsidiaryBusiness(prev => {
				const selectedCompany = prev.some(item => item.id === company.id);
				if (selectedCompany) {
					return prev.filter(item => item.id !== company.id);
				}
				return [...prev, company];
			});
		},
		[setSelectSubsidiaryBusiness]
	);

	// Handles submission of KYB form
	const handleSubmit = useCallback(async () => {
		await submitKyb({}, [], true);
	}, [submitKyb]);

	// Determines content to render based on loading state and search results
	const renderContentBody = useMemo(() => {
		let content;
		const businessList =
			searchTerm.length >= 1 ? filterBusinessList : subsidiaryBusinessList;

		switch (true) {
			case isSubsidiariesLoading:
				content = (
					<div className="select-subsidiary__loader">
						<div>
							<Loader type="loader" dimension={42} />
						</div>
						<div className="select-subsidiary__loader__title">Please wait</div>
						<div className="select-subsidiary__loader__desp">
							{Fetching_Subsidiaries_Text}
						</div>
					</div>
				);
				break;
			case !isSubsidiariesLoading && !subsidiaryBusinessList?.length:
				content = (
					<div className="select-subsidiary__loader">
						<i className="select-subsidiary__loader__no-data-icon ri-error-warning-fill" />
						<div className="select-subsidiary__loader__no-data-desp">
							{Not_Find_Linked_Subsidiaries_Text}
						</div>
					</div>
				);
				break;
			case searchTerm?.length >= 1 && !filterBusinessList?.length:
				content = (
					<div className="select-subsidiary__loader">
						<i className="select-subsidiary__loader__no-data-icon ri-error-warning-fill" />
						<div className="select-subsidiary__loader__no-data-desp">
							{No_Subsidiaries_Message}
						</div>
					</div>
				);
				setSelectSubsidiaryBusiness([]);
				break;
			default:
				content = businessList?.map(company => (
					<div className="select-subsidiary__wrapper" key={company?.id}>
						<div className="select-subsidiary__header">
							<input
								className="select-subsidiary__header__checkbox"
								type="checkbox"
								onClick={() => handleCheckBox(company)}
							/>
							<span className="select-subsidiary__header__title">
								{company?.name || 'N/A'}
							</span>
						</div>
						<div className="select-subsidiary__content">
							<div className="select-subsidiary__content__wrapper">
								<span className="select-subsidiary__content__label">
									TIN/EIN
								</span>
								<span className="select-subsidiary__content__colon">:</span>
								<span className="select-subsidiary__content__value">
									{company?.TIN || 'N/A'}
								</span>
							</div>
							<div className="select-subsidiary__content__wrapper">
								<span className="select-subsidiary__content__label">
									Address
								</span>
								<span className="select-subsidiary__content__colon">:</span>
								<span className="select-subsidiary__content__value">
									{(company?.Address as string) || 'N/A'}
								</span>
							</div>
						</div>
					</div>
				));
				break;
		}
		return content;
	}, [
		searchTerm.length,
		filterBusinessList,
		subsidiaryBusinessList,
		isSubsidiariesLoading,
		Fetching_Subsidiaries_Text,
		Not_Find_Linked_Subsidiaries_Text,
		No_Subsidiaries_Message,
		setSelectSubsidiaryBusiness,
		handleCheckBox,
	]);

	// Renders search input and content body
	const renderBodyElements = useMemo(() => {
		return (
			<>
				<div className="select-subsidiary__header-wrapper">
					<div className="kyb-details__header representative-header">
						<div className="kyb-details__header__title">{Header_Title}</div>
						<div className="kyb-details__header__sub-title">
							{Header_Description}
						</div>
					</div>
					{!hasLinkedSubsidiaries && !isSubsidiariesLoading && (
						<div className="select-subsidiary__search-wrapper">
							<i className="select-subsidiary__search-wrapper__search-icon ri-search-line" />
							<input
								className="select-subsidiary__search-wrapper__search-bar"
								type="text"
								placeholder="Search by TIN/EIN or Company Name"
								value={searchTerm}
								onChange={handleSearch}
							/>
						</div>
					)}
				</div>
			</>
		);
	}, [
		Header_Description,
		Header_Title,
		handleSearch,
		hasLinkedSubsidiaries,
		isSubsidiariesLoading,
		searchTerm,
	]);

	// Determines if submit button should be disabled
	const isSubmitButtonDisable = useMemo(() => {
		if (hasLinkedSubsidiaries) {
			return false;
		}
		return isSubsidiariesLoading;
	}, [hasLinkedSubsidiaries, isSubsidiariesLoading]);

	return (
		<>
			<div className="select-subsidiary__component-header">
				{renderBodyElements}
			</div>
			<div className="select-subsidiary__cards-body">
				<div className="select-subsidiary__cards-body__cards-component">
					{renderContentBody}
				</div>
				<div className="kyb-responsive__button-sticky">
					<Button
						label={
							isTheKybSubmitLoading ? (
								<Loader type="circle" dimension={26} />
							) : (
								'Submit'
							)
						}
						handleClick={handleSubmit}
						disabled={isSubmitButtonDisable || isTheKybSubmitLoading}
						type="button__filled button__filled--primary button__large button__block mt-2 process-button__size "
					/>
				</div>
			</div>
		</>
	);
};
