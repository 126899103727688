import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BodyNameKeyType } from 'states';

import {
	selectedDocTypeState,
	vouchedCameraOpenState,
	ShowKycSelectCountry,
} from './store';
import { IndentityVerificationObject } from 'views/kyc/constants';
import {
	ChooseVerification,
	DocumentVerification,
	KycSelectCountry,
} from './components';

export const DocumentVerificationWrapper = () => {
	const [bodyName, setBodyName] = useRecoilState(selectedDocTypeState);
	const setOpenVouchedCamera = useSetRecoilState(vouchedCameraOpenState);
	const showKycSelectCountry = useRecoilValue(ShowKycSelectCountry);

	const handleOnClickId = useCallback(
		(key: BodyNameKeyType) => {
			setBodyName(key);
			if (key === IndentityVerificationObject[1]?.key) {
				setOpenVouchedCamera(pre => ({ ...pre, docType: true }));
			} else {
				setOpenVouchedCamera(pre => ({ ...pre, docType: false }));
			}
		},
		[setBodyName, setOpenVouchedCamera]
	);

	const getComponent = useMemo(() => {
		if (!bodyName && showKycSelectCountry) {
			return <KycSelectCountry />;
		} else if (!bodyName) {
			return <ChooseVerification handleOnClickId={handleOnClickId} />;
		} else {
			return (
				<DocumentVerification bodyName={bodyName} setBodyName={setBodyName} />
			);
		}
	}, [bodyName, setBodyName, handleOnClickId, showKycSelectCountry]);

	return getComponent;
};
