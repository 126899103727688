import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Input } from '@storybook';

import { validateEmail, validateName } from 'utils';
import {
	AccreditedByVerifierInputErrorState,
	AccreditedByVerifierState,
	SelectedItemComponentForAccreditationState,
} from './stores';
import { CUSTOM_FORM_INPUTS, SIGNATORY_USER } from './constants';

import './custom-form.scss';

interface ICustomMLCForm {
	componentKey: string;
}

export const CustomMLCForm: FC<ICustomMLCForm> = ({ componentKey }) => {
	// globle state
	const [accreditedByVerifier, setAccreditedByVerifier] = useRecoilState(
		AccreditedByVerifierState
	);
	const [inputError, setInputError] = useRecoilState(
		AccreditedByVerifierInputErrorState
	);

	const [selectedItemComponent] = useRecoilState(
		SelectedItemComponentForAccreditationState
	);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value, name } = e.target;
			setInputError(prevState => {
				const componentData = prevState[componentKey] || {
					firstName: '',
					lastName: '',
					email: '',
				};
				return {
					...prevState,
					[componentKey]: {
						...componentData,
						[name]: '',
					},
				};
			});
			setAccreditedByVerifier(prevState => {
				const componentData = prevState[componentKey] || {
					firstName: '',
					lastName: '',
					email: '',
				};
				return {
					...prevState,
					[componentKey]: {
						...componentData,
						[name]: value,
					},
				};
			});
		},
		[componentKey, setAccreditedByVerifier, setInputError]
	);

	const handleBlur = useCallback(
		(name: string) => {
			const inputValue =
				accreditedByVerifier?.[componentKey]?.[name]?.trim() ?? '';
			const isValidName = validateName(inputValue);
			const isValidEmail = !validateEmail(inputValue);
			const isNameFieldInvalid =
				(name === SIGNATORY_USER.First_Name ||
					name === SIGNATORY_USER.Last_Name) &&
				!isValidName;
			const isEmailFieldInvalid =
				name === SIGNATORY_USER.Email && !isValidEmail;

			const showError =
				inputValue && (isNameFieldInvalid || isEmailFieldInvalid);
			if (showError) {
				setInputError(prevState => {
					const componentData = prevState[componentKey] || {
						firstName: '',
						lastName: '',
						email: '',
					};
					return {
						...prevState,
						[componentKey]: {
							...componentData,
							[name]: `Invalid ${SIGNATORY_USER[name] ?? name}`,
						},
					};
				});
			}
		},
		[accreditedByVerifier, componentKey, setInputError]
	);

	const isDisable = useMemo(
		() => componentKey !== selectedItemComponent?.key,
		[componentKey, selectedItemComponent]
	);

	const value = useCallback(
		(name: string) => {
			if (isDisable) return '';
			const verifierData = accreditedByVerifier?.[componentKey];
			return verifierData?.[name] ?? '';
		},
		[accreditedByVerifier, componentKey, isDisable]
	);

	const errorMessage = useCallback(
		(name: string) => {
			if (isDisable) return '';
			const errorData = inputError?.[componentKey];
			return errorData?.[name] ?? '';
		},
		[componentKey, inputError, isDisable]
	);

	return (
		<div className={`CustomInputWrapper`}>
			{CUSTOM_FORM_INPUTS.map(({ type, title, placeholder, name }) => (
				<Input
					label={title}
					inputName={name}
					inputType={type as 'text'}
					placeholder={placeholder}
					handleChange={handleChange}
					key={name}
					height={'fit-content'}
					isRequired
					value={value(name)}
					handleBlur={() => handleBlur(name)}
					isError={!!errorMessage(name)}
					errorMessage={errorMessage(name)}
					disabled={isDisable}
				/>
			))}
		</div>
	);
};

export const getCustomComponent = (key: string) => (
	<CustomMLCForm componentKey={key} />
);
