export const rotateImage = (base64: string, cb: any) => {
	//creating new Instance for Image
	const img = new Image();
	img.src = base64;
	img.onload = () => {
		const canvas = document.createElement('canvas');
		canvas.width = img.height;
		canvas.height = img.width;
		canvas.style.position = 'absolute';
		const ctx = canvas.getContext('2d');
		ctx?.translate(img.height, img.width / img.height);
		ctx?.rotate(Math.PI / 2);
		ctx?.drawImage(img, 0, 0);
		cb(canvas.toDataURL('image/jpeg', 1));
	};
};
