import { FC } from 'react';
import DatePicker from 'react-date-picker';

interface IDateCalender {
	className?: string;
	value?: Date;
	onChange?: any;
}

export const DateCalender: FC<IDateCalender> = ({
	className,
	value,
	onChange,
}) => {
	return (
		<>
			<DatePicker
				onChange={onChange}
				value={value}
				maxDate={new Date()}
				format="dd/MM/yyyy"
				className={className}
				clearIcon={null}
			/>
		</>
	);
};
