/**
 * ImageProcess is used to get various page post metrics from instagrams page/insights api
 * @param image unix start date value (Instagram uses PST timezone)
 * @param width unix end date value (Instagram uses PST timezone) default value 1000
 * @param height string array of valid instagram page post metrics/edges
 * @param quality limits the number of media objects retrieved
 */

export async function ImageProcess(
	image: string,
	width = 1000,
	height = 1000,
	quality = 95
): Promise<string> {
	const Jimp = (window as any).Jimp;
	try {
		return await new Promise((resolve, reject) => {
			// split image as we will have to remove base 64 mime type
			const splitedImage = image.split(',');
			// remove first index from splited Image
			const transformedImage = splitedImage.slice(1).join();
			const buf = Buffer.from(transformedImage, 'base64');
			Jimp.read(buf)
				.then((photo: any) => {
					if (photo.getWidth() < 1000) {
						return resolve(photo.getBase64Async(Jimp.MIME_JPEG));
					}
					return photo
						.scaleToFit(width, height)
						.quality(quality)
						.getBase64(Jimp.MIME_JPEG, function (_: any, src: string) {
							if (src) return resolve(src);
							reject('Something went Wrong');
						});
				})
				.catch(() => {
					reject('Something went wrong');
				});
		});
	} catch (error) {
		return image;
	}
}

export async function convertUrlToBase64(imageUrl: string): Promise<string> {
	const Jimp = (window as any).Jimp;
	const image = await Jimp.read(imageUrl);
	const imageMime = image._originalMime;
	const buffer = await image.getBufferAsync(imageMime); // or imageMime for jpeg format
	return `data:${imageMime};base64,` + buffer.toString('base64');
}
