import { FC } from 'react';

import './label-element.scss';

interface ILableElement {
	text?: string;
	backBtn?: JSX.Element;
	skipBtn?: JSX.Element;
}
export const LabelElement: FC<ILableElement> = ({ text, backBtn, skipBtn }) => {
	return (
		<div className="label-container">
			<div className="label-container__text">
				{backBtn} {text ?? ''}
			</div>
			{skipBtn}
		</div>
	);
};
