import { REACT_APP_API_HOST as API_HOST } from 'envs';

const host = () => {
	return window.location.origin + '/';
};
export const envUrl = host();

export const __siteMetaData: any = {
	stage: {
		endPoint: 'https://api.stage.satschel.com/v2',
		appUrl: 'https://secure.stage.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	pp: {
		endPoint: 'https://api.pp.satschel.com/v2',
		appUrl: 'https://secure.pp.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	beta: {
		endPoint: 'https://api.beta.satschel.com/v2',
		appUrl: 'https://secure.beta.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	sandbox: {
		endPoint: 'https://api.beta.satschel.com/v2',
		appUrl: 'https://secure.beta.satschel.com',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
	prod: {
		endPoint: 'https://api.satschel.com/v2',
		appUrl: 'https://secure.simplici.io',
		glareUrl: 'https://api.vouched.anandesh.dev/api/glare',
	},
};

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isProd = /api.simplici.io/gi.test(API_HOST);
const isBeta = /beta/gi.test(API_HOST);
export type IEnvironmentHost = 'nonStage' | 'stage' | 'pp' | 'prod' | 'beta';

const environmentHost = (): IEnvironmentHost => {
	if (isStage) return 'stage';
	if (isPP) return 'pp';
	if (isProd) return 'prod';
	if (isBeta) return 'beta';
	return 'stage';
};

export const envHost: any = environmentHost();
