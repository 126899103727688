import cn from 'classnames';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button, Loader } from '@storybook';
import { BackIcon, BodyWrapper, LabelElement } from 'components';
import { LoginPhoneNumberState } from 'states';
import { REGEX_ACCEPT_ONLY_NUMBER, useOtpVerify } from './stores';

import { onlyNumber } from 'utils';
import './otp-verify.scss';

export interface IOtpObject {
	otp1: string;
	otp2: string;
	otp3: string;
	otp4: string;
}

const initialOtpValue: IOtpObject = {
	otp1: '',
	otp2: '',
	otp3: '',
	otp4: '',
};

interface IOtpVerifyProps {
	otpInputRef: React.RefObject<HTMLInputElement>;
	moveOtpScreen: () => void;
	isOtpScreen?: boolean;
}

export const OtpVerify: React.FC<IOtpVerifyProps> = ({
	otpInputRef,
	moveOtpScreen,
	isOtpScreen = false,
}) => {
	// Global states
	const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);

	// Local states
	const [otpValue, setOtpValue] = useState<IOtpObject>(initialOtpValue);
	const [focusEvent, setFocusEvent] = useState<any>(null);
	const [isVerifying, setVerifying] = useState(false);
	const isOtpVerifying = useRef(false);
	const [isResending, setResending] = useState(false);

	// Hooks
	const { verify, getOtp } = useOtpVerify();
	const defaultInputRef = useRef<HTMLInputElement>(null);

	const { phone: phoneNumber, countryCode } = loginPhoneNumber || {};
	const { otp1, otp2, otp3, otp4 } = otpValue;
	const isInvalidOtp = !(otp1 && otp2 && otp3 && otp4);

	const otpLength = useMemo(
		() => Object.values(otpValue).filter(el => el !== '').length,
		[otpValue]
	);

	const handleSubmitOtp = useCallback(async () => {
		if (isOtpVerifying.current) {
			return;
		}
		isOtpVerifying.current = true;
		const otp = !isInvalidOtp && otp1 + otp2 + otp3 + otp4;
		if (phoneNumber && countryCode && otp) {
			setVerifying(true);
			const response = await verify(otp);
			isOtpVerifying.current = false;
			if (!response) {
				focusEvent?.target?.form?.elements[otpLength]?.focus();
				setVerifying(false);
			}
		}
	}, [
		countryCode,
		focusEvent?.target?.form?.elements,
		isInvalidOtp,
		otp1,
		otp2,
		otp3,
		otp4,
		otpLength,
		phoneNumber,
		verify,
	]);

	const handleOtpChange = useCallback(
		(name: keyof IOtpObject, e: ChangeEvent<HTMLInputElement>) => {
			e.preventDefault();
			const { value } = e.target;
			if (value.length > 1) return;
			const newValue = onlyNumber(value) ? value : '';
			setOtpValue(prev => ({ ...prev, [name]: newValue }));
		},
		[]
	);

	const handleResend = async () => {
		if (isResending || !phoneNumber || !countryCode) return;
		setResending(true);
		setOtpValue(initialOtpValue);
		await getOtp();
		setResending(false);
	};

	const handleInputFocus = useCallback(
		(event: any) => {
			if (
				event.target instanceof HTMLInputElement &&
				(event.target.value === '' ||
					REGEX_ACCEPT_ONLY_NUMBER.test(event.target.value))
			) {
				if (event.key === 'Delete' || event.key === 'Backspace') {
					const nextIndex = event.target.tabIndex - 2;
					if (nextIndex > -1) {
						event.target.form?.elements[nextIndex].focus();
					}
				} else if (REGEX_ACCEPT_ONLY_NUMBER.test(event.target.value)) {
					setFocusEvent(event);
					const nextIndex = event.target.tabIndex;
					if (nextIndex < 4) {
						event.target.form?.elements[otpLength].focus();
					} else {
						handleSubmitOtp();
					}
				}
			}
		},
		[handleSubmitOtp, otpLength]
	);

	const handleModifyDetails = () => {
		setOtpValue(initialOtpValue);
		moveOtpScreen();
	};

	const getRightValue = useCallback((str: string) => {
		const changedValue = str;
		return Number(changedValue) >= 0 ? changedValue : '';
	}, []);

	const handlePaste = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			e.preventDefault();
			const pastedData = e.clipboardData
				.getData('text/plain')
				.trim()
				.slice(0, 4)
				.split('');
			if (pastedData) {
				Object.entries(otpValue).forEach(([key, value], index) => {
					if (index >= 0) {
						const changedValue = getRightValue(pastedData.shift() || value);
						if (changedValue) {
							setOtpValue(prev => ({ ...prev, [key]: changedValue }));
						}
					}
				});
			}
		},
		[getRightValue, otpValue]
	);

	const renderedOtpInputs = useMemo(() => {
		return Object.entries(otpValue).map(([key, value], index) => (
			<input
				ref={index === 0 ? otpInputRef : defaultInputRef}
				type="tel"
				key={key}
				id={key}
				className={'OtpVerify--otpInput'}
				name={key}
				value={value}
				onChange={e => handleOtpChange(key as keyof IOtpObject, e)}
				onKeyUp={handleInputFocus}
				maxLength={1}
				minLength={1}
				tabIndex={index + 1}
				onFocus={e => e.target.select()}
				autoFocus={index === 0}
				onPaste={handlePaste}
			/>
		));
	}, [handlePaste, handleInputFocus, handleOtpChange, otpInputRef, otpValue]);

	const renderedMainComponent = useMemo(
		() => (
			<form className={'OtpVerify--form'}>
				<div className={'OtpVerify--otp'}>
					<div className={'OtpVerify--otpDetail'}>
						Enter The Verification Code
					</div>
					<div className={'OtpVerify--edit'}>
						Enter the OTP sent to : {countryCode} {phoneNumber}
						<div
							className={'OtpVerify--edit__editBtn'}
							onClick={handleModifyDetails}
						>
							Edit
						</div>
					</div>
					<div className={'OtpVerify--container'}>
						<div className={'OtpVerify--line'}>{renderedOtpInputs}</div>
					</div>
					<div className="OtpVerify--resend">
						{"Didn't receive OTP ?"}
						<div
							onClick={handleResend}
							className={`OtpVerify--btn ${isResending ? 'OtpVerify--disabled' : 'OtpVerify--resend-btn'}`}
						>
							Resend OTP
						</div>
					</div>
					<div
						className={`OtpVerify--verifyButton ${isOtpScreen ? 'OtpVerify--submit-btn' : ''}`}
					>
						<Button
							type={cn('buttons', 'OtpVerify--verifyButton__submit')}
							handleClick={handleSubmitOtp}
							disabled={isInvalidOtp || isVerifying}
							label={
								isVerifying ? <Loader type="circle" dimension={20} /> : 'Verify'
							}
						/>
					</div>
				</div>
			</form>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			countryCode,
			phoneNumber,
			renderedOtpInputs,
			isResending,
			isInvalidOtp,
			isVerifying,
		]
	);

	return (
		<BodyWrapper
			optionalClassName="terms-body-wrapper"
			label={
				<div className="OtpVerify--formHeader">
					<BackIcon handleGoBack={handleModifyDetails} />
					<LabelElement text="OTP Verification" />
				</div>
			}
			bodyElement={<>{renderedMainComponent}</>}
		/>
	);
};
