import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useNextStep } from 'hooks';
import { BreadCrumbLabel, BreadCrumbLabelForFund } from './constant';
import { BY_PSSS_URL } from 'views/constants';

import './bread-crumb.scss';

interface IBreadcrumb {
	ExtraSteps?: any;
	data?: any;
	currentIndex?: number;
}

export const Breadcrumb: FC<IBreadcrumb> = ({
	ExtraSteps,
	data = [],
	currentIndex,
}) => {
	const [onBoardingKeys, setOnBoardingKeys] = useState<any>(data);

	const { sessionDetails, currentStep } = useNextStep();

	const isAuthentication = sessionDetails?.steps?.find(
		item => item.stepId === 'authentication'
	);

	//adding extrastep as no need to show authentication steps in breadcrumb as well as no need to show T&C page for kyc in Admin Panel
	const overrideExtraSteps: { stepId: string }[] = BY_PSSS_URL.includes(
		document.referrer
	)
		? []
		: [...ExtraSteps];

	if (isAuthentication) {
		overrideExtraSteps.push({ stepId: 'authentication' });
	}

	useEffect(() => {
		const keys: string[] = [];
		if (data.length === 0) {
			sessionDetails?.steps
				?.filter(
					(item: any) =>
						!overrideExtraSteps
							?.map((items: any) => items?.stepId)
							?.includes(item.stepId)
				)
				?.forEach((item: any, index: number) => {
					if (item.stepId !== 'basic-information') {
						const data: any = {};
						data.text = item.label;
						data.status = index === 0 ? 'active' : 'pending';
						data.index = index;
						if (item.stepId === 'fundInvestment') {
							data.type = item.actions[0]?.metadata?.payInPayOut;
						}
						keys.push(data);
					}
				});
			setOnBoardingKeys(keys);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.length, sessionDetails?.steps]);

	const getLabel = useCallback((item: { text: string; type?: string }) => {
		if (item.type) {
			return (BreadCrumbLabelForFund as any)[item.type];
		} else {
			return (BreadCrumbLabel as any)[item.text];
		}
	}, []);

	const getComponent = useMemo(() => {
		return (
			<div className="breadcrumb" id="breadcrumb">
				{(onBoardingKeys ?? data)?.map((item: any, index: number) => (
					<div
						className={
							(item.index ===
							currentStep - (currentIndex ?? overrideExtraSteps?.length ?? 0)
								? item.text === 'Success Screen'
									? 'completed'
									: 'active'
								: item.index <
									  currentStep -
											(currentIndex ?? overrideExtraSteps?.length ?? 0)
									? 'completed'
									: 'pending') + ' breadcrumb-list'
						}
						key={`${item.tex}__${index.toString()}`}
					>
						<span className="breadcrumb__inner">
							<span className="breadcrumb__desc">{getLabel(item)}</span>
						</span>
					</div>
				))}
			</div>
		);
	}, [
		onBoardingKeys,
		data,
		currentStep,
		currentIndex,
		overrideExtraSteps?.length,
		getLabel,
	]);

	return getComponent;
};
