import { useState, useEffect, useMemo } from 'react';

export const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const isMobile = useMemo(() => width <= 768, [width]);

	return { width, isMobile };
};
