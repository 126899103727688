import { FC } from 'react';

import './skelton-loader.scss';

interface ISkelton {
	width?: string;
	height?: string;
	shape?: string;
	skeltonBgColor?: string;
	effectColor?: string;
}

export const SkeltonLoader: FC<ISkelton> = ({ width, height, shape }) => {
	return (
		<div
			className="skelton-loader-wrapper"
			style={{ width: width ?? '300px', height: height ?? 200 }}
		>
			<div className={`skelton-loader-wrapper__content ${shape ?? ''}`}></div>
		</div>
	);
};
