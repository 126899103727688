import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { KycSteps } from 'constants/common';
import { CheckDefense } from 'views/check-defense';
import { ExistingSessionsState, useExistingSession } from 'views/exist-session';
import {
	AddressDetails,
	DocumentVerificationWrapper,
	KycLoader,
	ProvideSSNNumber,
} from './components';
import {
	AddressNotFetchedState,
	IDMeritStepState,
	IsBackupKycState,
	KycLoaderState,
	useKycRequests,
} from './stores';

import './kyc.scss';

export const KYC = () => {
	//globle states
	const [currentStep, setCurrentStep] = useRecoilState(IDMeritStepState);
	const setIsKYCExist = useSetRecoilState(ExistingSessionsState);
	const isAddressNotFetched = useRecoilValue(AddressNotFetchedState);

	// local states
	const [isBackupKYC] = useRecoilState(IsBackupKycState);
	const kycLoaders = useRecoilValue(KycLoaderState);

	// hooks
	const { getExistingId } = useExistingSession();
	const { currentAction } = useKycRequests();

	useEffect(() => {
		if (getExistingId('kyc')) {
			setIsKYCExist(pre => ({ ...pre, kyc: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getExistingId]);

	useEffect(() => {
		if (currentAction?.metadata?.SSN ?? currentAction?.metadata?.ssn) {
			setCurrentStep(KycSteps.Ssn!);
		} else if (
			currentAction?.metadata?.addressVerification &&
			isAddressNotFetched
		) {
			setCurrentStep(KycSteps.Address!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getComponent = useMemo(() => {
		if (kycLoaders) {
			return <KycLoader />;
		}
		if (isBackupKYC) {
			if (currentStep === KycSteps.Ssn) return <ProvideSSNNumber />;
			if (currentStep === KycSteps.Address) return <AddressDetails />;
			if (currentStep === KycSteps.CheckScan) return <CheckDefense />;
			return <KycLoader />;
		}
		if (currentStep !== KycSteps.Ssn) {
			return <DocumentVerificationWrapper />;
		}
		return null;
	}, [isBackupKYC, kycLoaders, currentStep]);

	return getComponent;
};
