export type IInvestorObjKey =
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'countryCode'
	| 'phoneNumber'
	| 'shareOwned';

export const INVESTOR_OBJ_KEY_CONST: {
	[key in Uppercase<IInvestorObjKey>]: IInvestorObjKey;
} = {
	COUNTRYCODE: 'countryCode',
	EMAIL: 'email',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	PHONENUMBER: 'phoneNumber',
	SHAREOWNED: 'shareOwned',
};

export const INVESTOR_OBJ = {
	firstName: '',
	lastName: '',
	email: '',
	countryCode: '',
	phoneNumber: '',
	shareOwned: 0,
};

export const INVESTOR_OBJ_ITEM = {
	firstName: {
		value: '',
		error: '',
	},
	lastName: {
		value: '',
		error: '',
	},
	email: {
		value: '',
		error: '',
	},
	countryCode: {
		value: '+1',
		error: '',
	},
	phoneNumber: {
		value: '',
		error: '',
	},
	shareOwned: {
		value: 0,
		error: '',
	},
	disableRow: false,
};

export type IInvestorItems =
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'countryCode'
	| 'phoneNumber'
	| 'shareOwned';

export type IInvestorDetails = {
	[key in IInvestorItems]: { value: string | number; error: string };
};

export interface IInvestor extends IInvestorDetails {
	disableRow: boolean;
}

export const NOTIFICATION_MESSAGE = {
	INVALID_FIRST_NAME: 'The first name cannot be less than 3 characters.',
	INVALID_LAST_NAME: 'The last name cannot be less than 3 characters.',
	INVALID_EMAIL: 'Invalid email Id',
	INVALID_CODE: 'Please Enter valid country code with +',
	INVALID_PHONE_NUMBER: 'Invalid phone number',
	INVALID_RANGE: '% owned can not be more than 100% and less than 0%',
	INVALID_ORDER:
		'Shareholders/Owners should be arranged in descending order of share ownership',
	FIX_ERRORS:
		'Please correct any errors in the Shareholders/Owners table or ensure that all fields are filled.',
	MIN_SUM_LIMIT: 'Total shares owned cannot be less than',
	MAX_SUM_LIMIT: 'Total shares owned cannot exceed 100%.',
	EMPTY_INVESTORS: 'The investors list cannot be empty.',
	INVESTOR_SORTED: 'Shareholder sorted: highest shares owned first.',
};

export const INVESTORS_TYPE_STRING = 'investors-detail';
