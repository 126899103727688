import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	formPropertiesState,
	kycIsLiveness,
	kycRenderedObj,
} from '../../store';
import { UseVerification } from '../../hooks/use-verification';
import { Button } from '@storybook';
import { IsKycLoading } from 'views/kyc/stores';
import { IFormProperties } from 'states';

export const KycDocUploadFooter = (bodyName: any) => {
	const renderedObj = useRecoilValue(kycRenderedObj);
	const isLiveness = useRecoilValue(kycIsLiveness);
	const setFormProperties =
		useSetRecoilState<IFormProperties>(formPropertiesState);

	const isLoading = useRecoilValue(IsKycLoading);
	const { methods, states } = UseVerification(bodyName);
	const { getDisabled, onClickButton, handleActiveScreen } = methods;
	const { cameraScreenActive } = states;

	const { settings } = useMemo(() => renderedObj ?? {}, [renderedObj]);

	return (
		<div className="ppl-screen-body__body__button-wrapper ">
			{settings
				?.filter(({ label }) => label !== 'Back')
				.map(({ className, label, step }, index: number) => {
					const isBack = label === 'Back';
					const isActiveBtn = label !== 'Back' ? getDisabled(step) : false;
					const getButtonLabel =
						step === 'step4' && !isLiveness ? 'Submit' : label;
					return (
						label !== 'Next' && (
							<div
								className={`ppl-screen-body__body__button-wrapper__btn ${
									isBack
										? 'ppl-screen-body__body__button-wrapper__Back-btn'
										: ''
								}   ${
									!cameraScreenActive
										? 'ppl-screen-body__body__button-wrapper__Back-btn_margin'
										: ''
								}`}
								key={`${getButtonLabel}__${index.toString()}`}
							>
								<Button
									label={<>{getButtonLabel}</>}
									handleClick={() => {
										if (step === 'step2') {
											setFormProperties(content => {
												const data = { ...content };
												data.backImage = null;
												return data;
											});
										}
										if (/submit/g.test(getButtonLabel)) {
											onClickButton();
										} else {
											handleActiveScreen(step);
										}
									}}
									type={`${className} ${label === 'Back' ? ' ' : ''}`}
									disabled={isActiveBtn || isLoading}
								/>
							</div>
						)
					);
				})}
		</div>
	);
};
