import { FC } from 'react';
import { Button, Image } from '@storybook';

import './investment-not-allowed.scss';

interface IInvestmentNotAllowed {
	handleBack: () => void;
	handleClose?: () => void;
	organizationName?: string;
}

export const InvestmentNotAllowed: FC<IInvestmentNotAllowed> = ({
	handleBack,
	handleClose,
	organizationName,
}) => {
	return (
		<div className="InvestmentNotAllowed__container">
			<div className="InvestmentNotAllowed__content">
				<div className="InvestmentNotAllowed__icon">
					<Image
						fileName="investment-not-allowed.svg"
						className="InvestmentNotAllowed__img"
					/>
				</div>
				<h2>Investment Not Available</h2>
				<p>
					Sorry but at this time this investment is only for accredited
					investors. Please check back later or stay up to date with{' '}
					{organizationName ?? 'Morning Line Club'} as to when you can invest.
				</p>
				<div className="button-wrapper">
					{handleClose && (
						<Button
							label={'Close'}
							type="button button__filled button__filled--primary button__large button__block"
							handleClick={handleClose}
						/>
					)}
					<Button
						label={'Back'}
						type="button button__filled button__filled--secondary button__large button__block"
						handleClick={handleBack}
					/>
				</div>
			</div>
		</div>
	);
};
