export const isValidURL = (str: string) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

export const urlPath = () => {
	const { href, origin } = window.location;
	return href.replace(origin, '');
};

export const isCompanyLinkedInURLValid = (str: string) => {
	const regex =
		/^https?:\/\/(?:www\.)?(?:(?:[a-z]{2,3}\.)?linkedin\.com\/company|in\.linkedin\.com\/pub)\/[\w-#!:.?+=&%@!/'()]+$/i;
	const res = regex.test(str);
	if (res) return true;
	else return false;
};

export const formatBase64 = (base64String: string) => {
	return base64String.replace(/^data:[^;]+;base64,/, '');
};

export const onlyNumber = (val: string) => {
	const numericRegex = /^[0-9]+$/;
	if (numericRegex.test(val)) return true;
	else return false;
};

export const getOrigin = () => {
	try {
		const ancestorOriginsLength =
			Object.values(document?.location?.ancestorOrigins ?? {})?.length ?? 0;
		const val =
			window.location === window.parent.location
				? document.location.origin
				: document.location.ancestorOrigins?.[ancestorOriginsLength - 1];
		return val ?? 'https://secure.simplici.io';
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('getOrigin', err);
		return 'https://secure.simplici.io';
	}
};

export const getHost = (url: string) => {
	const newUrl = new URL(url);
	return newUrl.host;
};

export const getParentHost = () => {
	const domain = getOrigin();
	const host = getHost(domain as string);
	const hostParts = host?.split('.');
	const mainDomain = hostParts?.[hostParts?.length - 2];
	const domainExtantion = hostParts?.[hostParts.length - 1];
	return mainDomain ? mainDomain + '.' + domainExtantion : domainExtantion;
};

export const getOriginProtocol = () => {
	const domain = getOrigin();
	const newUrl = new URL(domain as string);
	return newUrl?.protocol ?? 'https:';
};

export const paramsToObject = (entries: any, ignoreKeys?: string[]) => {
	const result: Record<string, string> = {};
	for (const [key, value] of entries) {
		// each 'entry' is a [key, value] tupple
		if (!ignoreKeys?.includes(key)) {
			result[key] = value;
		}
	}
	return result;
};
