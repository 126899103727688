export const WebRTC_SERVER_URL =
	'https://api.stage.satschel.com/v2/rocket/offer';

export const CameraPermisionsErrors = {
	Not_Readable_Error: 'NotReadableError',
	Device_in_use: 'Device in use',
	Camera_settings_OFF: 'Camera settings OFF',
	Camera_time_out: 'Camera time out',
};

export const CameraSettingsOffMessages = {
	Heading: 'Camera access required',
	subHeading:
		'It looks like your camera is not enabled, or we do not have permission to access it.',
	instructionOne:
		'Ensure your camera is physically connected(in case of external webcam) and turned on.',
	instructionTwo: 'open your browser settings and allow camera permissions.',
};

export const CameraInUseMessages = {
	Heading: 'Camera in use',
	subHeading:
		'The camera is currently being used in another tab, window, or application. Please close any other applications using the camera and try again.',
};

export const CameraHardwareMessages = {
	Heading: 'Hardware issue',
	subHeading:
		'We are unable to connect to the camera at the moment. This could be due to hardware not being connected or another issue. Please check your hardware connection and try again.',
};

export const CameraTimeOutMessages = {
	Heading: 'Internet connection issue',
	subHeading:
		'The camera connection is currently unavailable, web pages are slow to load, streaming videos are buffering, and downloads are delayed; please try again.',
};
