import { useEffect, useMemo } from 'react';

import { useNextStep } from 'hooks';
import { ErrorPage } from 'components/error-page';

import { useFundInvestmentRequests } from './stores';
import { PayOut, PayIn } from './pages';

import './fund-investment.scss';

export const FundInvestment = () => {
	const { sessionPayloadDetail } = useNextStep();
	const { fetchBankDetails } = useFundInvestmentRequests();

	const type = (sessionPayloadDetail as any).currentAction.metadata
		?.payInPayOut;

	useEffect(() => {
		fetchBankDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderComponent = useMemo(() => {
		switch (type) {
			case 'payOut':
				return <PayOut />;
			case 'payIn':
				return <PayIn />;
			default:
				return <ErrorPage status={500} />;
		}
	}, [type]);

	return <div className="accred-wrapper fund-wrapper">{renderComponent}</div>;
};
