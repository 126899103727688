import type { ICurrentUrl } from 'states/kyc-verification';

import { atom } from 'recoil';

export const AllSignDocumentUrlState = atom<string[]>({
	key: 'all-sign-document-url',
	default: [],
});

export const IsCurrentUrlLoaded = atom<boolean>({
	key: 'current-signed-url-loading',
	default: false,
});

export const SignDocumentUrlState = atom<ICurrentUrl>({
	key: 'sign-document-url',
	default: {
		index: 0,
		url: undefined,
	},
});

export const CurrentStep = atom({
	key: 'current-step',
	default: 'term-condition',
});
