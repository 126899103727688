import './description.scss';

interface ITitle {
	description: string | JSX.Element;
	className?: string;
}

export const Description = ({ description, className = '' }: ITitle) => {
	if (!description) {
		return <></>;
	}
	const descriptionClass = `kyc-events-description ${className}`;

	return <div className={descriptionClass}>{description}</div>;
};
