import { useCallback } from 'react';

import {
	SurveyMatrixColumnName,
	SurveyQuestionName,
	SurveyQuestionType,
} from './constants';

// Define types for the form data structure
interface IMatrixElement {
	name: string;
	type: string;
	rows: Array<{ value: string }>; // Rows represent the matrix dropdown rows
	columns: Array<{ name: string }>; // Columns represent the matrix dropdown columns
}

interface IPage {
	elements: IMatrixElement[]; // Each page contains multiple matrix elements
}

interface IRepresentative {
	nationalIdNumber?: { value: string }; // Optional national ID number
	designation?: { value: string }; // Optional designation (title/role)
	[columnName: string]: { value: string } | undefined; // Dynamic properties for any additional representative data
}

interface IFormData {
	pages: IPage[]; // Form data is split into pages with elements
	representatives: IRepresentative[]; // List of representatives with associated details
}

interface ISurveyDataRow {
	[columnName: string]: string; // Survey data row where each key is a column name
}

interface ISurveyData {
	[questionName: string]:
		| {
				[rowValue: string]: ISurveyDataRow;
		  }
		| any; // Survey data keyed by question names and row values
}

// Hook to fetch and transform survey data
export const useSurveyData = () => {
	// Function to process and fetch survey data for representatives
	const fetchSurveyDataForRepresentatives = useCallback(
		(formData: IFormData): ISurveyData => {
			// Destructure pages and representatives from formData, ensuring fallback for undefined formData
			const { pages, representatives } = formData ?? {};

			// Filter matrix dropdown elements from the pages that are either beneficialOwner or authorizedSigner
			const filteredMatrixData =
				pages?.flatMap(
					(page: IPage) =>
						page?.elements?.filter(
							element =>
								element?.type === SurveyQuestionType.MATRIX_DROPDOWN &&
								[
									SurveyQuestionName.BENEFICIAL_OWNER,
									SurveyQuestionName.AUTHORIZED_SIGNER,
								].includes(element.name)
						) ?? []
				) ?? [];

			// Validate that representatives exist and matrix data is present
			if (
				!Array.isArray(representatives) || // Ensure representatives is a valid array
				!representatives.length || // Ensure representatives list is not empty
				!Object.keys(representatives[0] ?? {}).length || // Ensure at least one representative has data
				!filteredMatrixData.length // Ensure there are relevant matrix data questions
			) {
				return {}; // Return empty data if any condition fails
			}

			const newData: ISurveyData = {}; // Initialize the new survey data structure

			// Helper function to format date strings
			const formatDate = (inputDate: string) => {
				let date;

				switch (true) {
					// Case 1: Handles YYYY/MM/DD or YYYY-MM-DD format
					case inputDate.includes('/') || inputDate.includes('-'):
						date = new Date(inputDate);
						break;

					// Case 2: Handles 8-digit numeric formats
					case inputDate.length === 8 && !isNaN(Number(inputDate)): {
						const firstPart = inputDate.substring(0, 4);

						// Sub-case 2a: Handles YYYYMMDD (first 4 digits are year)
						if (parseInt(firstPart, 10) > 1900) {
							const year = inputDate.substring(0, 4);
							const month = inputDate.substring(4, 6);
							const day = inputDate.substring(6, 8);
							date = new Date(`${year}-${month}-${day}`);
						}
						// Sub-case 2b: Handles DDMMYYYY (first 2 digits are day)
						else {
							const day = inputDate.substring(0, 2);
							const month = inputDate.substring(2, 4);
							const year = inputDate.substring(4, 8);
							date = new Date(`${year}-${month}-${day}`);
						}
						break;
					}

					// Case 3: Default case, returns input as-is if no match
					default:
						return inputDate;
				}

				// Check if the date is valid
				if (isNaN(date.getTime())) {
					return inputDate; // Return the original input if the date is invalid
				}

				// Format the date into DD/MM/YYYY
				const formattedDay = String(date.getDate()).padStart(2, '0');
				const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // getMonth is zero-based
				const year = date.getFullYear();

				return `${formattedDay}/${formattedMonth}/${year}`;
			};

			// Helper function to retrieve the value from a representative's data based on the column name
			const getValue = (columnName: string, index: number): string => {
				const representativeData = representatives[index]; // Get representative data by index
				if (!representativeData) return ''; // Return empty string if no representative data is found

				const { SSN, ID_NUMBER, TITLE, DESIGNATION, DOB, DATE_OF_BIRTH } =
					SurveyMatrixColumnName;

				// Handle specific cases for SSN, ID_NUMBER, TITLE, DESIGNATION, DOB
				switch (columnName) {
					case SSN:
					case ID_NUMBER:
						return representativeData.nationalIdNumber?.value?.trim() ?? '';
					case TITLE:
					case DESIGNATION:
						return representativeData.designation?.value?.trim() ?? '';
					case DOB:
					case DATE_OF_BIRTH:
						return formatDate(
							representativeData[columnName]?.value?.trim() ?? ''
						);
					default:
						return representativeData[columnName]?.value?.trim() ?? ''; // Return value for dynamic fields
				}
			};

			// Loop through each filtered matrix element (question)
			filteredMatrixData.forEach((question: IMatrixElement) => {
				const questionName = question.name; // Get the question name

				// Initialize newData[questionName] if it doesn't exist
				newData[questionName] = {};

				// Loop through each row of the matrix
				question.rows.forEach((row: { value: string }, index: number) => {
					const rowValue = row.value; // Get the row's value

					// Initialize newData[questionName][rowValue] if it doesn't exist
					newData[questionName][rowValue] = {};

					// Loop through each column of the matrix
					question.columns.forEach((column: { name: string }) => {
						const columnName = column.name; // Get the column name

						// Assign the appropriate value from the representative's data
						newData[questionName][rowValue][columnName] = getValue(
							columnName,
							index
						);
					});
				});
			});

			return newData; // Return the final transformed survey data
		},
		[]
	);

	// Public function to fetch survey data, wrapping the internal function
	return {
		fetchSurveyData: (formData: IFormData): ISurveyData => ({
			...fetchSurveyDataForRepresentatives(formData), // Merge the processed survey data
		}),
	};
};
