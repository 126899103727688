import './title.scss';

interface ITitle {
	title: string | JSX.Element;
	className?: string;
}

export const Title = ({ title, className = '' }: ITitle) => {
	if (!title) {
		return <></>;
	}
	const titleClass = `kyc-events-title ${className}`;

	return <div className={titleClass}>{title}</div>;
};
