import React from 'react';

// eslint-disable-next-line react/display-name
export const CanvasWrapper = React.memo(() => {
	return (
		<canvas
			className="output_canvas"
			id="output_canvas"
			style={{
				transform: 'rotateY(180deg)',
				WebkitTransform: 'rotateY(180deg)',
			}}
		></canvas>
	);
});
