import type { ICountries, ICountryCode } from '.';

import { FC, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Image } from '@storybook/image';

import countries from './json/country-codes.json';

import './country-code.scss';

export const CountryCode: FC<ICountryCode> = ({
	handleChangeCountry,
	countryCode = '+1',
	optionsClassName = '',
	className = '',
	isDisabled = false,
}) => {
	const [country, setCountry] = useState<ICountries>();

	const customStyles = {
		control: (styles: any) => ({
			...styles,
			cursor: 'pointer',
			border: 'none',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			'&:hover': {
				border: 'none',
				borderRadius: '5px',
				boxShadow: 'none',
			},
		}),
		menu: (styles: any) => ({
			...styles,
			width: '350px', // Make the dropdown menu 100% wide
			'@media only screen and (max-width: 430px)': {
				width: '330px',
			},
		}),
	};

	useEffect(() => {
		const countryObj = countries.find(item => item.label === countryCode);
		if (countryObj && countryObj.label !== country?.label) {
			setCountry(countryObj);
		}
	}, [countryCode, country]);

	const handleCountryChange = (selectedOption: ICountries | null) => {
		if (selectedOption) {
			setCountry(selectedOption);
			handleChangeCountry(selectedOption);
		}
	};

	// Custom SingleValue component to show only the flag and country code after selection
	const SingleValue = (props: any) => {
		return (
			<components.SingleValue {...props}>
				<div className={`option ${optionsClassName}`}>
					<div className="skeleton-image-loader-wrapper">
						<Image className="option__flag" url={props.data.image} />
						<div className="skeleton-image-loader"></div>
					</div>
					<span className="option__label">{props.data.label}</span>
				</div>
			</components.SingleValue>
		);
	};

	return country ? (
		<Select
			defaultValue={country}
			styles={customStyles}
			formatOptionLabel={(countryData: ICountries) => {
				const isSelected = countryData.label === country.label;
				return (
					<div className={`option ${optionsClassName}`}>
						<div className="skeleton-image-loader-wrapper">
							<Image className="option__flag" url={countryData.image} />
							<div className="skeleton-image-loader"></div>
						</div>
						<span className="option__label">
							{countryData.value}
							<span className={!isSelected ? 'option__label-code' : ''}>
								{countryData.label}
							</span>
						</span>
					</div>
				);
			}}
			className={`country-label ${className}`}
			options={countries}
			getOptionValue={(option: ICountries) => option.value}
			onChange={handleCountryChange}
			isSearchable
			isDisabled={isDisabled}
			value={country}
			components={{
				SingleValue,
				IndicatorSeparator: () => null,
			}}
			maxMenuHeight={140}
		/>
	) : (
		<></>
	);
};
