type IMessageType = 'FILE_LIMIT_EXCEEDS_MESSAGE';

export const MESSAGE: { [key in Uppercase<IMessageType>]: string } = {
	FILE_LIMIT_EXCEEDS_MESSAGE: 'File size exceeds the maximum limit.',
};

export const max_FileSize = 10000000;

export const ProfessionalLicenseVerification =
	'Professional License Verification';

export const FinraBrokerCheckUrl = 'https://brokercheck.finra.org/';
