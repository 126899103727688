import { useCallback } from 'react';
import { useNotification } from 'hooks';

let isToasterShown = false;

export const useConnectionSpeedCheck = () => {
	const { warningNotification } = useNotification();

	const checkInternetSpeed = useCallback(() => {
		const startTime = new Date().getTime();
		const image = new Image();

		const imageUrl =
			'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png';

		image.onload = () => {
			const endTime = new Date().getTime();
			const duration = (endTime - startTime) / 1000; // time in seconds

			// Image size in bits (assuming 24 bits per pixel)
			const imageSize = 272 * 92 * 24;
			const speedMbps = parseFloat(
				(imageSize / (duration * 1024 * 1024)).toFixed(2)
			);

			// Check speed and show warning if below threshold
			if (speedMbps < 0.05) {
				if (!isToasterShown) {
					isToasterShown = true;
					warningNotification(
						'Your internet is a bit slow, which may cause delays in the next step.'
					);
				}
			} else {
				isToasterShown = false; // Reset toaster flag if speed improves
			}
		};

		// Start downloading the image
		image.src = imageUrl + '?cache=' + Math.random(); // Prevent caching
	}, [warningNotification]);

	// Return the function to be used in useEffect
	return checkInternetSpeed;
};
