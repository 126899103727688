import { Loader } from '@storybook';

import './please-wait.scss';

export const PleaseWait = () => {
	return (
		<div className="please-wait-wrapper">
			<div className="please-wait-wrapper__inner">
				<Loader />
				<div>Please wait...</div>
			</div>
		</div>
	);
};
