import { FC, useMemo } from 'react';

import { IHeader } from '../stores';
import {
	FinraBrokerCheckUrl,
	ProfessionalLicenseVerification,
} from './constants';

export const Header: FC<IHeader> = ({ title, subTitle }) => {
	const verifyProfessionalLicense = useMemo(() => {
		if (title !== ProfessionalLicenseVerification) {
			return null;
		}
		return (
			<a href={FinraBrokerCheckUrl} target="_blank" rel="noopener noreferrer">
				Finra&apos;s brokercheck.
			</a>
		);
	}, [title]);

	return (
		<div className="accred-header">
			{title && <div className="accred-header__title">{title}</div>}
			{subTitle && (
				<div className="accred-header__sub-title">
					{subTitle}
					{verifyProfessionalLicense}
				</div>
			)}
		</div>
	);
};
