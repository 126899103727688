import { FC, useCallback, useMemo } from 'react';
import { Button, Input } from '@storybook';

import {
	finraLisenceDropDownDefaultValue,
	finraLisenceDropValue,
} from '../constants';
import { SelectComponent } from './select-component';

import './input-form.scss';

interface IInputForm {
	settings: any;
	handleNext: any;
	handleBack: VoidFunction;
	handleChange?: any;
	isDisabled?: boolean;
	setChecked?: any;
	formData?: any;
	skipChoosePage?: boolean;
}
export const InputForm: FC<IInputForm> = ({
	settings,
	handleNext,
	handleBack,
	handleChange,
	isDisabled = false,
	setChecked,
	formData,
	skipChoosePage = false,
}) => {
	const renderInputs = useMemo(
		() =>
			// eslint-disable-next-line array-callback-return
			settings?.map((item: any) => {
				switch (item.type) {
					case 'dropdown':
						return (
							<SelectComponent
								selectOptions={finraLisenceDropValue}
								defaultValue={finraLisenceDropDownDefaultValue}
								name="license"
								label={item.title}
								isMulti={false}
								placeholder=""
							/>
						);
					case 'checkbox':
						return (
							<div className={'input-checkbox-wrapper'}>
								<input
									className={'input-checkbox-wrapper__checkbox'}
									type="checkbox"
									onChange={() => setChecked?.(true)}
									name=""
								/>
								<div className={'input-checkbox-wrapper__checkbox-text'}>
									{item.title}
								</div>
							</div>
						);
					case 'input':
						return (
							<Input
								inputType={item.type}
								inputName={item.name}
								label={item.title ?? item.label}
								placeholder={item.placeholder}
								handleChange={handleChange as any}
								key={item.name}
								height={'fit-content'}
								isRequired
								{...(formData &&
									formData[item.name] && { value: formData[item.name] })}
							/>
						);
					case 'text-area':
						return (
							<textarea
								className="input-text-area"
								name={item.name}
								onChange={handleChange as any}
								placeholder="Describe Entity here"
								rows={5}
							/>
						);
					default:
						return <></>;
				}
			}),
		[formData, handleChange, setChecked, settings]
	);

	const handleOnClickNext = useCallback(() => {
		const payload = {
			key: 'entityAccreditationVerify',
			label: 'Entity Accreditation Form',
			id: 1,
		};
		handleNext(payload);
	}, [handleNext]);

	return (
		<div className="accred-form">
			<div className="accred-form__inputs">{renderInputs}</div>
			<div className="button-wrapper">
				<Button
					label="Next"
					handleClick={handleOnClickNext}
					type="button__filled button__filled--primary button__large button__block "
					disabled={isDisabled}
				/>
				{!skipChoosePage ? (
					<Button
						label="Back"
						handleClick={handleBack}
						type="button__filled button__filled--secondary button__large button__block"
					/>
				) : null}
			</div>
		</div>
	);
};
