import { FC } from 'react';

import './screen-skelton.scss';

interface IScreenSkelton {
	header?: JSX.Element;
	body?: JSX.Element | any;
	footer?: JSX.Element | null;
	isQuestionnire?: boolean;
}

export const ScreenSkelton: FC<IScreenSkelton> = ({
	header,
	body,
	footer,
	isQuestionnire = false,
}) => {
	return (
		<div className="skelton-wrapper">
			<div className="skelton-wrapper__header">{header}</div>
			<div
				className={`skelton-wrapper__body ${isQuestionnire ? 'skelton-wrapper-questionnare' : ''}`}
				style={{ marginBottom: footer ? '50px' : '0px' }}
			>
				{body}
			</div>
			{footer && <div className="skelton-wrapper__footer">{footer}</div>}
		</div>
	);
};
