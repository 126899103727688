import { FC, useMemo } from 'react';
import { useCustomLoader } from 'hooks';

import './loader.scss';

interface ILoaderDimension {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white';
	type?: string;
	description?: JSX.Element;
	countdown?: number;
}

export const Loader: FC<ILoaderDimension> = ({
	dimension = 45,
	className = 'loader-blue',
	type = 'gif',
	description,
	countdown = 0,
}) => {
	const { customLoaderElement } = useCustomLoader();
	const renderMainComponent = useMemo(() => {
		if (type === 'gif' || type === 'countdown') {
			return (
				<div className="wrapper-gif-loader">
					{countdown > 0 ? (
						<div className="wrapper-countdown-loader">{countdown}</div>
					) : (
						customLoaderElement
					)}
					{description && (
						<div className="GifLoader--container">{description}</div>
					)}
				</div>
			);
		} else
			return (
				<div className="circle-loader">
					<div
						style={{ width: dimension, height: dimension }}
						className={`loader ${className}`}
					/>
				</div>
			);
	}, [className, countdown, customLoaderElement, description, dimension, type]);
	return renderMainComponent;
};
