import { useSetRecoilState } from 'recoil';
import { GoogleLocation } from 'states';
import { GOOGLE_API } from '../../constants/api';

export const useGetLoationDetails = () => {
	const setGoogleLocation = useSetRecoilState(GoogleLocation);

	const getGeoInfo = async () => {
		try {
			const paylaod = {
				ip: true,
			};

			const response = await fetch(GOOGLE_API.IP, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(paylaod),
			});
			if (response.ok) {
				const apiData = await response.json();
				setGoogleLocation(apiData);
				return apiData;
			}
		} catch (err) {
			return {};
		}
	};

	return {
		getGeoInfo,
	};
};
