import { PRIVACY_POLICY_LINK } from 'views/term-condition/constant';

import './privacy-policy.scss';

export const PrivacyPolicy = () => {
	return (
		<div className="privacy-container">
			<iframe
				title="Privacy Policy"
				src={PRIVACY_POLICY_LINK}
				className="privacy-frame"
			/>
		</div>
	);
};
