export const camelCaseToCapitalizedWords = (input: string): string => {
	try {
		// Replace underscores or any non-alphanumeric characters with spaces
		const formattedInput = input.replace(/[_\W]+/g, ' ');

		// Convert camelCase to capitalized words
		return formattedInput
			.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
			.replace(/(^\w|\s\w)/g, match => match.toUpperCase()); // Capitalize first letter of each word
	} catch (error) {
		// If an error occurs, return the original input value
		return input;
	}
};
