/* eslint-disable react/display-name */
import { FC, memo, useMemo } from 'react';
import { Loader } from '@storybook';

import { simplici_sign_environment } from 'constants/common';
import { useSharedVariables } from 'hooks';

interface IProps {
	currentUrl: string;
	submit_esign: string;
	hideLogo?: boolean;
	hideUser?: boolean;
}

export const SimpliciSignWebComponent: FC<IProps> = memo(
	({ currentUrl, submit_esign, hideLogo, hideUser }) => {
		const { envHost } = useSharedVariables();
		const renderComponent = useMemo(() => {
			if (currentUrl) {
				const signUrl = currentUrl.replace(
					simplici_sign_environment[envHost as any],
					''
				);
				return (
					<div
						dangerouslySetInnerHTML={{
							__html: `<simplici-sign env=${envHost} url=${signUrl} submit_esign=${submit_esign} hide_logo=${hideLogo} hide_user=${hideUser} />`,
						}}
					/>
				);
			}
			return (
				<div className="sign-agreement-container__loader">
					<Loader />
				</div>
			);
		}, [currentUrl, envHost, submit_esign, hideLogo, hideUser]);

		return renderComponent;
	}
);
