import './proof-read-document.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AggrementCheckboxState, ProofReadDocumentState } from './stores';
import { Button, Loader } from '@storybook';
import { CheckboxState, DocumentProofDetails } from './stores/type';
import { DocumentPreview } from './components';
import {
	useNetwork,
	useNextStep,
	useNotification,
	useSharedVariables,
} from 'hooks';
import { APIS, API_TYPE } from 'constants/api';

import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { AccessTokenState } from 'states';

export const ProofReadingDocument = () => {
	// global states
	const ProofReadDocumentData = useRecoilValue(ProofReadDocumentState);
	const [checkboxValues, setCheckboxValues] = useRecoilState(
		AggrementCheckboxState
	);
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	//local states
	const [startIndex, setStartIndex] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	/* this state store current files checked count */
	const [currentFilesCount, setCurrentFilesCount] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	//hooks
	const { post } = useNetwork();
	const { sessionPayloadDetail, setSessionDetails, handleNext } = useNextStep();
	const { errorNotification } = useNotification();
	const { onboardingType } = useSharedVariables();

	const { userId, pipelineId } = sessionDetails;

	const { currentAction, stepsId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	useEffect(() => {
		if (ProofReadDocumentData) {
			ProofReadDocumentData.map(item => {
				setCheckboxValues((prevValues: CheckboxState) => ({
					...prevValues,
					[item.docId]: false,
				}));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ProofReadDocumentData]);

	const handleCheckboxChange = useCallback(
		(id: string) => {
			setCurrentFilesCount(prev =>
				prev.includes(id) ? prev.filter(fileId => fileId !== id) : [...prev, id]
			);

			setCheckboxValues((prevValues: CheckboxState) => ({
				...prevValues,
				[id]: !prevValues[id],
			}));
		},
		[setCheckboxValues]
	);

	const handleNextClick = useCallback(
		async (btnlabel: 'Next' | 'Submit' | JSX.Element) => {
			setCurrentFilesCount([]);

			// Validate checkboxes before proceeding to the next page
			if (btnlabel === 'Next') {
				setStartIndex(prevStartIndex => prevStartIndex + 3);
				setCurrentPage(prevPage => prevPage + 1);
			} else {
				setIsLoading(true);
				// as all doc are checked we post token api
				const getActionsData = {
					id: 'proofVerification',
					data: {
						proofDoc: ProofReadDocumentData?.map(item => ({
							docId: item.docId,
							acknowledge: true,
						})),
					},
				};

				const payload: any = {
					pipelineId: pipelineId,
					userId: userId,
					actions: [getActionsData],
				};

				// complex patch api
				if (onboardingType === 'complex') {
					payload.nodeId = currentAction._id;
					const resp = await post(`${APIS.TOKEN}`, {
						code: sessionCode,
						type: API_TYPE.UPDATE,
						payload,
					});
					if (resp?.statusCode === 200) {
						const apiResponse = { ...resp };
						delete apiResponse.statusCode;
						setSessionDetails(prev => ({
							...prev,
							nodes: apiResponse,
							fundName: apiResponse?.fund?.name ?? '',
							investingAmount: apiResponse?.investingAmount ?? '',
						}));
						setIsLoading(false);
					} else {
						errorNotification(resp.message ?? 'Something went wrong');
						setIsLoading(false);
					}
				} else {
					payload.stepId = stepsId;
					const resp = await post(`${APIS.TOKEN}`, {
						code: sessionCode,
						type: API_TYPE.UPDATE,
						payload,
					});
					if (resp.statusCode === 200) {
						handleNext();
						setIsLoading(false);
					} else {
						errorNotification(resp.message ?? 'Something went wrong');
						setIsLoading(false);
					}
				}
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			ProofReadDocumentData,
			pipelineId,
			userId,
			currentAction._id,
			post,
			sessionCode,
			setSessionDetails,
		]
	);

	const areAllCheckboxesChecked = useMemo(() => {
		if (currentFilesCount.length > 1 && currentFilesCount.length % 3 === 0) {
			return true;
		} else {
			return Object?.values(checkboxValues)?.every(value => value === true);
		}
	}, [checkboxValues, currentFilesCount]);

	const handleClickDoc = useCallback((index: string) => {
		const configDocuments = document.getElementById(index);
		configDocuments?.scrollIntoView({ behavior: 'smooth' });
	}, []);

	const getButton = useMemo(() => {
		const btnLabel =
			startIndex + 3 >= ProofReadDocumentData?.length ? (
				isLoading ? (
					<Loader type="loader" dimension={24} />
				) : (
					'Submit'
				)
			) : (
				'Next'
			);
		return (
			<Button
				label={btnLabel}
				type="button__filled button__filled--primary button__large button__block mt-2"
				handleClick={() => handleNextClick(btnLabel)}
				disabled={
					!areAllCheckboxesChecked || !Object.keys(checkboxValues)?.length
				}
			/>
		);
	}, [
		ProofReadDocumentData,
		areAllCheckboxesChecked,
		handleNextClick,
		startIndex,
		checkboxValues,
		isLoading,
	]);

	const renderFooter = useCallback(() => {
		return (
			<div className="ProofReadingDocument--container__footer">
				<div className="aggrement-footer--container">
					{' '}
					{ProofReadDocumentData?.map((item: DocumentProofDetails) => (
						<div className="aggrement--footer--data" key={item?.docId}>
							<input
								className="aggrement--footer--data__checkbox"
								type="checkbox"
								id={item?.docId}
								checked={checkboxValues[item?.docId]}
								onChange={() => handleCheckboxChange(item?.docId)}
							/>

							<div className="aggrement--footer--data__label">
								I agree to{' '}
								<span onClick={() => handleClickDoc(item?.docId)}>
									{item?.docName}
								</span>
							</div>
						</div>
					))}
				</div>
				{getButton}
			</div>
		);
	}, [
		ProofReadDocumentData,
		getButton,
		checkboxValues,
		handleCheckboxChange,
		handleClickDoc,
	]);

	const renderBodyElement = useCallback(() => {
		return (
			<div className="ProofReadingDocument--container__data">
				{ProofReadDocumentData?.map(
					(item: DocumentProofDetails, index: number) => (
						<div
							className="ProofReadingDocument--container__bodyElement"
							key={item?.docId}
							id={item?.docId}
						>
							<div className="ProofReadingDocument--container__header">
								<div className="ProofReadingDocument--container__header--label">
									<div className="ProofReadingDocument--container__header--label__text">
										{`${index + 1 + currentPage * 3}. ${item?.docName ?? ''}`}
									</div>
								</div>
							</div>
							<div
								className="ProofReadingDocument--container__content"
								id={item?.docId}
							>
								<DocumentPreview docUrl={item?.signedUrl} />
							</div>
						</div>
					)
				)}
			</div>
		);
	}, [ProofReadDocumentData, currentPage]);

	return (
		<div className="ProofReadingDocument--container">
			<div className="ProofReadingDocument--container__header ProofReadingDocument--container__header--text">
				Document Review
			</div>
			<div className="ProofReadingDocument--container__body">
				{renderBodyElement()}
			</div>
			<div>{renderFooter()}</div>
		</div>
	);
};
