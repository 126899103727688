import { FC, useCallback } from 'react';
import { Image } from '@storybook';

import { IChooseOption } from '../stores';

export const ChooseOption: FC<IChooseOption> = ({ settings, handleClick }) => {
	const getIconByKey = useCallback(
		(key: string) =>
			key === 'individualAccreditationVerify'
				? 'user-circle.svg'
				: 'circle-bag.svg',
		[]
	);

	return (
		<div className="choose-option">
			{settings.map((item: any) => (
				<div
					className="choose-option__item"
					key={item.label}
					onClick={() => handleClick(item)}
				>
					<Image fileName={getIconByKey(item.key)} />
					<div className="choose-option__item__center">
						<span className="choose-option__item__title">{item.sublabel}</span>
						<span className="choose-option__item__sub-title">{item.label}</span>
					</div>
					<i className="ri-arrow-right-s-line"></i>
				</div>
			))}
		</div>
	);
};
